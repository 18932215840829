import React, { useEffect, useState } from "react";
import "../../assets/style/profile.css";
import Footer from "../Footer";
import Header from "../Header";
import {
  proafter,
  probefore,
} from "../../constant/index";
import { Link } from "react-router-dom";
import {
  EditAddress,
  GetAllAddress,
  PostAddressApi,
  PostDeleteAddress,
  PostMakeDefaultAddress,
  UpdateProfile,
} from "../../network/Network";
import { AllAddress, EditProfileData } from "../../redux/actions/AuthActions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import SideBar from "../SideBar/SideBar";
import NoRecord from "../NoRecord/NoRecord";
import Skeleton from "react-loading-skeleton";
import swal from 'sweetalert';

const MyAddress = () => {
  const dispatch = useDispatch();
  const Token = useSelector((state) => state.AuthReducer.token);
  const userData = useSelector((state) => state.AuthReducer.users);

  const [userAddress, setUserAddress] = useState([]);
  const [spinLoad, setSpinLoad] = useState(false);

  const [address, setAddress] = useState("");
  const [appartment, setAppartment] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [userState, setUserState] = useState("");
  const [loading, setLoading] = useState(false);

  const [postAddress, setPostAddress] = useState([]);
  const [selectedAddress, setSelectedPostAddress] = useState();
  const [editAddress, setEditAddress] = useState(false);
  

  const AddAddressHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    if (
      !address ||
      !appartment ||
      !city ||
      !zip ||
      !phone ||
      !country ||
      !userState
    ) {
      toast.error("Please Enter All Fields");
      setLoading(false);
      return;
    }
    if(phone.length < 11 || phone.length > 15){
      toast.error("Phone number must be betwween 11 to 15 digits");
      setLoading(false);
      return;
    }
    let data = new FormData();
    data.append("address", address);
    data.append("appartment", appartment);
    data.append("city", city);
    data.append("zip", zip);
    data.append("phone", phone);
    data.append("country", country);
    data.append("state", userState);
    // console.log(data);
    PostAddressApi(data, Token)
      .then((res) => {
        // console.log("address post", res);
        toast.success(res?.data?.message);
        setPostAddress(res?.data)
        setLoading(false);
        setAddress("");
        setAppartment("");
        setPhone("");
        setCity("");
        setCountry("");
        setUserState("");
        setZip("");
      })
      .catch((err) => {
        // console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    setSpinLoad(true);
    GetAllAddress(Token)
      .then((res) => {
        // console.log("address", res);
        dispatch(AllAddress(res?.data));
        setUserAddress(res?.data);
        setSpinLoad(false);
      })
      .catch((err) => {
        // console.log(err);
        setSpinLoad(false);
      });
  }, [postAddress]);

  const MakeDefault_Handler = (e,id) => {
    e.preventDefault();
   let data = {
    address_id: id,
   }
    // console.log(data);
    PostMakeDefaultAddress(data, Token)
      .then((res) => {
        // console.log("address post", res);
        toast.success(res?.data?.message);
        setPostAddress(res?.data)
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const DeleteAddressMethod = (id, index) => {
    setSpinLoad(true);
    let data = { address_id : id,
      _method: "delete", 
    };
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Address!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        PostDeleteAddress(data, Token)
        .then((res) => {
          // console.log(res?.data);
          setUserAddress((preVal) => {
            return [...preVal.filter((val) => val?.id !== id)];
          });
          // setUserAddress((prev) => prev?.id != id)
          // toast.success(res?.data?.message);
          swal(res?.data?.message, {
            icon: "success",
          });
          // paymentData?.splice(index, 1);
          // setPaymentData(res?.data?.response?.data);
          setSpinLoad(false);
        })
        .catch((err) => {
          // console.log(err);
          setSpinLoad(false);
        });
      } else {
        swal("Your Address is safe!");
        setSpinLoad(false);
      }
    });
  };

  const StoreAddressForUpdate = (e,item) =>{
    e.preventDefault();
    // console.log(item)
    setEditAddress(true)
    setSelectedPostAddress(item)
    setAddress(item?.address)
    setAppartment(item?.appartment)
    setCity(item?.city)
    setZip(item?.zip)
    setPhone(item?.phone)
    setCountry(item?.country)
    setUserState(item?.state)
  }

  const EditAddressHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    if (
      !address ||
      !appartment ||
      !city ||
      !zip ||
      !phone ||
      !country ||
      !userState
    ) {
      toast.error("Please Enter All Fields");
      setLoading(false);
      return;
    }
    if(phone.length < 11 || phone.length > 15){
      toast.error("Phone number must be betwween 11 to 15 digits");
      setLoading(false);
      return;
    }

    let data = new FormData();
    data.append("address", address);
    data.append("appartment", appartment);
    data.append("city", city);
    data.append("zip", zip);
    data.append("phone", phone);
    data.append("country", country);
    data.append("state", userState);
    data.append("address_id", selectedAddress?.id);
    // console.log(data);
    EditAddress(data, Token)
      .then((res) => {
        // console.log("address post", res);
        toast.success(res?.data?.message);
        setPostAddress(res?.data)
        setEditAddress(false)
        setLoading(false);
        setSelectedPostAddress("")
        setAddress("");
        setAppartment("");
        setPhone("");
        setCity("");
        setCountry("");
        setUserState("");
        setZip("");
      })
      .catch((err) => {
        // console.log(err);
        setLoading(false);
      });
  };
  

  return (
    <>
      <Header />
      {/* <!-- Profile Sec Start Here --> */}
      <section className="profile-sec">
        <div className="before">
          <figure>
            <img src={probefore} className="img-fluid" />
          </figure>
        </div>
        <div className="container">
          <div className="heading">
            <h2>My Profile</h2>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <SideBar />
            </div>
            <div className="col-lg-8">
              <div className="tab-content" id="v-pills-tabContent">
                {/* <!-- Second Tab Content --> */}
                <div
                  className={`tab-pane fade show active`}
                  id="v-pills-Addresses"
                  role="tabpanel"
                  aria-labelledby="v-pills-Addresses-tab"
                >
                  <div className="tab-box fourth-box">
                    <div className="my-address box">
                      <div className="heading">
                        <h3>My Address</h3>
                      
                      </div>
                      <div className="row align-items-center ">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Address</label>
                            <input
                              type="text"
                              className="form-control"
                              value={address}
                              onChange={(e) => setAddress(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Suite, Apartment, etc </label>
                            <input
                              type="text"
                              className="form-control"
                              value={appartment}
                              onChange={(e) => setAppartment(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>City</label>
                            <input
                              type="text"
                              className="form-control"
                              value={city}
                              onChange={(e) => setCity(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>State</label>
                            <input
                              type="text"
                              className="form-control"
                              value={userState}
                              onChange={(e) => setUserState(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Country</label>
                            <input
                              type="text"
                              className="form-control"
                              value={country}
                              onChange={(e) => setCountry(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Zip Code</label>
                            <input
                              type="number"
                              className="form-control"
                              value={zip}
                              onChange={(e) => setZip(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Phone</label>
                            <input
                              type="number"
                              className="form-control"
                              value={phone}
                              onChange={(e) => setPhone(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="button-group mt-3">
                            {
                              selectedAddress ? (
                                <button
                                className="btn addAddress"
                                onClick={(e) => EditAddressHandler(e)}
                                disabled={loading}
                              >
                                {loading ? "Loading..." : "Update Address"}
                              </button>
                              ) : (
                                <button
                                className="btn addAddress"
                                onClick={(e) => AddAddressHandler(e)}
                                disabled={loading}
                              >
                                {loading ? "Loading..." : "Save Address"}
                              </button>
                              )
                            }
                           
                          </div>
                        </div>
                      </div>
                      <div className="save-address">
                        <div className="heading">
                          <h3>Saved Addresses</h3>
                        </div>
                        {/* <!-- Saved Address --> */}
                        {spinLoad ? (
                          <>
                            {[0, 1, 2, 3, 4, 5, 7, 8, 9]?.map((item, index) => {
                              return (
                                <div className="col-md-12 mt-3" key={index}>
                                  <Skeleton count={5} />
                                </div>
                              );
                            })}
                          </>
                        ) : userAddress.length > 0 ? (
                          <>
                            {userAddress?.map((item, index) => {
                              return (
                                <label className="container" key={index}>
                                  <input
                                    type="radio"
                                    checked={item?.default == 1 ? true : false}
                                    name="radio"
                                  />
                                  <span className="checkmark">
                                    <div className="select-address">
                                      <div className="address-detail">
                                        <div className="actions">
                                          <a
                                            onClick={(e)=>StoreAddressForUpdate(e,item)} 
                                            className="editAddress edit"
                                          >
                                            <i
                                              className="fa fa-pencil-square-o"
                                              aria-hidden="true"
                                            ></i>
                                          </a>
                                          <a onClick={(e)=>DeleteAddressMethod(item?.id,index)}
                                            className="deleteAddress"
                                          >
                                            <i
                                              className="fa fa-trash"
                                              aria-hidden="true"
                                            ></i>
                                          </a>
                                        </div>
                                        <h5>
                                          <span className="property">
                                            Country:
                                          </span>
                                          <div className="spacer"></div>
                                          <span className="value">
                                            {item?.country}
                                          </span>
                                        </h5>
                                        <h5>
                                          <span className="property">
                                            Address:
                                          </span>
                                          <div className="spacer"></div>
                                          <span className="value">
                                            {item?.address}
                                          </span>
                                        </h5>
                                        <h5>
                                          <span className="property">
                                            Phone:
                                          </span>
                                          <div className="spacer"></div>
                                          <span className="value">
                                            {item?.phone}
                                          </span>
                                        </h5>
                                        <h5>
                                          <span className="property">
                                            State:
                                          </span>
                                          <div className="spacer"></div>
                                          <span className="value">
                                            {item?.state}
                                          </span>
                                        </h5>
                                        <div className="deafult"
                                          onClick={(e)=>MakeDefault_Handler(e,item?.id)}
                                        >
                                          <h5>Use as default</h5>
                                          <span className="dot"></span>
                                        </div>
                                      </div>
                                    </div>
                                  </span>
                                </label>
                              );
                            })}
                          </>
                        ) : (
                          <NoRecord />
                        )}

                        {/* <!-- Saved Address --> */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- Second Tab Content --> */}
              </div>
            </div>
          </div>
        </div>
        <div className="after">
          <figure>
            <img src={proafter} className="img-fluid" />
          </figure>
        </div>
      </section>
      {/* <!-- Profile Sec End Here --> */}
      <Footer />
    </>
  );
};

export default MyAddress;
