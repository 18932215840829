import {
  ADD_TO_CART,
  CART_CLEAR,
  DELETE_CART_ITEM,
  INCREMENT_QUANTITY,
  DECREMENT_QUANTITY,
  GALLERY_IMG,
  UPDATE_ADD_TO_CART,
  DELIVERY_DATES,
} from "../Types";
import {
  forgotPasswordOtp,
  forgotPasswordApi,
  postSignIn,
  postSignUp,
  setNewPasswordApi,
  forgotPasswordEmailApi,
} from "../../network/Network";
import { toast } from "react-toastify";
import { setError } from "../actions/AlertAction";
import { responses, responsesLogin } from "../../constant/ConstantFunction";

export const AddToCart = (payload) => {
  return {
    type: ADD_TO_CART,
    payload,
  };
};
export const UpdateCart = (payload) => {
  return {
    type: UPDATE_ADD_TO_CART,
    payload,
  };
};

export const ClearCart = (payload) => {
  return {
    type: CART_CLEAR,
    payload,
  };
};

export const deleteCartItem = (currentItem) => (dispatch) => {
  dispatch({
    type: DELETE_CART_ITEM,
    payload: currentItem,
  });
};

export const IncrementProductQuantity = (id) => {
  return {
    type: INCREMENT_QUANTITY,
    payload: id,
  };
};

export const DecrementProductQuantity = (id) => {
  return {
    type: DECREMENT_QUANTITY,
    payload: id,
  };
};

export const GalleryImages = (payload) => {
  return {
    type: GALLERY_IMG,
    payload,
  };
};

export const AllDeliveryDates = (payload) => {
  return {
    type: DELIVERY_DATES,
    payload,
  };
};





