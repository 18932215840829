import React, { useEffect, useState } from "react";
import Footer from "../../component/Footer";
import Header from "../../component/Header";
import "../../assets/style/productdetail.css";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import {
  arrow,
  blogbefore,
  no_image_product_details,
  proafter,
} from "../../constant/index";
import { GetProductDetailsApi } from "../../network/Network";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";

const BirdProductDetail = () => {
  let currentUrl = window.location.href.split("/");
  const ImageUrlRedux = useSelector((state) => state.AuthReducer.urlData);
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const navigate = useNavigate();

  const [birdsDetails, setBirdsDetails] = useState(null);
  const [spinLoad, setSpinLoad] = useState(false);

  useEffect(() => {
    setSpinLoad(true);
    GetProductDetailsApi(currentUrl[4])
      .then((res) => {
        // console.log("birds details", res);
        setBirdsDetails(res?.data);
        setSpinLoad(false);
      })
      .catch((err) => {
        // console.log(err);
        setSpinLoad(false);
      });
  }, [currentUrl[4]]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (birdsDetails == null) {
    return (
      <div className="container">
        <div className="row loader-container">
          {[0, 1, 2, 3, 4, 5, 7, 8, 9]?.map((item, index) => {
            return (
              <div className="col-md-12 mt-3" key={index}>
                <Skeleton count={5} />
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <>
      <Header />
      {/* <!-- InnerBanner Sec Start Here --> */}
      <section className="innerBanner">
        <div className="container">
          <div className="inner-box">
            <div className="title">
              <h2>Kigi Bird Detail Page</h2>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- InnerBanner Sec End Here --> */}

      {/* <!-- Product Detail Sec Start Here --> */}
      <section className="product-detail birdsprodetail">
        <div className="before">
          <figure>
            <img src={blogbefore} className="img-fluid" />
          </figure>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="product-images">
                {
                  birdsDetails?.images[0]
                    ?
                    <>
                      <div className="single-image">
                        <Slider asNavFor={nav2} ref={(slider1) => setNav1(slider1)}>
                          {
                            birdsDetails?.images?.map((item, index) => {
                              return (
                                <figure key={index}>
                                  <img src={`${ImageUrlRedux?.product_image_url}/${item}`} className="img-fluid" />
                                </figure>
                              )
                            })
                          }

                        </Slider>
                      </div>
                      <div className="slider-images">
                        <Slider
                          asNavFor={nav1}
                          ref={(slider2) => setNav2(slider2)}
                          slidesToShow={birdsDetails?.images?.length > 0 || birdsDetails?.images?.length < 3 ? birdsDetails?.images?.length : 3}
                          swipeToSlide={true}
                          focusOnSelect={true}
                        >
                          {
                            birdsDetails?.images?.map((item, index) => {
                              return (
                                <figure key={index}>
                                  <img src={`${ImageUrlRedux?.product_image_url}/${item}`} className="img-fluid" />
                                </figure>
                              )
                            })
                          }

                        </Slider>
                      </div>
                    </>
                    :
                    <img className="no_image_avatar_product_details" src={`${birdsDetails?.thumbnail ? `${ImageUrlRedux?.product_thumbnail_url}/${birdsDetails?.thumbnail}` : no_image_product_details}`} />
                }
              </div>
            </div>
            <div className="col-lg-6">
              <div className="details">
                <div className="header">
                  <div className="heading">
                    <h4>{birdsDetails?.category?.name}</h4>
                    <h3>{birdsDetails?.name}</h3>
                  </div>
                  {/* <!-- <div className="price-tag">
                            <h5>Unit Price : <span className="value">$50.50</span></h5>
                        </div> --> */}
                </div>
                <div className="description">
                  <h4>Description</h4>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: birdsDetails?.details,
                    }}
                  />
                </div>

                <div className="button-group">
                  <Link to="/donatenow" state={{ data: { id: currentUrl[4], page: "birds", } }} >
                    <button className="btn">
                      DONATE NOW <img src={arrow} className="img-fluid" />
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="after">
          <figure>
            <img src={proafter} className="img-fluid" />
          </figure>
        </div>
      </section>
      {/* <!-- Product Detail Sec End Here --> */}
      <Footer />
    </>
  );
};

export default BirdProductDetail;
