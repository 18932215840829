import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { btnicon, tiktok } from "../constant/index";
import {
  GetCampaign,
  GetGalleryImage,
  GetProductDate,
  GetSocialMedia,
  GetUrls,
  PostNewsLetter,
} from "../network/Network";
import { AllDonationCampaign, AllSocialLinks, AllURls } from "../redux/actions/AuthActions";
import { AllDeliveryDates, GalleryImages } from "../redux/actions/CartActions";

const Footer = () => {
  const dispatch = useDispatch();
  const ImageUrlRedux = useSelector((state) => state.AuthReducer.urlData);
  const SiteSettingsRedux = useSelector((state) => state.AuthReducer.siteSettings);
  const SocialDataRedux = useSelector((state) => state.AuthReducer.socialData);
  const [newsEmail, setNewsEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const SubmitNewsLetter = (e) => {
    setLoading(true);
    e.preventDefault();
    if (!newsEmail) {
      toast.error("Please Enter Email Address");
      setLoading(false);
      return;
    }
    if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        newsEmail
      )
    ) {
      toast.error("Invalid Email");
      setLoading(false);
      return;
    }
    let data = {
      email: newsEmail,
    };
    PostNewsLetter(data)
      .then((res) => {
        // console.log("news", res);
        toast.success(res?.data?.message);
        setLoading(false);
        setNewsEmail("");
      })
      .catch((err) => {
        // console.log(err);
        if(err?.response?.data?.errors[0]?.code == "email"){
          toast.error(err?.response?.data?.errors[0]?.message);
          setLoading(false);
          return;
        }
        if (err?.response?.data?.errors?.email) {
          toast.error(err?.response?.data?.errors?.email[0]);
          setLoading(false);
          return;
        }
        setLoading(false);
      });
  };

  // Get url Data
  useEffect(() => {
    GetUrls()
      .then((res) => {
        // console.log(res);
        dispatch(AllURls(res?.data?.base_urls));
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);

  // Get gallery Data
  useEffect(() => {
    GetGalleryImage()
      .then((res) => {
        // console.log(res);
        dispatch(GalleryImages(res?.data));
        // setGalleryData(res?.data)
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);

  // Get camping Data
  useEffect(() => {
    GetCampaign()
      .then((res) => {
        // console.log(res);
        dispatch(AllDonationCampaign(res?.data));
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);

  // Get delivery date
  useEffect(() => {
    GetProductDate()
      .then((res) => {
        // console.log("DATE", res);
        dispatch(AllDeliveryDates(res?.data));
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);

    // Get Social date
    useEffect(() => {
      GetSocialMedia()
        .then((res) => {
          // console.log("social ", res);
          dispatch(AllSocialLinks(res?.data));
        })
        .catch((err) => {
          // console.log(err);
        });
    }, []);

  

  return (
    <>
      {/* <!-- Newsletter Sec Start Here --> */}
      <section className="newsletter-sec">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div className="heading">
                <h2>Subscribe To Our Newsletter To Stay Updated</h2>
              </div>
            </div>
            <div className="col-lg-5">
              <form>
                <div className="form-group">
                  <input
                    type="email"
                    placeholder="Enter Your Email"
                    className="form-control"
                    value={newsEmail}
                    onChange={(e) => setNewsEmail(e.target.value)}
                  />
                </div>
                <div className="button-group">
                  <button
                    className="btn"
                    onClick={(e) => SubmitNewsLetter(e)}
                    disabled={loading}
                  >
                    <img src={btnicon} className="img-fluid" />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Newsletter Sec End Here --> */}

      {/* <!-- Footer Start Here --> */}
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="footer-content">
                <div className="head">
                  <h4>About</h4>
                </div>
                <p>
                 {SiteSettingsRedux?.kigi_about_us}
                </p>
                {/* <p
                    dangerouslySetInnerHTML={{
                      __html: SiteSettingsRedux?.kigi_about_us,
                    }}
                  /> */}
                <ul className="icons">
                  {
                    SocialDataRedux?.map((item,index)=>{
                      return(
                        <li key={index}>
                        <a href={`${item?.link}`} target="_blank">
                          <i className={item?.icon} aria-hidden="true"></i>
                        </a>
                      </li>
                      )
                    })
                  }
                 
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <div className="footer-content scnd-content">
                <div className="head">
                  {/* <!-- <h4>RESOURCES</h4> --> */}
                  <h4>QUICK LINKS</h4>
                </div>
                <ul className="menus">
                  <li>
                    <Link to="/about">About Us</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact Us</Link>
                  </li>
                  <li>
                    <Link to="/gift-certificate">Gift Certificates</Link>
                  </li>
                  <li>
                    <Link to="/gallery">Image Gallery</Link>
                  </li>
                  <li>
                    <Link to="/blogs">Blogs</Link>
                  </li>
                  <li>
                    <Link to="/faqs">Faqs</Link>
                  </li>
                  <li>
                    <Link to="/shipping-policy">Shipping Policy</Link>
                  </li>
                  <li>
                    <Link to="/video">Videos</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <div className="footer-content thrd-content">
                <div className="head">
                  <h4>COMPANY INFO</h4>
                </div>
                <ul className="menus">
                  <li></li>
                  <li>
                    <h6>Address : </h6>
                    <a>{SiteSettingsRedux?.kigi_address}</a>
                  </li>
                  <li></li>
                  <li>
                    <h6>Email : </h6>
                    <a>{SiteSettingsRedux?.kigi_company_email}</a>
                  </li>
                  <li></li>
                  <li>
                    <h6>Number : </h6>
                    <a>+{SiteSettingsRedux?.kigi_company_phone}</a>
                  </li>
                
                </ul>
              </div>
            </div>
          </div>
          <div className="big-logo">
            <h1>{SiteSettingsRedux?.kigi_company_name}</h1>
          </div>
          <div className="copyright">
            <p>{SiteSettingsRedux?.kigi_company_copyright_text}</p>
          </div>
        </div>
      </footer>
      {/* <!-- Footer End Here --> */}
    </>
  );
};

export default Footer;
