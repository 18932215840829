import React, { useEffect } from "react";
import Footer from "../../component/Footer";
import Header from "../../component/Header";
import "../../assets/style/checkout.css";
import {
  proafter,
  probefore,
} from "../../constant/index";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import StripeForm from "../../component/PaymentMethod/StripeForm";
import {
  GetAllAddress,
  GetCouponCode,
  GetGiftCode,
  PostMakeDefaultAddress,
  PostPlaceOrder,
} from "../../network/Network";
import { toast } from "react-toastify";
import { AllAddress } from "../../redux/actions/AuthActions";
import AddressModal from "../../component/Modal/AddressModal";
import { ClearCart } from "../../redux/actions/CartActions";

const Checkout = () => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const ImageUrlRedux = useSelector((state) => state.AuthReducer.urlData);
  const Token = useSelector((state) => state.AuthReducer.token);
  const CartDataRedux = useSelector((state) => state.CartReducer.cartData);
  const AddressDataRedux = useSelector((state) => state.AuthReducer.address);
  const userData = useSelector((state) => state.AuthReducer.users);

  const [promoCode, setPromoCode] = useState("");
  const [subTotal, setSubTotal] = useState(0);

  const Location = useLocation();
  const CheckOutData = Location?.state?.data;
  const [postAddress, setPostAddress] = useState([]);

  const [giftCode, setGiftCode] = useState("");
  const [couponCode, setCouponCode] = useState("");

  const [giftData, setGiftData] = useState();
  const [couponData, setCouponData] = useState();

  const [couponLoading, setCouponLoading] = useState(false);
  const [giftLoading, setGiftLoading] = useState(false);

  const [isOpenAddressModal, setIsOpenAddressModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalDonation, setTotalDonation] = useState(0);

  // console.log(CheckOutData);

  const TotalAmount = () => {
    let i;
    let total = 0;
    let donationTotal = 0;
    for (i = 0; i < CheckOutData?.CartData.length; i++) {
      // console.log(CheckOutData?.CartData[i]);
      if (CheckOutData?.CartData[i]?.campaign_price) {
        donationTotal =
          parseInt(donationTotal) +
          parseInt(CheckOutData?.CartData[i]?.campaign_price);
      }

      if (
        CheckOutData?.CartData[i]?.type &&
        CheckOutData?.CartData[i]?.type == "bulk"
      ) {
        total = total + CheckOutData?.CartData[i]?.price * 1;
      } else {
        total =
          total +
          CheckOutData?.CartData[i]?.price *
            CheckOutData?.CartData[i]?.quantity;
      }
      if (CheckOutData?.CartData[i]?.campaign_price) {
        total =
          parseInt(total) + parseInt(CheckOutData?.CartData[i]?.campaign_price);
      }
      if (CheckOutData?.CartData[i]?.addon_item) {
        let j;
        for (j = 0; j < CheckOutData?.CartData[i]?.addon_item.length; j++) {
          total =
            parseInt(total) +
            parseInt(
              CheckOutData?.CartData[i]?.addon_item[j]?.addon_item[0]
                ?.variation[0]?.price
            );
        }
      }
    }
    setSubTotal(total);
    setTotalDonation(donationTotal);
  };

  useEffect(() => {
    TotalAmount();
  }, [CartDataRedux]);

  useEffect(() => {
    GetAllAddress(Token)
      .then((res) => {
        // console.log("address", res);
        dispatch(AllAddress(res?.data));
      })
      .catch((err) => {
        // console.log(err);
      });
  }, [postAddress]);

  const MakeDefault_Handler = (e, id) => {
    e.preventDefault();
    let data = {
      address_id: id,
    };
    // console.log(data);
    PostMakeDefaultAddress(data, Token)
      .then((res) => {
        // console.log("address post", res);
        toast.success(res?.data?.message);
        setPostAddress(res?.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const styling = {
    marginTop: "10px",
    width: "100%",
    padding: "8px 16px",
    borderRadius: "20px",
  };

  const ApplyCouponCodeHandler = (e) => {
    e.preventDefault();
    setCouponLoading(true);
    let data = {
      code: couponCode,
      amount: subTotal,
    };
    GetCouponCode(data, Token)
      .then((res) => {
        setCouponLoading(false);
        // console.log(res);
        setCouponData(res?.data?.data);
        toast.success("Successfully Apply");
        setCouponCode("");
      })
      .catch((err) => {
        setCouponLoading(false);
        // console.log(err);
        if (err?.response?.data?.errors[0]?.message) {
          toast.error(err?.response?.data?.errors[0]?.message);
        }
        toast.error(err?.response?.data?.errors);
      });
  };

  const ApplyGiftCodeHandler = (e) => {
    e.preventDefault();
    setGiftLoading(true);
    let data = {
      code: giftCode,
    };
    GetGiftCode(data, Token)
      .then((res) => {
        setGiftLoading(false);
        // console.log(res);
        setGiftData(res?.data?.data);
        toast.success(res?.data?.message);
        setGiftCode("");
      })
      .catch((err) => {
        setGiftLoading(false);
        // console.log(err);
        if (err?.response?.data?.errors[0]?.message) {
          toast.error(err?.response?.data?.errors[0]?.message);
        }
        toast.error(err?.response?.data?.message);
      });
  };

  // console.log(CheckOutData);
  const PlaceOrderHandler = (e, stripe_token) => {
    e.preventDefault();
    setLoading(true);
    let data = {
      order_data: {
        coupon_discount: couponData?.discount ? couponData?.discount : 0,
        coupon_code: couponData?.coupon ? couponData?.coupon?.code : " ",
        gift_certificate_price: giftData?.amount ? giftData?.amount : 0,
        gift_certificate_code: giftData?.code ? giftData?.code : " ",
        total: subTotal,
        _token: stripe_token,
        products: CheckOutData?.CartData,
      },
    };

    PostPlaceOrder(data, Token)
      .then((res) => {
        // console.log(res);
        setLoading(false);
        dispatch(ClearCart());
        toast.success("Order placed successfully!");
        setTimeout(() => {
          Navigate(`/thankyou`);
        }, 600);
      })
      .catch((err) => {
        setLoading(false);
        // console.log(err);
        if (err?.response?.data?.errors) {
          toast?.error(err?.response?.data?.errors);
        }
        if (err?.response?.data?.error) {
          // console.log(err?.response?.data?.error);
          toast?.error(err?.response?.data?.error);
        }
        if (err?.response?.data?.errors[0]?.code == "stock") {
          toast?.error(err?.response?.data?.errors[0]?.message);
        }
      });
  };

  return (
    <>
      <Header />
      {/* <!-- InnerBanner Sec Start Here --> */}
      <section className="innerBanner">
        <div className="container">
          <div className="inner-box">
            <div className="title">
              <h2>Checkout</h2>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- InnerBanner Sec End Here --> */}

      {/* <!-- Checkout Start Here --> */}
      <section className="checkout">
        <div className="before">
          <figure>
            <img src={probefore} className="img-fluid" />
          </figure>
        </div>
        <div className="container">
          <form action="thank-you.php">
            <div className="row">
              <div className="col-lg-6">
                <div className="first-top-bar">
                  <h3>Shipment Details</h3>
                  {Token ? null : (
                    <p>
                      Already Have an account?<Link to="/login">Log in</Link>
                    </p>
                  )}
                </div>
                <div className="saved-address">
                  <div className="title">
                    <h5>Use Saved Addresses</h5>
                  </div>
                  {AddressDataRedux?.map((item, index) => {
                    return (
                      <label className="container" key={index}>
                        <input type="radio" checked="checked" name="radio" />
                        <span className="checkmark">
                          <div className="select-address">
                            <div className="selection">
                              <i className="fa fa-check" aria-hidden="true"></i>
                            </div>
                            <div className="radio-btn">
                              <span></span>
                            </div>
                            <div className="address-detail">
                              <div className="top">
                                <h5>
                                  <span className="property">Country:</span>
                                  <span className="value">{item?.country}</span>
                                </h5>
                                <h5>
                                  <span className="property pl-4">State:</span>
                                  <span className="value">{item?.state}</span>
                                </h5>
                              </div>
                              <h5>
                                <span className="property">Address:</span>
                                <span className="value">
                                  {item?.address}, {item?.appartment}
                                </span>
                              </h5>
                              <h5 className="last">
                                <span className="property">Phone:</span>
                                <span className="value">+{item?.phone}</span>
                              </h5>
                              <div className="button-group">
                                <button
                                  className={`${
                                    item?.default == "1"
                                      ? "btn2"
                                      : "btn default"
                                  }`}
                                  disabled={item?.default == "1" ? true : false}
                                  onClick={(e) =>
                                    MakeDefault_Handler(e, item?.id)
                                  }
                                >
                                  Default
                                </button>
                              </div>
                            </div>
                          </div>
                        </span>
                      </label>
                    );
                  })}
                </div>
                <div className="add-new-address">
                  <label
                    className="container"
                    onClick={(e) => setIsOpenAddressModal(true)}
                  >
                    {" "}
                    Add New Address
                    {/* <input type="radio" name="radio" />
                    <span className="checkmark"></span> */}
                  </label>
                </div>

                <div className="payment-details">
                  <div className="heading">
                    <h3>Payment Details</h3>
                  </div>
                  <div className="saved-payment-method">
                    <div className="add-new-method">
                      <div className="form-group">
                        <StripeForm
                          styling={styling}
                          paynow="Confirm Order"
                          PaymentHandler={PlaceOrderHandler}
                          loading={loading}
                        />
                      </div>
                    </div>
                    {/* <div className="card-type">
                      <label className="container">
                        <input type="radio" checked="checked" name="radio" />
                        <span className="checkmark">
                          <figure>
                            <img src={payment1} className="img-fluid" />
                          </figure>
                        </span>
                      </label>
                      <label className="container">
                        <input type="radio" name="radio" />
                        <span className="checkmark">
                          <figure>
                            <img src={payment2} className="img-fluid" />
                          </figure>
                        </span>
                      </label>
                      <label className="container">
                        <input type="radio" name="radio" />
                        <span className="checkmark">
                          <figure>
                            <img src={payment3} className="img-fluid" />
                          </figure>
                        </span>
                      </label>
                      <label className="container">
                        <input type="radio" name="radio" />
                        <span className="checkmark">
                          <figure>
                            <img src={payment4} className="img-fluid" />
                          </figure>
                        </span>
                      </label>
                      <label className="container">
                        <input type="radio" name="radio" />
                        <span className="checkmark">
                          <figure>
                            <img src={payment5} className="img-fluid" />
                          </figure>
                        </span>
                      </label>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="second-top-bar">
                  <div className="title pb-4">
                    <h3>Shipment Details</h3>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Promo Code"
                      value={couponCode}
                      onChange={(e) => setCouponCode(e.target.value)}
                    />
                    <button
                      type="button"
                      onClick={(e) => ApplyCouponCodeHandler(e)}
                      disabled={couponLoading}
                    >
                      {couponLoading ? "Loading" : "APPLY"}
                    </button>
                  </div>
                </div>
                <div className="second-top-bar">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Gift Certificate code"
                      value={giftCode}
                      onChange={(e) => setGiftCode(e.target.value)}
                    />
                    <button
                      type="button"
                      onClick={(e) => ApplyGiftCodeHandler(e)}
                      disabled={giftLoading}
                    >
                      {giftLoading ? "Loading.." : "APPLY"}
                    </button>
                  </div>
                </div>
                <div className="cart-product-detail">
                  <div className="title">
                    <h3>Products Details</h3>
                  </div>
                  <div className="checkout-products">
                    {CheckOutData?.CartData?.map((item, index) => {
                      return (
                        <div className="products" key={index}>
                          <div className="abt-product">
                            <div className="img-box">
                              <figure>
                                <img
                                  src={`${ImageUrlRedux?.product_thumbnail_url}/${item?.productitem?.thumbnail}`}
                                  alt="123"
                                />
                              </figure>
                            </div>
                            <div className="pro-dtl">
                              <h5 className="name">
                                {item?.productitem?.name}
                              </h5>
                              <ul className="variations">
                                <li>
                                  <span className="property">
                                    Product price with quantity:
                                  </span>{" "}
                                  <span className="value">
                                    ${item?.price} * {item?.quantity}
                                  </span>
                                </li>
                                {/* <li>
                                  <span className="property">Color:</span>{" "}
                                  <span className="value"> {item?.productitem?.name}</span>
                                </li> */}
                                {/* {item?.productitem?.leaf_type ? (
                                  <li>
                                    <span className="property">Leaf Type:</span>{" "}
                                    <span className="value">
                                      {item?.productitem?.leaf_type}
                                    </span>
                                  </li>
                                ) : null} */}

                        

                                {item?.addon_item ? (
                                  <>
                                  {
                                    item?.addon_item?.map((item)=>{
                                      return(
                                        <li key={item?.addon_item[0]?.id}>
                                        <span className="property">AddOn:</span>{" "}
                                        <span className="value">
                                        {item?.addon_item[0]?.name}<br />$
                                        {item?.addon_item[0]?.variation[0]
                                            ? item?.addon_item[0]?.variation[0]
                                                ?.price
                                            : null}
                                            <br />
                                            {item?.addon_item[0]?.variation[0]
                                            ? item?.addon_item[0]?.variation[0]
                                                ?.size
                                            : null}
                                        </span>
                                      </li>
                                      )
                                    })
                                  }
                                   
                                  </>
                                ) : null}

                                {item?.type == "bulk" ? (
                                  <li>
                                    <span className="property">Type: </span>
                                    <span className="value">
                                      {item?.type}
                                    </span>{" "}
                                  </li>
                                ) : null}
                              </ul>
                            </div>
                          </div>
                          {/* <div className="product-price">
                            <h4 className="price">{item?.quantity} x</h4>
                          </div>
                          <div className="product-price">
                            <h4 className="price">${item?.price}</h4>
                          </div> */}
                        </div>
                      );
                    })}
                  </div>
                  <div className="pricing-details">
                    <div className="item-total">
                      <h4 className="property">Donation Amount :</h4>
                      <h4 className="value">
                        ${totalDonation ? totalDonation : 0}
                      </h4>
                    </div>
                    <div className="item-total">
                      <h4 className="property">Item Total :</h4>
                      <h4 className="value">${subTotal}</h4>
                    </div>
                    <div className="item-total">
                      <h4 className="property">Shipment & Delivery</h4>
                      <h4 className="value">$0</h4>
                    </div>
                    <div className="item-total">
                      <h4 className="property">Promo Applied :</h4>
                      <h4 className="value">
                        -${couponData?.discount ? couponData?.discount : 0}
                      </h4>
                    </div>
                    <div className="item-total">
                      <h4 className="property">Gift Code Applied :</h4>
                      <h4 className="value">
                        -${giftData?.amount ? giftData?.amount : 0}
                      </h4>
                    </div>
                  </div>

                  <div className="order-total">
                    <h3 className="property">Order Total</h3>
                    <h3 className="value">
                      $
                      {couponData || giftData
                        ? parseInt(subTotal) -
                          parseInt(
                            couponData?.discount
                              ? couponData?.discount
                              : 0 + giftData
                              ? giftData?.amount
                              : 0
                          )
                        : subTotal}
                      {/* {parseInt(subTotal) >= parseInt(couponData) ? (
                        <>
                          {parseInt(subTotal) - parseInt(couponData?.discount? couponData?.discount : 0 +  giftData? giftData?.amount : 0)}
                        </>
                      ) : (
                        subTotal
                      )} */}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="border-last"></div>
            {/* <div className="confirm-orderDv">
              <div className="checkbox">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="for-checkbox"
                />
                <label for="for-checkbox">
                  Proident, sunt in culpa qui officia deserunt mollit anim id
                  est laborum.
                </label>
              </div>
              <div className="button-group">
                <Link to="/thankyou">
                  <button type="submit">Confirm Order</button>
                </Link>
              </div>
            </div> */}
          </form>
        </div>
        <div className="after">
          <figure>
            <img src={proafter} className="img-fluid" />
          </figure>
        </div>
      </section>
      {/* <!-- Checkout End Here --> */}
      <Footer />
      <AddressModal
        isOpenAddressModal={isOpenAddressModal}
        setIsOpenAddressModal={setIsOpenAddressModal}
        postAddress={postAddress}
        setPostAddress={setPostAddress}
      />
    </>
  );
};

export default Checkout;
