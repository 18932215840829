import React, { useState } from "react";
import Header from "../../component/Header";
import "../../assets/style/login.css";
import { blogbefore, proafter, arrow } from "../../constant/index";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { forgotPassword } from "../../redux/actions/AuthActions";
import { toast } from "react-toastify";

const ForgetPassword = () => {
    const navigate = useNavigate();
	const dispatch = useDispatch()
	const [ email, setEmail ] = useState("")
	const [ loading, setLoading ] = useState("")
  
	const ForgetPasswordHandler = async (e) => {
		e.preventDefault();
		setLoading(true);
		if(!email){
		  toast.error("please enter Your email");
		  setLoading(false);
		}
		if (
		  !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
			email
		  )
		) {
		  toast.error("please enter correct email");
		  setLoading(false);
		  return;
		} else {
		  let data = { 
            email: email, 
            platform: "kigi",
            };
		  setTimeout(async () => {
			setLoading(false);
			let send = await dispatch(forgotPassword(data));
			// Navigate("/forgot-password-Otp", { state: email });
			// Navigate("/newpassword");
			// window.location.href = "/newpassword";
		  }, 600);
		}
	  };
    return (
        <>
            <Header />
            <section className="login">
                <div className="before">
                    <figure>
                        <img src={blogbefore} className="img-fluid" />
                    </figure>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-8">
                            <div className="auth-box">
                                <div className="heading">
                                    <h2>Forgot Password</h2>
                                    <h4>
                                        Write Your email to{" "}
                                        <span className="bld">Recover Account</span>
                                    </h4>
                                </div>
                                <form action="change-pass.php">
                                    <div className="form-group">
                                        <label>Your Email</label>
                                        <input type="email" className="form-control" 
                                            value={email}
                                            onChange={(e)=>setEmail(e.target.value)}
                                        />
                                    </div>
                                    {/* <Link to="/changepassword"> */}
                                        <button className="btn" type="submit"
                                            disabled={loading}
                                            onClick={(e)=>ForgetPasswordHandler(e)}
                                        >
                                            <span> {loading? "Loading.." : "SUBMIT"}</span>{" "}
                                            <img src={arrow} className="img-fluid" />
                                        </button>
                                    {/* </Link> */}
                                </form>
                            </div>
                        </div>
                        <div className="col-md-2"></div>
                    </div>
                </div>
                <div className="after">
                    <figure>
                        <img src={proafter} className="img-fluid" />
                    </figure>
                </div>
            </section>
        </>
    );
};

export default ForgetPassword;
