import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import "../../assets/style/Custom.css";
import { productdetail3 } from "../../constant";
import { useSelector } from "react-redux";
import { ramdomImage } from "../../constant/ConstantFunction";

function DonarListModal(props) {
  const { setIsOpenDonarModal, isOpenDonarModal, campaignDetails } = props;
  const ImageUrlRedux = useSelector((state) => state.AuthReducer.urlData);
  const Navigate = useNavigate();
  // console.log("selected", campaignDetails);

  return (
    <>
      <Modal
        isOpen={isOpenDonarModal}
        toggle={() => {
          setIsOpenDonarModal(false);
        }}
        className="custom-modal modal-width orderView-Modal"
      >
        {/* <FontAwesomeIcon
          icon={faTimesCircle}
          className="modal-close"
          onClick={() => setIsOpenAddressModal(false)}
        /> */}
        {/* <h2 style={{ textAlign: "center" }}>Add New Address</h2> */}
        <div
          // className="modal"
          id="donors"
          // tabindex="-1"
        >
          <div
          // className="modal-dialog modal-dialog-centered"
          >
            <div
            //   className="modal-content"
            >
              <div className="modal-header">
                <h5 className="modal-title donation-title">Donors List</h5>
                {/* <!-- <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> --> */}
              </div>
              <div className="modal-body">
                <div className="donor-list">
                  {campaignDetails?.donors?.map((item, index) => {
                    return (
                      <div className="donor-box" key={index}>
                        <div className="img-box">
                          <figure>
                            {item?.user?.image == null ? (
                              <img
                                src={`${ramdomImage(
                                  `${item?.user?.f_name}{" "}${item?.user?.l_name}`
                                )}`}
                                className="image-fluid image-width"
                                style={{ borderRadius: "50%", width: "100%" }}
                                alt=""
                              />
                            ) : (
                              <img
                                src={`${ImageUrlRedux?.customer_image_url}/${item?.user?.image}`}
                                alt="all-Images"
                                className="img-fuild"
                              />
                            )}
                            {/* <img src={image} className="img-fluid" /> */}
                          </figure>
                        </div>
                        <div className="content ml-3">
                          <div className="header">
                            <div className="name">
                              <h4>
                                {item?.user?.f_name}
                                {" "}
                                {item?.user?.l_name}
                              </h4>
                            </div>
                          </div>
                          {/* <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                sed do eiusmod tempor incididunt
                              </p> */}
                        </div>
                        <div className="content ml-3">
                          <div className="header">
                            <div className="amount">
                              <p>
                                <span className="property">Donate Amount: </span>
                                <span className="value">
                                  $ {item?.amount}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default DonarListModal;
