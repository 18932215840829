import React, { useEffect, useState } from "react";
import Footer from "../../component/Footer";
import Header from "../../component/Header";
import "../../assets/style/productdetail.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { blogbefore, cart1, heart1, proafter } from "../../constant/index";
import {
  GetFrequentProducts,
  GetProductDetailsApi,
  GetProductRatingApi,
  PostAddWishList,
  PostProductReviewApi,
} from "../../network/Network";
import { toast } from "react-toastify";
import { AddToCart } from "../../redux/actions/CartActions";
import { useDispatch, useSelector } from "react-redux";
import ReviewBox from "../../component/Review/ReviewBox";
import AllReviews from "../../component/Review/AllReviews";
import Skeleton from "react-loading-skeleton";
import ReactPlayer from "react-player";
import swal from "sweetalert";
import GrowingZoneSide from "../../component/GrowingZone/GrowingZoneSide";
import { no_image_product_details } from "../../constant";
import ProductsModal from "../../component/Modal/ProductsModal";

const ProductDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const ImageUrlRedux = useSelector((state) => state.AuthReducer.urlData);
  const cartDataRedux = useSelector((state) => state.CartReducer.cartData);
  const Token = useSelector((state) => state.AuthReducer.token);
  const CampaignReduxData = useSelector(
    (state) => state.AuthReducer.donationCampaign
  );
  let currentUrl = window.location.href.split("/");
  const [productDetails, setProductDetails] = useState(null);
  const [spinLoad, setSpinLoad] = useState(false);
  const [wishLoading, setWishLoading] = useState(false);
  const [active, setActive] = useState(false);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [reviewLoading, setReviewLoading] = useState(false);

  const [reviewSpin, setReviewSpin] = useState(false);
  const [reviewData, setReviewData] = useState([]);
  const [submitReview, setSubmitReview] = useState(false);

  const [quantity, setQuantity] = useState(1);
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedAddOn, setSelectedAddOn] = useState();
  const [selectCampPrice, setSelectCampPrice] = useState("");
  const [selectCamp_id, setSelectCamp_id] = useState("");
  const [frequentProducts, setFrequentProducts] = useState();
  const [freqSpin, setFreqSpin] = useState(false);
  const [waiting, setWaiting] = useState(false);

  const [addFreq, setAddFreq] = useState([]);
  const [amount, setAmount] = useState("");

  const [freqSize, setFreqSize] = useState();
  const [freqSizeItem, setFreqSizeItem] = useState();

  const [selectedAddonData, setSelectedAddonData] = useState();
  const [selectedAddonSizeData, setSelectedAddonSizeData] = useState();
  const [selectedAddonSize, setSelectedAddonSize] = useState();
  const [addonQuantity, setAddonQuantity] = useState(1);

  const [isOpenProductModal, setIsOpenProductModal] = useState(false);
  const [selectedAddOnShow, setSelectedAddOnShow] = useState(false);

  const handleRating = (rate) => {
    // console.log(rate);
    setRating(rate);
  };

  // product Details
  useEffect(() => {
    setSpinLoad(true);
    GetProductDetailsApi(currentUrl[4])
      .then((res) => {
        // console.log("details", res);
        setProductDetails(res?.data);
        setSpinLoad(false);
      })
      .catch((err) => {
        // console.log(err);
        setSpinLoad(false);
      });
  }, [currentUrl[4]]);

  const AddWishListHandler = (e) => {
    e.preventDefault();
    setWishLoading(true);
    let data = {
      product_id: currentUrl[4],
    };
    // console.log(data);
    PostAddWishList(data, Token)
      .then((res) => {
        setWishLoading(false);
        // console.log(res);
        setActive(!active);
        toast.success("Product Add to your Wishlist");
        // dispatch(WishListData(res?.data?.data?.wishlist))
      })
      .catch((err) => {
        setWishLoading(false);
        // console.log(err?.response);
        toast.error(err?.response?.data?.message);
      });
  };

  const Increment = (id) => {
    setQuantity(quantity + 1);
  };
  const Decrement = (id) => {
    setQuantity(quantity - 1);
  };

  const IncrementAddon = (id) => {
    setAddonQuantity(addonQuantity + 1);
  };
  const DecrementAddon = (id) => {
    setAddonQuantity(addonQuantity - 1);
  };

  const SelectPacks = (e, item) => {
    if (!selectedSize) {
      toast.error("Please Select Size First");
      return;
    }
    let newVarient = productDetails?.variation.filter(
      (val) => val?.size == selectedSize
    );
    // console.log("newVarient", newVarient);
    // console.log("item", item);
    if (parseInt(item?.quantity) <= newVarient[0]?.qty) {
      if (amount == item?.price) {
        setAmount("");
        setQuantity(1);
      } else {
        setAmount(e.target.value);
        setQuantity(parseInt(item?.quantity, 10));
      }
    } else {
      toast.error("Selected pack is out of stock");
      return;
    }
  };

  const AddToCartHandler = (item) => {
    if (!selectedSize) {
      toast.error("Please Select Size");
      return;
    }
    if (productDetails?.variation) {
      let newVarient = productDetails?.variation.filter(
        (val) => val?.size == selectedSize
      );
      // console.log("run");
      // console.log(newVarient, "test");
      if (newVarient[0]?.qty > 0) {
        let checkItemAlreadyExist = cartDataRedux.filter(
          (val) => val?.id === item?.id
        );
        let FindSelectedAddon;
        if (selectedAddOn) {
          FindSelectedAddon = selectedAddonData?.filter(
            (val) => val?.id === parseInt(selectedAddOn)
          );
        }
        // console.log("add on", selectedAddOn, FindSelectedAddon);
        if (checkItemAlreadyExist.length > 0) {
          swal({
            title: "Item Already Exist in Cart!!!",
          });
        } else {
          let productSizeId = productDetails?.variation.filter(
            (val) => val?.size == selectedSize
          );
          let data = {
            id: item?.id,
            price:
              selectedSize && amount
                ? amount
                : selectedSize
                ? VariationPrice()
                : item?.unit_price,
            quantity: quantity,
            type: amount ? "bulk" : "",
            size: selectedSize,
            campaign_price: selectCampPrice ? selectCampPrice : "",
            campaign_id: selectCamp_id ? selectCamp_id : "",
            productitem: item,
            deliveryDate: "",
            addon_item: selectedAddonArray ? selectedAddonArray : "",
            product_variant_id: productSizeId[0]?.id,
            // addon_item: FindSelectedAddon ? FindSelectedAddon : "",
            // addon_id: selectedAddOn ? selectedAddOn : "",
           
            // addon_variant_id: selectedAddonSizeData?.variation[0]?.id,
            // addon_variationPrice: selectedAddonSizeData?.variation[0]?.price,
            // addonQuantity: addonQuantity,
          };
          dispatch(AddToCart(data));
          toast("Product successfully added");
          setIsOpenProductModal(true);
        }
      } else {
        toast("Selected variation is out of stock");
        return;
      }
    }
  };

  const SubmitRating = (e) => {
    e.preventDefault();
    setReviewLoading(true);
    if(!Token){
      toast.error("Please login");
      setReviewLoading(false);
      return;
    }
    if (!rating || !comment) {
      toast.error("Please Enter All Fields");
      setReviewLoading(false);
      return;
    }
    let data = {
      product_id: productDetails?.id,
      comment: comment,
      rating: rating,
    };
    PostProductReviewApi(data, Token)
      .then((res) => {
        setReviewLoading(false);
        // console.log("post review", res);
        toast.success("Successfully send");
        setSubmitReview(!submitReview);
        setRating("");
        setComment("");
      })
      .catch((err) => {
        setReviewLoading(false);
        // console.log(err?.response?.data?.message);
        setSubmitReview(!submitReview);
        if (err?.response?.data?.message === "Unauthenticated.") {
          toast.error("Please Login");
        }
        setRating("");
        setComment("");
      });
  };

  // product Reviews
  useEffect(() => {
    setReviewSpin(true);
    GetProductRatingApi(currentUrl[4])
      .then((res) => {
        // console.log("reviews", res);
        setReviewData(res?.data?.data);
        setReviewSpin(false);
      })
      .catch((err) => {
        // console.log(err);
        setReviewSpin(false);
      });
  }, [submitReview]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const FrequentAddToCartHandler = (e) => {
    let i;
    setWaiting(true);
    for (i = 0; i < addFreq?.length; i++) {
      // console.log("count", i);
      let checkItemAlreadyExist = cartDataRedux.filter(
        (val) => val?.id === addFreq[i]?.item?.id
      );
      if (checkItemAlreadyExist?.length > 0) {
        alert("Item Already Exist in Cart!!!");
      } else {
        let data = {
          id: addFreq[i]?.item?.id,
          price: addFreq[i]?.item?.unit_price,
          quantity: 1,
          size: addFreq[i]?.item?.variation ? addFreq[i]?.selectSize : " ",
          campaign_price: "",
          campaign_id: "",
          productitem: addFreq[i]?.item,
          deliveryDate: " ",
          addon_item: "",
          product_variant_id: "",
        };
        // console.log("data rep", data);
        dispatch(AddToCart(data));
      }
    }
    toast("Product successfully added");
    setWaiting(false);
    navigate("/cart");
  };

  // Frequent Products
  useEffect(() => {
    setFreqSpin(true);
    GetFrequentProducts(currentUrl[4])
      .then((res) => {
        // console.log("frequent product", res);
        setFrequentProducts(res?.data);
        setFreqSpin(false);
      })
      .catch((err) => {
        // console.log(err);
        setFreqSpin(false);
      });
  }, [productDetails]);

  const AddFrequentHandler = (e, item) => {
    e.preventDefault();
    let data = addFreq?.filter((val) => val?.item?.id == item?.id);
    if (!freqSize && addFreq?.length < 1) {
      toast?.error("Please select Size first");
      return;
    }
    // console.log(data[0]?.id);
    if (data[0]?.item?.id == item?.id) {
      setAddFreq((preVal) => {
        return [...preVal.filter((val) => val?.item?.id !== item?.id)];
      });
    } else {
      if (!freqSize) {
        toast?.error("Please select Size first");
        return;
      }
      // console.log("FreqfilterVariationQty", FreqfilterVariationQty());
      if (FreqfilterVariationQty() > 0) {
        setAddFreq((preVal) => {
          return [...preVal, { item, selectSize: freqSize }];
        });
      } else {
        toast?.error("Selected Variant is out of stock");
        return;
      }
    }
    setFreqSize("");
    // console.log("freq all data", addFreq);
  };

  const FrequentVariations = (e, item) => {
    setFreqSize(e);
    setFreqSizeItem(item);
    FreqfilterVariationQty();
  };

  const FreqfilterVariationQty = () => {
    let varient = freqSize;
    if (freqSizeItem?.variation) {
      let newVarient = freqSizeItem?.variation.filter(
        (val) => val?.size == varient
      );
      if (newVarient.length > 0) {
        return newVarient[0].qty || 1;
      }
      return 1;
    }
    return 1;
  };

  const filterVariationQty = () => {
    let varient = selectedSize;
    // console.log("ya wala", varient, productDetails?.variation)
    if (productDetails?.variation) {
      let newVarient = productDetails?.variation.filter(
        (val) => val?.size == varient
      );
      if (newVarient.length > 0) {
        return newVarient[0]?.qty;
      }
      // return 1;
    }
    // return 1;
  };

  const VariationPrice = () => {
    let varient = selectedSize;
    // console.log("vv", varient);
    if (productDetails?.variation) {
      let data = productDetails?.variation?.filter(
        (val) => val?.size === varient
      );
      // console.log("data0", data);
      if (data?.length > 0) {
        return data[0]?.price;
      }
      return 1;
    }
    return 1;
  };

  const SelectedAddonDataHandler = (e) => {
    e.preventDefault();
    const Addon = productDetails?.variation?.filter(
      (val) => val?.size == e.target.value
    );
    setSelectedAddonData(Addon[0]?.addon);
    // console.log("Addon", Addon);
  };

  const SelectedAddonSizeHandler = (e) => {
    e.preventDefault();
    const AddonSize = selectedAddonData.filter(
      (val) => val?.id == e.target.value
    );

    setSelectedAddonSizeData(AddonSize[0]);
    // console.log("AddonSize", AddonSize);
  };

  // console.log("ya", selectedAddonSizeData);

  const filterAddonVariationQty = () => {
    // console.log("setSelectedAddOn", selectedAddOn);
    let varient = selectedAddOn;
    // console.log("selectedAddonSizeData", selectedAddonSizeData);
    if (selectedAddonSizeData?.variation?.length > 0) {
      let newVarient = selectedAddonSizeData?.variation.filter(
        (val) => val?.product_id == varient
      );
      if (newVarient.length > 0) {
        return newVarient[0].qty || 1;
      }
      return 1;
    }
    return 1;
  };

  const [selectedAddonArray, SetselectedAddonArray] = useState([]);

  const AddonSelectionHandler = (e) => {
    // console.log(e.target.value);
    // SetselectedAddonArray()
    if (e.target.checked === true) {
      const newSelection = selectedAddonData?.filter(
        (val) => val?.id == parseInt(e.target.value)
      );
      SetselectedAddonArray((preVal) => {
        return [...preVal, {addon_item: newSelection}];
      });
    } else {
      SetselectedAddonArray((preVal) => {
        return [
          ...preVal.filter(
            (val) => val?.addon_item?.[0].id !== parseInt(e.target.value)
          ),
        ];
      });
    }
  };
  // console.log("selectedAddonArray", selectedAddonArray);

  if (productDetails == null) {
    return (
      <div className="container">
        <div className="row loader-container">
          {[0, 1, 2, 3, 4, 5, 7, 8, 9]?.map((item, index) => {
            return (
              <div className="col-md-12 mt-3" key={index}>
                <Skeleton count={5} />
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <>
      <Header />
      <section className="innerBanner">
        <div className="container">
          <div className="inner-box">
            <div className="title">
              <h2>{productDetails?.name}</h2>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Product Detail Sec Start Here --> */}
      <section className="product-detail">
        <div className="before">
          <figure>
            <img src={blogbefore} className="img-fluid" />
          </figure>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="product-images">
                {productDetails?.images[0] ? (
                  <>
                    <div className="single-image">
                      <Slider
                        asNavFor={nav2}
                        ref={(slider1) => setNav1(slider1)}
                      >
                        {productDetails?.images?.map((item, index) => {
                          return (
                            <figure
                              key={index}
                              className="productDetail-slider"
                            >
                              <img
                                src={`${ImageUrlRedux?.product_image_url}/${item}`}
                                className="img-fluid"
                              />
                            </figure>
                          );
                        })}
                      </Slider>
                    </div>
                    <div className="slider-images">
                      <Slider
                        asNavFor={nav1}
                        ref={(slider2) => setNav2(slider2)}
                        slidesToShow={
                          productDetails?.images?.length > 0 ||
                          productDetails?.images?.length < 3
                            ? productDetails?.images?.length
                            : 3
                        }
                        swipeToSlide={true}
                        focusOnSelect={true}
                      >
                        {productDetails?.images?.map((item, index) => {
                          return (
                            <figure key={index}>
                              <img
                                src={`${ImageUrlRedux?.product_image_url}/${item}`}
                                className="img-fluid"
                              />
                            </figure>
                          );
                        })}
                      </Slider>
                    </div>
                  </>
                ) : (
                  <img
                    className="no_image_avatar_product_details"
                    src={`${
                      productDetails?.thumbnail
                        ? `${ImageUrlRedux?.product_thumbnail_url}/${productDetails?.thumbnail}`
                        : no_image_product_details
                    }`}
                  />
                )}
              </div>

              <section className="frequently_bought">
                <div className="container">
                  <div className="heading">
                    <h2>Frequently Bought Together</h2>
                  </div>
                  <div className="row">
                    {freqSpin == null ? (
                      <>
                        {[0, 1, 2, 3, 4, 5, 7, 8, 9]?.map((item, index) => {
                          return (
                            <div className="col-md-4 mt-3 " key={index}>
                              <Skeleton count={5} />
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        {frequentProducts?.products?.map((item, index) => {
                          return (
                            <div className="col-lg-3 col-md-6 mb-3" key={index}>
                              <div
                                className={`${
                                  index == 3
                                    ? "product-box"
                                    : "product-box pro_box"
                                }`}
                                id="multi_select"
                              >
                                {/* <Link to={`/product-detail/${item?.id}`}> */}
                                <div
                                  className={`${
                                    addFreq
                                      ?.map((item) => {
                                        return item?.item?.id;
                                      })
                                      .includes?.(item?.id)
                                      ? "selected-freq pro-img"
                                      : "pro-img"
                                  }`}
                                  onClick={(e) => AddFrequentHandler(e, item)}
                                >
                                  <figure>
                                    <img
                                      src={
                                        item?.thumbnail
                                          ? `${ImageUrlRedux?.product_thumbnail_url}/${item?.thumbnail}`
                                          : `${no_image_product_details}`
                                      }
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </figure>
                                </div>
                                <div className="pro-detail">
                                  <div className="name">
                                    <h4>{item?.name}</h4>
                                  </div>
                                  <div className="price">
                                    <h4>${item?.unit_price}</h4>
                                  </div>
                                </div>

                                {/* </Link> */}
                              </div>

                              <select
                                id="size"
                                className="form-control"
                                key={index}
                                onChange={(e) =>
                                  FrequentVariations(e.target.value, item)
                                }
                              >
                                <option selected disabled>
                                  Select Size
                                </option>
                                {item?.variation?.map((item2, index) => {
                                  return (
                                    <option value={item2?.type} key={index}>
                                      {item2?.type}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          );
                        })}
                      </>
                    )}
                    <div className="button-group frequentlybtn">
                      <button
                        className="btn"
                        onClick={(e) => FrequentAddToCartHandler(e)}
                        disabled={waiting || addFreq?.length < 1}
                      >
                        {waiting ? "Please Wait" : "Add to cart"}{" "}
                        <img src={cart1} className="img-fluid" />
                      </button>
                    </div>
                  </div>
                </div>
              </section>

              {productDetails?.video_url ? (
                <div className="plant-video">
                  <div className="heading">
                    <h2>Plant Video</h2>
                  </div>
                  <div className="video-wrapper">
                    <div className="video-container">
                      <ReactPlayer
                        // light={`${ImageUrlRedux?.product_video_thumbnail_url}/${productDetails?.video_thumbnail}`}
                        url={`${ImageUrlRedux?.product_video_url}/${productDetails?.video_url}`}
                        className="react-player"
                        width="100%"
                        height="307px"
                        overflow="hidden"
                        backgroundSize="cover"
                        controls
                      />
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="reviews_sec">
                <div className="heading">
                  <h3>Reviews</h3>
                </div>
                <div className="reviewDv">
                  <AllReviews reviewData={reviewData} />
                </div>
              </div>
              <ReviewBox
                handleRating={handleRating}
                rating={rating}
                SubmitRating={SubmitRating}
                comment={comment}
                setComment={setComment}
                reviewLoading={reviewLoading}
              />
            </div>
            <div className="col-lg-6">
              <div className="details">
                <div className="header">
                  <div className="heading">
                    <h4>{productDetails?.category?.name}</h4>
                    <h3>{productDetails?.name}</h3>
                  </div>
                  <div className="price-tag">
                    <h5>
                      Price :{" "}
                      <span className="value">
                        $
                        {selectedSize && amount
                          ? amount
                          : selectedSize
                          ? VariationPrice()
                          : productDetails?.variation[0]?.price ? productDetails?.variation[0]?.price : productDetails?.unit_price}
                      </span>
                    </h5>
                    {productDetails?.current_stock > 0 ? null : (
                      <p>Out of Stock</p>
                    )}
                  </div>
                </div>
                <div className="description">
                  <h4>Description</h4>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: productDetails?.details,
                    }}
                  />
                </div>

                <div className="additional-info">
                  <h4>Additional Information </h4>
                  <div className="row">
                    <div className="col-md-6 border-right">
                      <ul>
                        <li>
                          <span className="property">Latin Name:</span>{" "}
                          <span className="value">
                            {productDetails?.latin_name}
                          </span>
                        </li>
                        <li>
                          <span className="property">Common Name:</span>{" "}
                          <span className="value">
                            {productDetails?.common_name}
                          </span>
                        </li>
                        <li>
                          <span className="property">Sun Exposure:</span>{" "}
                          <span className="value">
                            {productDetails?.sun_exposure}
                          </span>
                        </li>

                        <li>
                          <span className="property">Annual Growth:</span>{" "}
                          <span className="value">
                            {productDetails?.annual_growth}
                          </span>
                        </li>
                        <li>
                          <span className="property">HxW @10 Years:</span>{" "}
                          <span className="value">{productDetails?.hxw}</span>
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-6">
                      <ul>
                        <li>
                          <span className="property">Leaf Type:</span>{" "}
                          <span className="value">
                            {productDetails?.leaf_type}
                          </span>
                        </li>

                        {JSON?.parse(productDetails?.colors) &&
                        JSON?.parse(productDetails?.colors)?.length > 0 ? (
                          <>
                            {JSON?.parse(productDetails?.colors)?.map(
                              (item, index) => {
                                return (
                                  <li key={index}>
                                    <span className="property">Color:</span>{" "}
                                    <span className="value">{item?.name}</span>
                                  </li>
                                );
                              }
                            )}
                          </>
                        ) : null}

                        <li>
                          <span className="property">Growing Zone:</span>{" "}
                          <span className="value">
                            {productDetails?.growing_zone?.zone_name}
                          </span>
                        </li>

                        <li>
                          <span className="property">Species:</span>{" "}
                          <span className="value">
                            {productDetails?.species}
                          </span>
                        </li>
                        <li>
                          <span className="property">Growth Rate:</span>{" "}
                          <span className="value">
                            {productDetails?.growth_rate}
                          </span>
                        </li>
                        {/* <li>
                          <span className="property">Hardiness Zone:</span>{" "}
                          <span className="value">{productDetails?.hardiness_zone}</span>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="variations">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label for="size">Select Size: </label>
                        {productDetails?.variation?.length > 0 ? (
                          <select
                            id="size"
                            className="form-control"
                            onChange={(e) => {
                              setSelectedSize(e.target.value);
                              filterVariationQty();
                              setQuantity(1);
                              VariationPrice();
                              SelectedAddonDataHandler(e);
                              setAddonQuantity(1);
                              setSelectedAddOnShow(false);
                            }}
                          >
                            <option selected disabled>
                              Select Size
                            </option>
                            {productDetails?.variation?.map((item, index) => {
                              return (
                                <option value={item?.size} key={index}>
                                  {item?.size?.replace("_", " ")}
                                </option>
                              );
                            })}
                          </select>
                        ) : null}

                        {/* {productDetails?.choice_options?.map((item, index) => {
                          return (
                            <>
                              {item?.title == "Size" ? (
                                <>
                                  <select
                                    id="size"
                                    className="form-control"
                                    key={index}
                                    onChange={(e) => {
                                      setSelectedSize(e.target.value);
                                      filterVariationQty();
                                      setQuantity(1);
                                      VariationPrice();
                                    }}
                                  >
                                    <option>Select Size</option>
                                    {item?.options?.map((item2, index) => {
                                      {
                                        console.log("size", item2);
                                      }
                                      return (
                                        <option value={item2} key={index}>
                                          {item2}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </>
                              ) : null}
                            </>
                          );
                        })} */}
                      </div>
                      {selectedSize ? (
                        <p>
                          {" "}
                          {selectedSize?.replace("_", " ")} size: only{" "}
                          {filterVariationQty()}{" "}
                          {filterVariationQty() > 1 ? "items" : "item"} left
                        </p>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label for="Quantity">Select Quantity </label>
                        <div className="quantity">
                          <button
                            className="minus"
                            onClick={() =>
                              Decrement(productDetails?.productitem?.id)
                            }
                            disabled={quantity <= 1 || amount}
                          >
                            -
                          </button>
                          <span> {quantity}</span>
                          <button
                            className={`plus ${filterVariationQty()}`}
                            // className="plus"
                            onClick={() =>
                              Increment(productDetails?.productitem?.id)
                            }
                            disabled={
                              filterVariationQty() == quantity || amount
                            }
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mt-2">
                      <div>
                        {selectedSize && selectedAddonData?.length > 0 ? (
                          <>
                            <label for="Add">Don't Forget These:</label>
                            <div className="form-group">
                              <div className="row mt-1 mb-2">
                                {selectedAddonData?.map((item) => {
                                  return (
                                    <>
                                      <div className="col-md-3 mt-3" key={item?.id}>
                                        <div className="addonContainer">
                                          <div>
                                            <input
                                              type="checkbox"
                                              id={item?.id}
                                              value={item?.id}
                                              onChange={(e) =>
                                                AddonSelectionHandler(e)
                                              }
                                            />
                                          </div>
                                          <div className="addon-img">
                                            <img
                                              src={`${ImageUrlRedux?.product_thumbnail_url}/${item?.thumbnail}`}
                                              className="image-fluid"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-9 addon-data mt-2">
                                        <p>Addon Product: {item?.name}</p>
                                        {item?.variation.length > 0 ? (
                                          <>
                                            <p>
                                              Pack :{" "}
                                              {item?.variation[0]?.size?.replace(
                                                /["_"]/g,
                                                " "
                                              )}
                                            </p>
                                            <p>${item?.variation[0]?.price} </p>
                                          </>
                                        ) : null}
                                      </div>
                                    </>
                                  );
                                })}
                              </div>

                              {/* {console.log(
                                "selectedAddonData this",
                                selectedAddonData
                              )} */}
                              {/* <select
                                id="Add"
                                className="form-control addon-name-select"
                                onClick={(e) => {
                                  setSelectedAddOn(e.target.value);
                                  SelectedAddonSizeHandler(e);
                                  setSelectedAddOnShow(true);
                                  filterAddonVariationQty();
                                }}
                              >
                                <option value={null} selected>
                                  Select Addon
                                </option>
                                {selectedAddonData?.map((item, index) => {
                                  return (
                                    <option value={item?.id} key={index}>
                                      {item?.name}
                                    </option>
                                  );
                                })}
                              </select> */}
                            </div>
                          </>
                        ) : null}
                      </div>

                      {selectedAddOnShow &&
                      selectedAddonData &&
                      selectedAddonSizeData ? (
                        <>
                          <div className="row mt-4 mb-2">
                            <div className="col-md-3">
                              <div className="addonContainer">
                                <div className="addon-img">
                                  <img
                                    src={`${ImageUrlRedux?.product_thumbnail_url}/${selectedAddonSizeData?.thumbnail}`}
                                    className="image-fluid"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-9 addon-data">
                              <p>
                                Addon Product: {selectedAddonSizeData?.name}
                              </p>
                              {selectedAddonSizeData ? (
                                <>
                                  <p>
                                    {selectedAddonSizeData?.variation[0]?.size?.replace(
                                      /["_"]/g,
                                      " "
                                    )}
                                  </p>
                                  <p>
                                    $
                                    {selectedAddonSizeData?.variation[0]?.price}{" "}
                                  </p>
                                </>
                              ) : null}
                            </div>
                          </div>

                          <div>
                            {selectedAddOn && selectedAddOnShow ? (
                              <>
                                <p>
                                  Addon: only {filterAddonVariationQty()}{" "}
                                  {filterAddonVariationQty() > 1
                                    ? "packs"
                                    : "pack"}{" "}
                                  left
                                </p>

                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label for="Quantity">
                                      Addon Quantity{" "}
                                    </label>
                                    <div className="quantity">
                                      <button
                                        className="minus"
                                        onClick={() => DecrementAddon()}
                                        disabled={addonQuantity <= 1}
                                      >
                                        -
                                      </button>
                                      <span> {addonQuantity}</span>
                                      <button
                                        className={`plus ${filterAddonVariationQty()}`}
                                        onClick={() => IncrementAddon()}
                                        disabled={
                                          filterAddonVariationQty() ==
                                          addonQuantity
                                        }
                                      >
                                        +
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : null}
                          </div>
                        </>
                      ) : null}

                      <div>
                        {/* {selectedSize &&
                        selectedAddonData?.length > 0 &&
                        selectedAddonSizeData?.variation?.length > 0 ? (
                          <div className="col-md-6">
                            <div className="form-group">
                              <label for="size">Addon Size: </label>
                              <select
                                id="size"
                                className="form-control"
                                onChange={(e) => {
                                  setSelectedAddonSize(e.target.value);
                                  filterAddonVariationQty();
                                  // setQuantity(1);
                                  // VariationPrice();
                                  // SelectedAddonDataHandler(e)
                                }}
                              >
                                <option>Select Size</option>
                                {selectedAddonSizeData?.variation?.map((item, index) => {
                                  return (
                                    <option value={item?.id} key={index}>
                                      {item?.size}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        ) : null} */}
                      </div>

                      {/* {
                        productDetails?.variation.length > 0 && selectedSize ? (
                          <>
                          <div className="form-group">
                            <label for="Add">Don't Forget These:</label>
                            <select
                              id="Add"
                              className="form-control"
                              onChange={(e) => setSelectedAddOn(e.target.value)}
                            >
                              <option>Select Addon</option>
                              <>
                            {productDetails?.variation?.filter((val)=>{
                                if(val?.size != selectedSize){
                                  console.log("ya",val?.size, selectedSize)
                                  return
                                }
                                else {
                                  return console.log("per", val)
                                }
                              })
                              ?.addon?.map((item,index)=>{
                                return(
                                      <>
                                     
                                        <option
                                          value={item?.id}
                                          key={index}
                                        >
                                          {item?.name}
                                        </option>
                                      </>
                                )
                              })
                            }
                          </>
                            </select>
                            </div>
                          </>
                        ) : (null)
                      }
             */}

                      {/* {productDetails?.addons.length > 0 ? (
                        <>
                          <div className="form-group">
                            <label for="Add">Don't Forget These:</label>
                            <select
                              id="Add"
                              className="form-control"
                              onChange={(e) => setSelectedAddOn(e.target.value)}
                            >
                              <option>Select Addon</option>
                              {productDetails?.addons?.map((item, index) => {
                                return (
                                  <>
                                    <option
                                      value={item?.single_addon?.id}
                                      key={index}
                                    >
                                      {item?.single_addon?.name} $
                                      {item?.single_addon?.unit_price}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                          </div>
                          {selectedAddOn ? (
                            <>
                              {productDetails?.addons?.map((item, index) => {
                                return (
                                  <>
                                    {item?.single_addon?.id == selectedAddOn ? (
                                      <div className="addon-details">
                                        <div className="addon-img">
                                          <img
                                            src={`${ImageUrlRedux?.product_thumbnail_url}/${item?.single_addon?.thumbnail}`}
                                            alt=""
                                            className="img-fluid"
                                          />
                                        </div>
                                        <p className="addon-name ml-2">
                                          {" "}
                                          {item?.single_addon?.name}
                                        </p>
                                        <p className="addon-price ml-3">
                                          ${item?.single_addon?.unit_price}
                                        </p>
                                      </div>
                                    ) : null}
                                  </>
                                );
                              })}
                            </>
                          ) : null}
                        </>
                      ) : null} */}
                    </div>
                    <div className="col-md-12">
                      {productDetails?.bulk_price &&
                      productDetails?.bulk_price?.length > 0 ? (
                        <div className="variations mt-4">
                          <div className="form-group">
                            <label for="SelectAdds">Choose Your Pack : </label>
                            <div className="input-group">
                              {productDetails?.bulk_price?.map(
                                (item, index) => {
                                  {
                                    // console.log(amount, item?.price);
                                  }
                                  return (
                                    <label
                                      className={`${
                                        amount == item?.price
                                          ? "add_container-Selected add_container"
                                          : "add_container"
                                      }`}
                                      key={index}
                                    >
                                      <input
                                        type="radio"
                                        hidden
                                        name={`${item?.quantity}${item?.price}`}
                                        value={item?.price}
                                        checked={
                                          amount == item?.price ? true : false
                                        }
                                        onClick={(e) => SelectPacks(e, item)}
                                      />
                                      <span className="checkmark">
                                        <h5 className="count">
                                          {" "}
                                          {item?.quantity}
                                        </h5>
                                        <h5 className="price">
                                          ${item?.price}
                                        </h5>
                                      </span>
                                    </label>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="donation">
                  <div className="title">
                    <h4>Donation</h4>
                    <a href="/donate" target="_blank">
                      Learn More
                    </a>
                  </div>
                  <div className="input-group">
                    <div className="form-group br-right">
                      <label for="Amount">Amount to donate: </label>
                      <select
                        className="form-control"
                        id="Amount"
                        onChange={(e) => setSelectCampPrice(e.target.value)}
                      >
                        <option value="" selected disabled>
                          Amount
                        </option>
                        <option value={"5"}>$5</option>
                        <option value={"12"}>$12</option>
                        <option value={"20"}>$20</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label for="Campaign">Select Campaign : </label>
                      <select
                        id="size"
                        className="form-control custom-select"
                        onChange={(e) => setSelectCamp_id(e.target.value)}
                      >
                        <option selected disabled>
                          Select Campaign
                        </option>
                        {CampaignReduxData?.map((item, index) => {
                          return (
                            <>
                              {item?.goal_price != item?.total_donation ? (
                                <option value={item?.id} key={index}>
                                  {item?.title}
                                </option>
                              ) : null}
                            </>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                {productDetails?.current_stock > 0 ? (
                  <div className="button-group">
                    <button
                      className="btn mr-2"
                      onClick={() => AddToCartHandler(productDetails)}
                    >
                      Add to cart <img src={cart1} className="img-fluid" />
                    </button>
                    <button
                      onClick={(e) => AddWishListHandler(e)}
                      className="btn"
                      disabled={wishLoading}
                    >
                      {wishLoading ? "Loading..." : "Add to wishlist"}{" "}
                      <img src={heart1} className="img-fluid" />
                    </button>
                  </div>
                ) : null}

                <GrowingZoneSide
                  ImageUrlRedux={ImageUrlRedux}
                  productDetails={productDetails}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="after">
          <figure>
            <img src={proafter} className="img-fluid" />
          </figure>
        </div>
      </section>
      <Footer />

      <ProductsModal
        setIsOpenProductModal={setIsOpenProductModal}
        isOpenProductModal={isOpenProductModal}
        frequentProducts={frequentProducts}
      />
    </>
  );
};

export default ProductDetail;
