import React, { useEffect, useState } from "react";
import Header from "../../component/Header";
import "../../assets/style/login.css";
import { blogbefore, proafter, arrow } from "../../constant/index";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { signUp } from "../../redux/actions/AuthActions";

const Signup = () => {
  const dispatch = useDispatch();
  const Token = useSelector((state) => state.AuthReducer.token);
  const Navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);

  const SignUpHandler = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    if (
      !firstName ||
      !lastName ||
      !phone ||
      !email ||
      !password
      // !confirmPassword ||
    ) {
      toast.error("Please Enter All Fields");
      setBtnLoading(false);
      return;
    }
    if (firstName?.length < 3 || firstName?.length > 30) {
      toast.error("First name must be between 3 to 30 characters");
      setBtnLoading(false);
      return;
    }
    if (lastName?.length < 3 || lastName?.length > 30) {
      toast.error("Last name must be between 3 to 30 characters");
      setBtnLoading(false);
      return;
    }
    if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      toast.error("Invalid Email");
      setBtnLoading(false);
      return;
    }

    if(phone.length < 11 || phone.length > 15){
      toast.error("Phone number must be betwween 11 to 15 digits");
      setBtnLoading(false);
      return;
    }
    if (password?.length < 8) {
      toast.error("Password must be at least 8 characters");
      setBtnLoading(false);
      return;
    }
    if (password != confirmPassword) {
      toast.error("Please enter same password and confirm password");
      setBtnLoading(false);
      return;
    } else {
      var data = new FormData();
      data.append("f_name", firstName);
      data.append("l_name", lastName);
      data.append("email", email);
      data.append("phone", phone);
      data.append("password", password);
      data.append("confirm_password", confirmPassword);
      // console.log("complete Data", data);
      let send = await dispatch(signUp(data, Navigate, setBtnLoading));
    }
  };

  useEffect(() => {
    if(Token){
      Navigate('/')
    }
    }, [])

  return (
    <>
      <Header />
      <section className="login">
        <div className="before">
          <figure>
            <img src={blogbefore} className="img-fluid" />
          </figure>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <div className="auth-box">
                <div className="heading">
                  <h2>Sign Up</h2>
                  {/* <h4>
                    Welcome back to <span className="bld">Kigi Nursery</span>
                  </h4> */}
                  <p>
                    Already A member ?<Link to="/login">Sign in</Link>
                  </p>
                </div>
                <form action="">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>First Name</label>
                        <input
                          type="text"
                          className="form-control"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Last Name</label>
                        <input
                          type="text"
                          className="form-control"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Email Address</label>
                        <input
                          type="email"
                          className="form-control"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Contact Number</label>
                        <input
                          type="number"
                          className="form-control"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Password</label>
                        <input
                          type="password"
                          className="form-control"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Confirm Password</label>
                        <input
                          type="password"
                          className="form-control"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <button
                    className="btn"
                    onClick={(e) => SignUpHandler(e)}
                    disabled={btnLoading}
                  >
                    <span>{btnLoading ? "Loading..." : "SIGN UP"} </span>{" "}
                    <img src={arrow} className="img-fluid" />
                  </button>
                </form>
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>
        <div className="after">
          <figure>
            <img src={proafter} className="img-fluid" />
          </figure>
        </div>
      </section>
    </>
  );
};

export default Signup;
