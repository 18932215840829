import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import "../../assets/style/Custom.css";
import { productdetail3 } from "../../constant";
import { useSelector } from "react-redux";

function ViewOrderModal(props) {
  const { setIsOpenOrderModal, isOpenOrderModal, selectedData } = props;
  const ImageUrlRedux = useSelector((state) => state.AuthReducer.urlData);
  const Navigate = useNavigate();
  // console.log("selected", selectedData);

  return (
    <>
      <Modal
        isOpen={isOpenOrderModal}
        toggle={() => {
          setIsOpenOrderModal(false);
        }}
        className="custom-modal modal-width orderView-Modal"
      >
        {/* <FontAwesomeIcon
          icon={faTimesCircle}
          className="modal-close"
          onClick={() => setIsOpenAddressModal(false)}
        /> */}
        {/* <h2 style={{ textAlign: "center" }}>Add New Address</h2> */}
        <div
          // className="modal fade"
          id="orderDetail"
          // tabindex="-1"
          // aria-labelledby="orderDetailLabel"
          // aria-hidden="true"
        >
          <div
          // className="modal-dialog modal-dialog-centered"
          >
            <div>
              <div className="modal-header">
                {/* <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button> */}
              </div>
              <div className="modal-body">
                <div className="heading">
                  <h2 className="modal-mainTitle">Order Details</h2>
                </div>
                <div className="all-orders">
                  {selectedData?.order_detail?.map((item, index) => {
                    return (
                      <div className="order-detail" key={index}>
                        <div className="img-box">
                          <figure>
                            <img
                              src={`${ImageUrlRedux?.product_thumbnail_url}/${item?.product?.thumbnail}`}
                              alt=""
                              className="img-fluid"
                            />
                          </figure>
                        </div>
                        <div className="details">
                          <h5 className="name">{item?.product?.name}</h5>
                          <h5 className="price">
                            <span className="pro-name">
                              Price: ${item?.price}
                            </span>
                          </h5>
                          <h5 className="price">
                            <span className="order-no">
                              Order no: #{item?.order_id}
                            </span>
                          </h5>
                          {item?.delivery_date ? (
                            <h5 className="price">
                              <span
                                className="order-no"
                                style={{ fontSize: "12px" }}
                              >
                                Delivery Date : {item?.delivery_date?.value}
                              </span>
                            </h5>
                          ) : null}

                          {item?.order_detail_addon_variant.length > 0 ? (
                            <>
                            {
                              item?.order_detail_addon_variant?.map((item)=>{
                                return(
                                  <h5 className="status" key={item?.id}>
                                  <span>
                                    <label>Addon</label>
                                    <p className="m-0">
                                      {item?.addon_variants?.addon?.name } $
                                      {item?.addon_variants?.addon?.unit_price }
                                    </p>
                                  </span>
                                </h5>
                                )
                              })
                            }
                            </>
                          ) : null}

                          {item?.campaign_price ? (
                            <h5 className="status">
                              <span>
                                <label>Donation : </label> $
                                {item?.campaign_price}
                              </span>
                            </h5>
                          ) : null}

                          <h5 className="status">
                            <span>
                              <label>Quantity</label>({item?.qty})
                            </span>
                            <span>{item?.payment_status}</span>
                          </h5>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <ul className="order-summary">
                  <li>
                    <span>Sub Total :</span>
                    <span>$ {selectedData?.order_amount}</span>
                  </li>
                  <li>
                    <span>Discount Coupon Code Applied :</span>
                    <span>-$ {selectedData?.discount_amount}</span>
                  </li>
                  <li>
                    <span>Discount Gift Code Applied :</span>
                    <span>-$ {selectedData?.gift_certificate_price}</span>
                  </li>
                  <li>
                    <span>Shipping Cost :</span>
                    <span>$ {selectedData?.shipping_amount}</span>
                  </li>
                  <li>
                    <span>Total Amount :</span>
                    <span>
                      ${" "}
                      {selectedData?.order_amount -
                        (selectedData?.gift_certificate_price +
                          selectedData?.discount_amount)}
                    </span>
                  </li>
                </ul>
                <div className="button-group">
                  <Link to="/all-plants" className="btn">
                    Continue Shopping
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ViewOrderModal;
