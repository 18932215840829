import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../../component/Footer";
import Header from "../../component/Header";

const Thankyou = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <>
    <Header/>
      {/* <!-- Thank You Sec Css Start Here --> */}
      <section class="tahnk-you">
        <div class="container">
          <div class="row">
            <div class="col-lg-3"></div>
            <div class="col-lg-6">
              <div class="thank-box">
                <div class="heading">
                  <h3>Thank You</h3>
                </div>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Nostrum repellendus voluptates amet, provident nam,
                  consequuntur corrupti, voluptatibus explicabo unde impedit
                  nemo modi? Est placeat ipsam provident? Autem laborum
                  laboriosam doloremque!
                </p>
                <div class="button-group">
                  <Link to="/" class="kigi-btn">
                    Back to home
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-lg-3"></div>
          </div>
        </div>
      </section>
      {/* <!-- Thank You Sec Css End Here --> */}
      <Footer/>
    </>
  );
};

export default Thankyou;
