import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import "../../assets/style/Custom.css";
import { no_image_product_details, productdetail3 } from "../../constant";
import { useSelector } from "react-redux";
import { AiFillCloseCircle } from "react-icons/ai";

function ProductsModal(props) {
  const { setIsOpenProductModal, isOpenProductModal,frequentProducts } = props;
  const ImageUrlRedux = useSelector((state) => state.AuthReducer.urlData);
  const Navigate = useNavigate();
  const isLogin = useSelector((state) => state.AuthReducer.isLogin);
  return (
    <>
      <Modal
        isOpen={isOpenProductModal}
        toggle={() => {
            setIsOpenProductModal(false);
        }}
        className="custom-modal modal-width orderView-Modal frequent-modal-main"
      >
        <AiFillCloseCircle 
            onClick={(e)=> setIsOpenProductModal(false)}
            className="close"
        />
        {/* <h2 style={{ textAlign: "center" }}>Add New Address</h2> */}
        <div
        //   className="frequent-modal-main"
        //   id="donors"
          // tabindex="-1"
        >
          <div
          // className="modal-dialog modal-dialog-centered"
          >
            <div
            //   className="modal-content"
            >
              <div className="modal-header">
                <h5 >Ok 1 Item Was Added To your Cart</h5>
                <h2 >What Next?</h2>
                {/* <!-- <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> --> */}
              </div>
              <div className="modal-body">
                <div>
                    <div className="row">
                        <div className="col-md-6">
                            <button className="btn"
                            onClick={(e)=>Navigate('/cart')}
                            >View Cart</button>
                        </div>
                        <div className="col-md-6 ">
                            <button className="btn"
                            onClick={(e)=>Navigate('/all-plants')}
                            >Continue Shopping</button>
                        </div>
                        {/* <div className="col-md-4">
                            <button className="btn"
                             onClick={(e)=>Navigate('/shop')}
                            >Checkout</button>
                        </div> */}
                    </div>
                    <div className="products-freq-modal row">
                    {frequentProducts?.products?.map((item, index) => {
                          return (
                           <>
                            {
                                index < 3 ?(
                                    <div className="col-lg-4 col-md-6" key={index}>
                                    <div
                                      className={`${"product-box" }`}
                                      id="multi_select"
                                    >
                                      {/* <Link to={`/product-detail/${item?.id}`}> */}
                                      <div
                                        className={`${ "pro-img" }`}
                                        onClick={(e) => {Navigate(`/product-detail/${item?.id}`);setIsOpenProductModal(false); window.location.reload(false);}}
                                      >
                                        <figure>
                                          <img
                                            src={
                                              item?.thumbnail
                                                ? `${ImageUrlRedux?.product_thumbnail_url}/${item?.thumbnail}`
                                                : `${no_image_product_details}`
                                            }
                                            alt=""
                                            className="img-fluid"
                                          />
                                        </figure>
                                      </div>
                                      <div className="pro-detail">
                                        <div className="name">
                                          <h4>{item?.name}</h4>
                                        </div>
                                        <div className="price">
                                          <h4>${item?.unit_price}</h4>
                                        </div>
                                      </div>
      
                                      {/* </Link> */}
                                    </div>
                                  </div>
                                ) : (null)
                            }
                           </>
                          );
                        })}
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ProductsModal;
