import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import '../../assets/style/Custom.css'
import { bird2, productdetail3 } from "../../constant";
import { useSelector } from "react-redux";


function ViewDonationModal(props) {
  const { setIsOpenViewDonationModal, isOpenViewDonationModal,selectedData } = props;
  const ImageUrlRedux = useSelector((state) => state.AuthReducer.urlData);
  const Navigate = useNavigate();
// console.log("selected",selectedData)

  return (
    <>
      <Modal
        isOpen={isOpenViewDonationModal}
        toggle={() => {
            setIsOpenViewDonationModal(false);
        }}
        className="custom-modal modal-width orderView-Modal"
      >
        {/* <FontAwesomeIcon
          icon={faTimesCircle}
          className="modal-close"
          onClick={() => setIsOpenViewDonationModal(false)}
        /> */}
        <div
        id="donationDetail"
      >
        <div 
            // className="modal-dialog modal-dialog-centered"
        >
          <div>
            <div className="modal-header">
              {/* <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button> */}
            </div>
            <div className="modal-body">
              <div className="heading">
                <h2 className="modal-mainTitle">Donation Details</h2>
              </div>
              <div className="all-orders">
                <div className="order-detail">
                  <div className="img-box">
                    {
                      selectedData?.campaign ? (
                        <figure>
                        <img src={`${ImageUrlRedux?.campaign_image_url}/${selectedData?.campaign?.image}`} alt="" className="img-fluid" />
                      </figure>
                      ) : (
                        <figure>
                        <img src={`${ImageUrlRedux?.product_thumbnail_url}/${selectedData?.product?.thumbnail}`} alt="" className="img-fluid" />
                      </figure>
                      )
                    }
                   
                  </div>
                  <div className="details">
                    <h5 className="name">{selectedData?.product?.name}</h5>
                    {
                      selectedData?.campaign ? ( <h5 className="name">{selectedData?.campaign?.title}</h5>) : (null)
                    }
                    <h5 className="price">
                      <span className="pro-name">Price: ${selectedData?.amount}</span>
                    </h5>
                    <h5 className="price">
                      <span className="order-no">Donation no: #{selectedData?.id}</span>
                    </h5>
                    <h5 className="status">
                      <span>{selectedData?.payment_status}</span>
                    </h5>
                  </div>
                </div>
              
              </div>
              <ul className="order-summary">
                <li>
                  <span>Sub Total :</span>
                  <span>$ {selectedData?.amount}</span>
                </li>
                <li>
                  <span>Total Amount :</span>
                  <span>$ {selectedData?.amount}</span>
                </li>
              </ul>
              <div className="button-group">
                <a onClick={(e)=>setIsOpenViewDonationModal(false)} className="btn">
                  Close
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      </Modal>
    </>
  );
}

export default ViewDonationModal;
