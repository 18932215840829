import React, { useEffect, useState } from "react";
import Header from "../../component/Header";
import "../../assets/style/login.css";
import { blogbefore, proafter, arrow } from "../../constant/index";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { login, WebSiteSettings } from "../../redux/actions/AuthActions";
import { GetSiteSettingsApi } from "../../network/Network";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const Token = useSelector((state) => state.AuthReducer.token);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);

  const handleRememeberMe = (event) => {
    setChecked(!checked);
  };

  useEffect(() => {
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = rememberMe ? localStorage.getItem("email") : "";
    const password = rememberMe ? localStorage.getItem("password") : "";
    setEmail(email);
    setPassword(password);
    setChecked(rememberMe);
  }, []);

  // console.log(checked)

  const SignInHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!email || !password) {
      toast.error("Please Enter All Fields");
      setLoading(false);
      return;
    }
    if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      toast.error("Invalid Email");
      setLoading(false);
      return;
    } else {
      localStorage.setItem("rememberMe", checked);
      localStorage.setItem("email", checked ? email : "");
      localStorage.setItem("password", checked ? password : "");

      let data = {
        email: email,
        password: password,
      };
      // console.log(data);
      setTimeout(async () => {
        // setLoading(false);
        let x = await dispatch(login(data, setLoading));
        // console.log("response login", x);
        if (x) {
          // console.log(x);
          window.location.href = "/profile";
          // Navigate("/profile");
        }
      }, 600);
    }
  };

    // Get Pages Data
    useEffect(() => {
      GetSiteSettingsApi()
        .then((res) => {
          // console.log(res);
          dispatch(WebSiteSettings(res?.data?.data));
        })
        .catch((err) => {
          // console.log(err);
        });
    }, []);

    useEffect(() => {
    if(Token){
      navigate('/')
    }
    }, [])
    

  return (
    <>
      <Header />
      <section className="login">
        <div className="before">
          <figure>
            <img src={blogbefore} className="img-fluid" />
          </figure>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <div className="auth-box">
                <div className="heading">
                  <h2>Sign In</h2>
                  <h4>
                    Welcome back to <span className="bld">Kigi Nursery</span>
                  </h4>
                  <p>
                    Not a Member ?<Link to="/signup">Sign Up</Link>
                  </p>
                </div>
                <form action="profile.php">
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="email"
                      className="form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label>Password</label>
                    <input
                      type="password"
                      className="form-control"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="remember-dv">
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="Remember"
                        className="form-check-input"
                        value={checked}
                        checked={checked}
                        onChange={(e) => handleRememeberMe(e)}
                      />
                      <label for="Remember">Remember Me</label>
                    </div>
                    <div className="link">
                      <Link to="/forget-password">Forgot Password ?</Link>
                    </div>
                  </div>
                  <button
                    className="btn"
                    type="submit"
                    onClick={(e) => SignInHandler(e)}
                    disabled={loading}
                  >
                    <span> {loading ? "Loading.." : " SIGN IN"}</span>{" "}
                    <img src={arrow} className="img-fluid" />
                  </button>
                </form>
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>
        <div className="after">
          <figure>
            <img src={proafter} className="img-fluid" />
          </figure>
        </div>
      </section>
    </>
  );
};

export default Login;
