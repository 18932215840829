import React, { useEffect, useState } from "react";
import Footer from "../../component/Footer";
import Header from "../../component/Header";
import "../../assets/style/blogs.css";
import { arrow, smallafter, smallbefore } from "../../constant/index";
import { Link, useNavigate } from "react-router-dom";
import { GetBlogs } from "../../network/Network";
import { useSelector } from "react-redux";
import NoRecord from "../../component/NoRecord/NoRecord";
import Skeleton from "react-loading-skeleton";

const Blog = () => {
  const navigate = useNavigate();
  const ImageUrlRedux = useSelector((state) => state.AuthReducer.urlData);
  const [blogData, setBlogData] = useState([]);
  const [spinLoad, setSpinLoad] = useState(false);

  useEffect(() => {
    setSpinLoad(true);
    GetBlogs()
      .then((res) => {
        // console.log("blogs", res);
        setBlogData(res?.data);
        setSpinLoad(false);
      })
      .catch((err) => {
        // console.log(err);
        setSpinLoad(false);
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      {/* <!-- InnerBanner Sec Start Here --> */}
      <section className="innerBanner">
        <div className="container">
          <div className="inner-box">
            <div className="title">
              <h2>Blogs</h2>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- InnerBanner Sec End Here --> */}

      {/* <!-- Blogs Sec Start Here --> */}
      <section className="blogs">
        <div className="before">
          <figure>
            <img src={smallafter} className="img-fluid" />
          </figure>
        </div>
        <div className="container">
          <div className="all-blogs">
            {spinLoad ? (
              <>
                {[0, 1, 2, 3, 4, 5]?.map((item, index) => {
                  return (
                    <div className="col-md-12 mt-3" key={index}>
                      <Skeleton count={5} />
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                {blogData?.length > 0 ? (
                  <>
                    {blogData?.map((item, index) => {
                      return (
                        <div className="row blog-box mb-4" key={index}>
                          <div className="col-lg-4">
                            <div className="img-box">
                              <figure>
                                <img
                                  src={`${ImageUrlRedux?.blog_url}/${item?.image}`}
                                  className="img-fluid"
                                />
                              </figure>
                            </div>
                          </div>
                          <div className="col-lg-8">
                            <h3 className="title">{item?.title}</h3>
                            <p>{item?.sub_content}</p>
                            {/* <p
                              dangerouslySetInnerHTML={{
                                __html: item?.content,
                              }}
                            /> */}
                            <div className="button-group">
                              <Link
                                to={`/blog-detail/${item?.id}`}
                                className="btn"
                              >
                                Read More
                                <img src={arrow} className="img-fluid" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <NoRecord />
                )}
              </>
            )}
          </div>
        </div>
        <div className="after">
          <figure>
            <img src={smallbefore} className="img-fluid" />
          </figure>
        </div>
      </section>
      {/* <!-- Blogs Sec End Here --> */}
      <Footer />
    </>
  );
};

export default Blog;
