import React, { useEffect, useState } from "react";
import Footer from "../../component/Footer";
import Header from "../../component/Header";
import "../../assets/style/shop.css";
import {
  bird1,
  bird2,
  bird3,
  bird4,
  blogbefore,
  proafter,
} from "../../constant/index";
import { Link } from "react-router-dom";
import NoRecord from "../../component/NoRecord/NoRecord";
import { GetPlantsApi } from "../../network/Network";
import Skeleton from "react-loading-skeleton";
import BirdsCard from "../../component/AllCard/BirdsCard";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";

const KigiBird = () => {
  const CategoriesReduxData = useSelector(
    (state) => state.AuthReducer.categories
  );
  const [birdsData, setBirdsData] = useState([]);
  const [spinLoad, setSpinLoad] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState();

  const handlePageClick = async (data) => {
    setCurrentPage(data?.selected + 1);
  };

  // Get ALL Birds
  useEffect(() => {
    setSpinLoad(true);
    let data = {
      name: "/kigi-birds",
    };
    GetPlantsApi(currentPage,data)
      .then((res) => {
        // console.log(res);
        setBirdsData(res?.data?.data);
        const total = res?.data?.total;
        const limit = res?.data?.per_page;
        setPageCount(Math.ceil(total / limit));
        setSpinLoad(false);
      })
      .catch((err) => {
        // console.log(err);
        setSpinLoad(false);
      });
  }, [currentPage]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <>
      <Header />
      {/* <!-- InnerBanner Sec Start Here --> */}
      <section className="innerBanner">
        <div className="container">
          <div className="inner-box">
            <div className="title">
              <h2>kigi birds</h2>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- InnerBanner Sec End Here --> */}

      {/* <!-- Shop Sec Start Here --> */}
      <section className="shop_sec kigibirds_sec">
        <div className="before">
          <figure>
            <img src={blogbefore} className="img-fluid" />
          </figure>
        </div>
        <div className="container">
        {CategoriesReduxData?.map((item, index) => {
            return (
              <>
                {item?.name == "KIGI BIRDS" ? (
                  <div className="heading" key={index}>
                  <h2>{item?.subtitle}</h2>
                 
                </div>
                ) : null}
              </>
            );
          })}

          
          <div className="row">
            {spinLoad ? (
              <>
                {[0, 1, 2, 3, 4, 5, 7, 8, 9]?.map((item, index) => {
                  return (
                    <div className="col-md-4 mt-3" key={index}>
                      <Skeleton count={5} />
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                {birdsData?.length > 0 ? (
                  <>
                    {birdsData?.map((item, index) => {
                      return (
                        <BirdsCard
                          item={item}
                          index={index}
                          colValue={"3"}
                          links="/kigibird-detail"
                        />
                      );
                    })}
                  </>
                ) : (
                  <NoRecord />
                )}
              </>
            )}
          </div>
          <div className="paginatio-count">
                <div className="pagination-container mt-5">
                <ReactPaginate
                  previousLabel="<"
                  nextLabel=">"
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-center"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
              {birdsData?.length > 0 ? (
                  <p className="total-pages">
                    {birdsData?.length} PRODUCTS 1 - {birdsData?.length}
                  </p>
                ) : null}
              </div>
        </div>
        <div className="after">
          <figure>
            <img src={proafter} className="img-fluid" />
          </figure>
        </div>
      </section>
      {/* <!-- Shop Sec End Here --> */}
      <Footer />
    </>
  );
};

export default KigiBird;
