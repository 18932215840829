import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { no_image_product_details } from '../../constant';

const BirdsCard = (props) => {
    const { index, item, colValue, links } = props;
    const ImageUrlRedux = useSelector((state) => state.AuthReducer.urlData);
    return (
        <div className={colValue == "4" ? "col-lg-4" : "col-lg-3"} key={index}>
            <div className="product-box">
                <Link to={`${links}/${item?.id}`}>
                    <div className="pro-img">
                        <figure><img src={item?.thumbnail ? `${ImageUrlRedux?.product_thumbnail_url}/${item?.thumbnail}` : no_image_product_details} alt="" className="img-fluid" /></figure>
                    </div>
                    <div className="pro-detail">
                        <div className="name">
                            <h4>{item?.name}</h4>
                        </div>
                        <div className="price">
                            <Link to="/donatenow" state={{ data: item?.id }} className="btn">Donate</Link>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default BirdsCard