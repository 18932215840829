import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useNavigate } from "react-router-dom";
import "../../assets/style/Custom.css";
import { PostAddressApi } from "../../network/Network";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

function AddressModal(props) {
  const {
    setIsOpenAddressModal,
    isOpenAddressModal,
    postAddress,
    setPostAddress,
  } = props;
  const Token = useSelector((state) => state.AuthReducer.token);
  const Navigate = useNavigate();
  const [address, setAddress] = useState("");
  const [appartment, setAppartment] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [userState, setUserState] = useState("");
  const [loading, setLoading] = useState(false);

  const AddAddressHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    if (
      !address ||
      !appartment ||
      !city ||
      !zip ||
      !phone ||
      !country ||
      !userState
    ) {
      toast.error("Please Enter All Fields");
      setLoading(false);
      return;
    }
    if (phone?.length < 10) {
      toast.error("The phone no must be at least 10 characters");
      setLoading(false);
      return;
    }
    let data = new FormData();
    data.append("address", address);
    data.append("appartment", appartment);
    data.append("city", city);
    data.append("zip", zip);
    data.append("phone", phone);
    data.append("country", country);
    data.append("state", userState);
    // console.log(data);
    PostAddressApi(data, Token)
      .then((res) => {
        // console.log("address post", res);
        toast.success(res?.data?.message);
        setPostAddress(res?.data);
        setIsOpenAddressModal(false);
        setLoading(false);
        setAddress("");
        setAppartment("");
        setPhone("");
        setCity("");
        setCountry("");
        setUserState("");
        setZip("");
      })
      .catch((err) => {
        // console.log(err);
        setLoading(false);
      });
  };

  return (
    <>
      <Modal
        isOpen={isOpenAddressModal}
        toggle={() => {
          setIsOpenAddressModal(false);
        }}
        className="custom-modal modal-width orderView-Modal"
      >
        {/* <FontAwesomeIcon
          icon={faTimesCircle}
          className="modal-close"
          onClick={() => setIsOpenAddressModal(false)}
        /> */}
        <h2 className="modal-mainTitle">Add New Address</h2>
        <div>
          <div className="custom_form">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label for="Address">Address</label>
                  <input
                    type="text"
                    id="Address"
                    className="form-control"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label for="Address">Suite, Apartment, etc</label>
                  <input
                    type="text"
                    id="Address"
                    className="form-control"
                    value={appartment}
                    onChange={(e) => setAppartment(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label for="Suite">Phone</label>
                  <input
                    type="number"
                    id="Suite"
                    className="form-control"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label for="City">City</label>
                  <input
                    type="text"
                    id="City"
                    className="form-control"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label for="Country">Country/Region</label>
                  <input
                    type="text"
                    id="Country"
                    className="form-control"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label for="State">State</label>
                  <input
                    type="text"
                    id="State"
                    className="form-control"
                    value={userState}
                    onChange={(e) => setUserState(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label for="Zip">Zip Code</label>
                  <input
                    type="number"
                    id="Zip"
                    className="form-control"
                    value={zip}
                    onChange={(e) => setZip(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="checkbox">
                  {/* <input
                              type="checkbox"
                              className="form-check-input"
                              id="check"
                            />
                            <label for="check">Save Shipping Details</label> */}
                  <button
                    className="btn Addresss-modal-btn"
                    onClick={(e) => AddAddressHandler(e)}
                    disabled={loading}
                  >
                    {loading ? "Loading..." : "Save Address"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default AddressModal;
