import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { pointer, profile } from '../../constant'
import { ramdomImage } from '../../constant/ConstantFunction'
import { logout } from '../../redux/actions/AuthActions'
import { BsFillCameraFill } from 'react-icons/bs';
import { ClearCart } from '../../redux/actions/CartActions'

const SideBar = (props) => {
    const {uploadLoading,tabs,
    fileupload,
    handleImageUpload,} = props
    let currentUrl = window.location.href.split("/");
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const userData = useSelector((state) => state.AuthReducer.users);
    const ImageUrlRedux = useSelector((state) => state.AuthReducer.urlData)

    const LogoutHandler = (e) => {
        e.preventDefault();
        setTimeout(async () => {
          let x = await dispatch(logout());
          dispatch(ClearCart())
          window.location.href = "/login";
        }, 600);
      };

  return (
    <div className="sidebar">
    <div className="user-info">
      <div className="img-box">
      <figure className='profile-image-cont'>
                {uploadLoading ? (
                  <img src={fileupload} className="img-fluid" alt="" />
                ) : (
                  <>
                  { userData?.image == null ? (
                     <img
                     src={`${ramdomImage(
                       `${userData?.f_name}{""}${userData?.l_name}`
                     )}`}
                     className="image-fluid image-width"
                     alt=""
                   />
                  ) : (
                    <img
                    src={`${
                      fileupload
                        ? fileupload
                        : `${ImageUrlRedux?.customer_image_url}/${userData?.image}`
                    }`}
                    className="image-fluid image-width"
                    alt=""
                  />
                  )}
                  </>
                  // <img
                  //   src={`${
                  //     fileupload ? fileupload : `${profile}`
                  //   }`}
                  //   className="img-fluid"
                  //   alt=""
                  // />
                )}
              </figure>
              {
                 tabs == 2 ? (
                  <div className='upload-profile'>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                    multiple={false}
                    className="profile-upload"
                  />
                  <BsFillCameraFill 
                    className='icon'
                  />
                </div>
                 ) : (null)
              }
             
        {/* <figure>
          <img src={profile} className="img-fluid" />
        </figure> */}
      </div>
      <div className="info">
        <h3>{userData?.f_name}{" "}{userData?.l_name}</h3>
        <h5>Customer</h5>
      </div>
    </div>
    <div
      className="nav flex-column nav-pills me-3"
      id="v-pills-tab"
      role="tablist"
      aria-orientation="vertical"
    >
      {/* <!-- First Tab Button --> */}
      <button
        onClick={() => navigate('/profile')}
        className={`${
					currentUrl?.[3] === "profile" ? "nav-link active" : "nav-link"
				}`}
        id="v-pills-Account-tab"
        data-bs-toggle="pill"
        data-bs-target="#v-pills-Account"
        type="button"
        role="tab"
        aria-controls="v-pills-Account"
        aria-selected="true"
      >
        My Account <img src={pointer} className="img-fluid" />
      </button>

      <button
         onClick={() => navigate('/my-address')}
         className={`${
					currentUrl?.[3] === "my-address" ? "nav-link active" : "nav-link"
				}`}
        id="v-pills-Addresses-tab"
        data-bs-toggle="pill"
        data-bs-target="#v-pills-Addresses"
        type="button"
        role="tab"
        aria-controls="v-pills-Addresses"
        aria-selected="false"
      >
        My Addresses <img src={pointer} className="img-fluid" />
      </button>

      <button
         onClick={() => navigate('/my-donation')}
         className={`${
					currentUrl?.[3] === "my-donation" ? "nav-link active" : "nav-link"
				}`}
        id="v-pills-Donations-tab"
        data-bs-toggle="pill"
        data-bs-target="#v-pills-Donations"
        type="button"
        role="tab"
        aria-controls="v-pills-Donations"
        aria-selected="false"
      >
        My Donations <img src={pointer} className="img-fluid" />
      </button>

      <button
         onClick={() => navigate('/my-orders')}
         className={`${
					currentUrl?.[3] === "my-orders" ? "nav-link active" : "nav-link"
				}`}
        id="v-pills-Orders-tab"
        data-bs-toggle="pill"
        data-bs-target="#v-pills-Orders"
        type="button"
        role="tab"
        aria-controls="v-pills-Orders"
        aria-selected="false"
      >
        My Orders <img src={pointer} className="img-fluid" />
      </button>
      {/* <!-- Fourth Tab Button --> */}

      {/* <!-- Fifth Tab Button --> */}
      <button
      onClick={() => navigate('/wishlist')}
      className={`${
        currentUrl?.[3] === "wishlist" ? "nav-link active" : "nav-link"
      }`}
        id="v-pills-Wishlist-tab"
        data-bs-toggle="pill"
        data-bs-target="#v-pills-Wishlist"
        type="button"
        role="tab"
        aria-controls="v-pills-Wishlist"
        aria-selected="false"
      >
        Wishlist <img src={pointer} className="img-fluid" />
      </button>
      {/* <!-- Fifth Tab Button --> */}

      {/* <!-- Logout Button --> */}
      <a style={{cursor: "pointer"}}>
        <button className="logout"
            onClick={(e)=>LogoutHandler(e)}
        >Logout</button>
      </a>
      {/* <!-- Logout Button --> */}
    </div>
  </div>
  )
}

export default SideBar