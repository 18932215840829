import React, { useEffect, useState } from "react";
import Footer from "../../component/Footer";
import Header from "../../component/Header";
import "../../assets/style/shop.css";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
import { Link, useNavigate } from "react-router-dom";
import { GetCampaign } from "../../network/Network";
import Skeleton from "react-loading-skeleton";
import NoRecord from "../../component/NoRecord/NoRecord";
import { useDispatch, useSelector } from "react-redux";
import { AllDonationCampaign } from "../../redux/actions/AuthActions";

const Donate = () => {
  const dispatch = useDispatch();
  const ImageUrlRedux = useSelector((state) => state.AuthReducer.urlData);
  const navigate = useNavigate();
  const [campaign, setCampaign] = useState([]);
  const [spinLoad, setSpinLoad] = useState(false);

  const HandelNavigateDetail = (e, id) => {
    e.preventDefault();
    navigate(`/campaign/${id}`);
  };

  // Get camping Data
  useEffect(() => {
    setSpinLoad(true);
    GetCampaign()
      .then((res) => {
        // console.log(res);
        setCampaign(res?.data);
        dispatch(AllDonationCampaign(res?.data));
        setSpinLoad(false);
      })
      .catch((err) => {
        // console.log(err);
        setSpinLoad(false);
      });
  }, []);

  Chart.register(ArcElement);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const donutData = {
    // labels: ["I", "II", "III", "IIII"],
    datasets: [
      {
        data: [50],
        backgroundColor: "#c23127",
        // backgroundColor: ["#c23127", "#c23127", "#eeeeee"],
        // // hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
        // borderWidth: 0,
      },
    ],
  };


  return (
    <>
      <Header />
      {/* <!-- InnerBanner Sec Start Here --> */}
      <section className="innerBanner">
        <div className="container">
          <div className="inner-box">
            <div className="title">
              <h2>Donate</h2>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- InnerBanner Sec End Here --> */}

      {/* <!-- section  donation start here--> */}
      <section>
        <section>
          <div className="container">
            <div className="row">
              {spinLoad ? (
                <>
                  {[0, 1, 2, 3, 4, 5]?.map((item, index) => {
                    return (
                      <div className="col-md-12 mt-3" key={index}>
                        <Skeleton count={5} />
                      </div>
                    );
                  })}
                </>
              ) : (
                <>
                  {campaign?.length > 0 ? (
                    <>
                      {campaign?.map((item, index) => {
                        return (
                          <div
                            className="col-md-4"
                            key={index}
                            onClick={(e) => HandelNavigateDetail(e, item?.id)}
                          >
                            <div className="dntn_prod">
                              <div>
                                <Link to={`/campaign/${item?.id}`}>
                                  <img
                                    src={`${ImageUrlRedux?.campaign_image_url}/${item?.image}`}
                                    className="img-fluid"
                                    alt=""
                                  />
                                </Link>
                              </div>
                              <div className="text-center">
                                <h4>{item?.title}</h4>
                              </div>
                              <div className="dntn_prod_desc">
                                <div className="donutcanva">
                                  {/* <!-- custom-loader Include Here --> */}
                                  <Doughnut
                                    data={{
                                      // labels: ["I", "II", "III", "IIII"],
                                      datasets: [
                                        {
                                          data: item?.donation ? item?.donation.map((items)=>{
                                            return (items?.amount)
                                          }) : null ,
                                          backgroundColor: "#c23127",
                                          // , 
                                          // [item?.donation ? item?.donation?.map((item)=>{item?.amount}).join(",") : 10],
                                          // backgroundColor: "#c23127",
                                        },
                                      ],
                                    }}
                                  />
                                  <div className="graph-container">
                                    <div
                                      className="graph-donut"
                                      data-percent="80"
                                      data-background="#c23127"
                                    >
                                      <span className="percent">
                                        {(
                                          (item?.total_donation /
                                            item?.goal_price) *
                                          100
                                        ).toFixed(2)}
                                      </span>
                                      <span className="bott_txt">Funded</span>
                                    </div>
                                  </div>
                                  {/* <!-- custom-loader Include Here --> */}
                                </div>
                                <div className="pledged_dv">
                                  <p>${item?.goal_price}</p>
                                  <span className="bott_txt">Pledged</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <NoRecord />
                  )}
                </>
              )}
            </div>
          </div>
        </section>
      </section>
      <Footer />
    </>
  );
};

export default Donate;
