import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { arrow1, btnicon, stepbefore } from '../../constant';

const WorkStep = (props) => {
    const {StepDataRedux,url} = props
    const ImageUrlRedux = useSelector((state) => state.AuthReducer.urlData);
  return (
   <>
      <section className="how_it_work">
        <div className="container">
          <div className="discover">
            <div className="heading_wrapper">
              <h2>How It Works</h2>
            </div>
            <div className="view-all">
              <Link to={url} className="lnk">
                Read More <img src={btnicon} className="img-fluid" />
              </Link>
            </div>
          </div>
          <div className="row">
            {StepDataRedux?.map((item, index) => {
              return (
                <div className="col-md-4" key={index}>
                  <div className="wor_box">
                    <div className="img_box">
                      <figure>
                        <img
                          src={`${ImageUrlRedux?.how_it_works_url}/${item?.image}`}
                          className="img-fluid"
                        />
                      </figure>
                    </div>
                    <div className="content">
                      <h4>{item?.title}</h4>
                      <h5>
                        <span>{item?.sub_title}</span>
                        <br />
                        {item?.description}{" "}
                      </h5>
                    </div>
                    {index < StepDataRedux?.length - 1 ? (
                      <div className="arrow">
                        <figure>
                          <img src={arrow1} className="img-fluid" />
                        </figure>
                      </div>
                    ) : null}
                  </div>
                </div>
              );
            })}

           
          </div>
        </div>
        <div className="after">
          <figure>
            <img src={stepbefore} className="img-fluid" />
          </figure>
        </div>
      </section>
   </>
  )
}

export default WorkStep