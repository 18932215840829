import React, { useState } from "react";
import { toast } from "react-toastify";

const GrowingZoneSide = (props) => {
  const { ImageUrlRedux, productDetails } = props;
  const [zipCode, setZipCode] = useState();
  const [growingData, setGrowingData] = useState();
  const [growLoading, setGrowLoading] = useState(false);

  const fetchZone = async (e) => {
    e.preventDefault();
    setGrowLoading(true);
    if (growingData) {
      setGrowingData("");
    }
    let response = await fetch(`https://phzmapi.org/${zipCode}.json`);
    let zoneResults = false;
    // console.log("response", response);
    if (response.status == 200) {
      zoneResults = await response.json();
      setGrowingData(zoneResults);
      // console.log("zone", zoneResults);
      setGrowLoading(false);
    } else {
      setGrowLoading(false);
      toast.error("zip code not found");
    }
    return zoneResults;
  };

  return (
    <>
      <div className="map">
        <div className="map-img">
          <figure>
            <img
              src={`${ImageUrlRedux?.growing_zones_url}/${productDetails?.growing_zone?.image}`}
              className="img-fluid"
            />
          </figure>
        </div>
        <div className="map-zones">
          <h4>Growing Zone : {productDetails?.growing_zone?.zone_name}</h4>
        </div>
      </div>

      <div className="header-box header-box__growing-zone">
        <div className="header-box__dropdown">
          <div className="site-header__box zone-box">
            <div className="zone-box__content">
              <form id="setGrowingZone">
                <input
                  className="form-control"
                  type="text"
                  id="growingZone"
                  name="nursery_growing_zip"
                  placeholder="Enter Zip Code to Find Growing Zone"
                  value={zipCode}
                  onChange={(e) => setZipCode(e.target.value)}
                />
                <button
                  type="submit"
                  id="growingZoneBtn"
                  className="btn btn--default"
                  onClick={(e) => fetchZone(e)}
                  disabled={growLoading}
                >
                  {growLoading ? "Loading..." : "Find my Growing Zone"}
                </button>
              </form>
              {growingData ? (
                <div className="mt-3">
                  <p className="zone-data">Zone : {growingData?.zone}</p>
                  <p className="zone-data">
                    Temperature range : {growingData?.temperature_range}
                  </p>
                  <p className="zone-data">
                    Lat : {growingData?.coordinates?.lat}
                  </p>
                  <p className="zone-data">
                    Lon : {growingData?.coordinates?.lon}
                  </p>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GrowingZoneSide;
