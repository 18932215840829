import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import {
  searchicon,
  loginicon,
  carticon,
  wishlisticon,
} from "../constant/index";
// import user_icon from "../assets/images/user_icon.png";
import logo from "../assets/images/logo.png";
import { GetCategories, GetSiteSettingsApi } from "../network/Network";
import { AllCategoriesData, logout, WebSiteSettings } from "../redux/actions/AuthActions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const Header = () => {
  const [activeSearch,setActiveSearch] = useState(0)
  // console.log(activeSearch)
  const dispatch = useDispatch();
  const Token = useSelector((state) => state.AuthReducer.token);
  const cartDataRedux = useSelector((state) => state.CartReducer.cartData);
  const ImageUrlRedux = useSelector((state) => state.AuthReducer.urlData);
  const SiteSettingsRedux = useSelector(
    (state) => state.AuthReducer.siteSettings
  );
  const CategoriesReduxData = useSelector(
    (state) => state.AuthReducer.categories
  );
  let currentUrl = window.location.href.split("/");
  const [mobView, setMobView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchShow, setSearchShow] = useState(false);
  const [Show, setShow] = useState(false);
  const [mainSearch, setMainSearch] = useState("");

  const NavBarHandler = () => {
    setMobView(!mobView);
  };

      // Get Pages Data
      useEffect(() => {
        GetSiteSettingsApi()
          .then((res) => {
            // console.log(res);
            dispatch(WebSiteSettings(res?.data?.data));
          })
          .catch((err) => {
            // console.log(err);
          });
      }, []);

  const LogoutHandler = (e) => {
    e.preventDefault();
    setTimeout(async () => {
      let x = await dispatch(logout());
      window.location.href = "/login";
    }, 600);
  };

  
  // Get Categories Data
  useEffect(() => {
    GetCategories()
      .then((res) => {
        // console.log(res);
        dispatch(AllCategoriesData(res?.data));
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);

  return (
    <>
      {/* <!-- Header Start Here --> */}
      <header id="headerTop">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-2 col-3">
              <div className="logo_box">
                <Link to="/">
                  <figure>
                    <img src={`${ImageUrlRedux?.company_url}/${SiteSettingsRedux?.kigi_company_web_logo}`} alt="" className="img-fluid" />
                  </figure>
                </Link>
              </div>
            </div>
            <div className="col-lg-7 col-1">
              <div className="navbar_menus">
                <ul className="menus">
                  {/* <!-- <li className="menu-items">
                            <div className="dropdown">
                                <a href="shop.php" className="btn btn-secondary dropdown-toggle">
                                    All Plants
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li><a className="dropdown-item" href="premium-product.php">OTHERS PLANTS</a></li>
                                </ul>
                            </div>
                        </li> --> */}
                  <li className="menu-items">
                    <Link to="/all-plants"
                       className={`${
                        currentUrl?.[3] === "all-plants"
                          ? "active"
                          : null
                      }`}
                    >All Plants</Link>
                  </li>
                  <li className="menu-items">
                    <Link to="/nutrients-additives"
                       className={`${
                        currentUrl?.[3] === "nutrients-additives"
                          ? "active"
                          : null
                      }`}
                    >Nutrients & Additives</Link>
                  </li>
                  <li className="menu-items">
                    <Link to="/planting-mixes"
                       className={`${
                        currentUrl?.[3] === "planting-mixes"
                          ? "active"
                          : null
                      }`}
                    >Planting Mixes</Link>
                  </li>
                  <li className="menu-items">
                    <Link to="/kigibird"
                     className={`${
                      currentUrl?.[3] === "kigibird"
                        ? "active"
                        : null
                    }`}
                    >Kigi Birds</Link>
                  </li>
      
                  <li className="menu-items">
                    <Link to="/premium-products"
                      className={`${
                        currentUrl?.[3] === "premium-products"
                          ? "active"
                          : null
                      }`}
                    >Other Products</Link>
                  </li>
                  <li className="menu-items"
                  >
                    <Link to="/donate"
                        className={`${
                          currentUrl?.[3] === "donate"
                            ? "active"
                            : null
                        }`}
                    >Donate</Link>
                  </li>
                  {/* <li className="menu-items">
                    <Link to="/contact"
                        className={`${
                          currentUrl?.[3] === "contact"
                            ? "active"
                            : null
                        }`}
                    >Contact Us</Link>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-8">
              <div className="right-side">
                <ul className="actions-link">
                  {/* <li className="search" id="searchBtn" onClick={()=>setActiveSearch(1)}>
                    <img src={searchicon} className="img-fluid" />
                  </li> */}

                  {/* <li className="auth">
                    <Link to="/login">
                      <img src={loginicon} className="img-fluid" />
                    </Link>
                  </li> */}
                  <li className="cart">
                    <Link to="/cart"
                      className={`${
                        currentUrl?.[3] === "cart"
                          ? "active"
                          : null
                      }`}
                    >
                      <img src={carticon} className="img-fluid" />
                    </Link>
                    <span className="count">{cartDataRedux?.length}</span>
                  </li>

                  <li className="wishlist">
                  {
                    Token ? (
                      <Link to="/wishlist"
                      className={`${
                        currentUrl?.[3] === "wishlist"
                          ? "active"
                          : null
                      }`}
                      >
                      <img src={wishlisticon} className="img-fluid" />
                    </Link>
                    ) : (
                      <a onClick={()=>toast.error("Please Login")}
                      className={`${
                        currentUrl?.[3] === "wishlist"
                          ? "active"
                          : null
                      }`}
                      >
                      <img src={wishlisticon} className="img-fluid" />
                    </a>
                    )
                  }
                    
                  </li>
                
                 
                  
                  {Token ? (
                       <li className="auth">
                       <Link to="/profile"
                          className={`${
                            currentUrl?.[3] === "profile"
                              ? "active"
                              : null
                          }`}
                       >
                         <img src={loginicon} className="img-fluid" />
                       </Link>
                     </li>
                  ) : (
                    <li className="login-btn">
                     <Link to="/login"  className="kigi-btn">
                     Login
                   </Link>
                   </li>
                  )}
                 
                  <li className="canvas_btn">
                    <i className="fa fa-bars" aria-hidden="true"
                      onClick={() => NavBarHandler()}
                    ></i>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <!-- Header End Here --> */}

      {/* <!-- Go To Top Button --> */}
      {/* <!-- <div className="go_to_top">
    <a href="#header-top"><i className="fa fa-angle-up" aria-hidden="true"></i></a>
</div> --> */}
      {/* <!-- Go To Top Button --> */}

      {/* <!-- Mobile Header Start Here --> */}
      <div className={`${mobView ? "mobile_header show" : "mobile_header"}`}>
        <div className="cancel"
        onClick={() => NavBarHandler()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="#000"
            className="bi bi-x"
            viewBox="0 0 16 16"
          >
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
          </svg>
        </div>
        <ul className="mobile_menus">
          <li className="menu_items">
            <Link to="/all-plants"
              className={`${
                currentUrl?.[3] === "all-plants"
                  ? "menu_links active"
                  : "menu_links"
              }`}
            >
              All Plants
            </Link>
          </li>
          <li className="menu_items">
            <Link to="/nutrients-additives" 
               className={`${
                currentUrl?.[3] === "nutrients-additives"
                  ? "menu_links active"
                  : "menu_links"
              }`}
            >
              Nutrients & Additives
            </Link>
          </li>

          <li className="menu_items">
            <Link to="/planting-mixes" 
                className={`${
                  currentUrl?.[3] === "planting-mixes"
                    ? "menu_links active"
                    : "menu_links"
                }`}
            >
              Planting Mixes
            </Link>
          </li>

          <li className="menu_items">
            <Link to="/premium-products" 
               className={`${
                currentUrl?.[3] === "premium-products"
                  ? "menu_links active"
                  : "menu_links"
              }`}
            >
              Others Plants
            </Link>
          </li>
         
          <li className="menu_items">
            <Link to="/kigibird"
               className={`${
                currentUrl?.[3] === "kigibird"
                  ? "menu_links active"
                  : "menu_links"
              }`}
            >
              Kigi Birds
            </Link>
          </li>
         
          {/* <li className="menu_items">
            <Link to="/premium-products" className="menu_links" >
              Products
            </Link>
          </li> */}
          <li className="menu_items">
            <Link  to="/donate" 
                className={`${
                  currentUrl?.[3] === "donate"
                    ? "menu_links active"
                    : "menu_links"
                }`}
            >
              Donate 
            </Link>
          </li>
          <li className="menu_items">
            <Link to="/contact"
                 className={`${
                  currentUrl?.[3] === "contact"
                    ? "menu_links active"
                    : "menu_links"
                }`}
            >
              Contact Us
            </Link>
          </li>
        </ul>
      </div>
      {/* <!-- Mobile Header End Here --> */}

      
      {/* <!-- Search Bar Sec Start Here --> */}
      <div  className={`search-bar ${activeSearch ===1?"active":""}`}>
        <div className="container">
          <form action="">
            <div className="form-group">
              <input
                type="text"
                placeholder="Search..."
                className="form-control"
              />
              <button type="button" className="search">
                <i className="fa fa-search" aria-hidden="true"></i>
              </button>
            </div>
          </form>
          <div className="cancel" onClick={()=>setActiveSearch(0)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="26"
              fill="#000"
              className="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </div>
        </div>
      </div>
      {/* <!-- Search Bar Sec End Here --> */}
    </>
  );
};

export default Header;
