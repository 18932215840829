import React, { useEffect, useState } from "react";
import "../../assets/style/profile.css";
import Footer from "../Footer";
import Header from "../Header";
import {
  proafter,
  probefore,
  productdetail3,
  bird2,
  no_image_product_details,
} from "../../constant/index";
import { Link } from "react-router-dom";
import {
  GetAllWishList,
  PostWishListDelete,
  UpdateProfile,
} from "../../network/Network";
import { EditProfileData } from "../../redux/actions/AuthActions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import SideBar from "../SideBar/SideBar";
import NoRecord from "../NoRecord/NoRecord";
import Skeleton from "react-loading-skeleton";
import swal from "sweetalert";
import ReactPaginate from "react-paginate";
import { BsFillCartPlusFill } from "react-icons/bs";
import { AddToCart } from "../../redux/actions/CartActions";

const WishList = () => {
  const dispatch = useDispatch();
  const Token = useSelector((state) => state.AuthReducer.token);
  const userData = useSelector((state) => state.AuthReducer.users);
  const ImageUrlRedux = useSelector((state) => state.AuthReducer.urlData);
  const cartDataRedux = useSelector((state) => state.CartReducer.cartData);

  const [fvtData, setFvtData] = useState([]);
  const [spinLoad, setSpinLoad] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [quantity, setQuantity] = useState(1);
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedData, setSelectedData] = useState();

  const handlePageClick = async (data) => {
    setCurrentPage(data?.selected + 1);
  };

  //Get wishlist Data
  useEffect(() => {
    setSpinLoad(true);
    GetAllWishList(currentPage, Token)
      .then((res) => {
        // console.log("wish", res);
        setFvtData(res?.data?.data);
        const total = res?.data?.total;
        const limit = res?.data?.per_page;
        setPageCount(Math.ceil(total / limit));
        setSpinLoad(false);
      })
      .catch((err) => {
        // console.log(err);
        setSpinLoad(false);
      });
  }, [currentPage]);

  const DeleteWishListHandler = (id, index) => {
    setSpinLoad(true);
    let data = {
      product_id: id,
      _method: "delete",
    };
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this product!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        PostWishListDelete(data, Token)
          .then((res) => {
            // console.log(res?.data?.data);
            setSpinLoad(false);
            setFvtData((preval) =>
              preval.filter((val) => val?.product?.id != id)
            );
          })
          .catch((err) => {
            // console.log(err);
            setSpinLoad(false);
          });
      } else {
        swal("Your product is safe!");
        setSpinLoad(false);
      }
    });
  };

  const AddToCartHandler = (item) => {
    let checkItemAlreadyExist = cartDataRedux.filter(
      (val) => val?.id === item?.id
    );
    if (checkItemAlreadyExist.length > 0) {
      swal({
        title: "Item Already Exist in Cart!!!",
      });
    } else {
      let data = {
        id: item?.id,
        price: item?.variation && item?.variation?.length > 0 ? item?.variation[0]?.price : " ",
        quantity: quantity,
        size: item?.variation && item?.variation?.length > 0 ? item?.variation[0]?.size : " " ,
        campaign_price: "",
        campaign_id:  "",
        productitem: item,
        deliveryDate: " ",
        addon_item:  "",
        // addon_id: "",
        product_variant_id: "",
        addon_variant_id : "",
        addon_variationPrice: "",
        addonQuantity: "",
      };
      dispatch(AddToCart(data));
      toast("Product successfully added");
      setSpinLoad(true);
      let dataDelete = {
        product_id: item?.id,
        _method: "delete",
      };
      PostWishListDelete(dataDelete, Token)
        .then((res) => {
          // console.log(res?.data?.data);
          setSpinLoad(false);
          setFvtData((preval) =>
            preval.filter((val) => val?.product?.id != item?.id)
          );
        })
        .catch((err) => {
          // console.log(err);
          setSpinLoad(false);
        });
    }
  };

  // const filterVariationQty = () => {
  //   let varient = selectedSize;
  //   if (JSON.parse(selectedData?.variation) && JSON.parse(selectedData?.variation).length > 0) {
  //     let newVarient = JSON.parse(selectedData?.variation).filter(
  //       (val) => val.type == varient
  //     );
  //     if (newVarient.length > 0) {
  //       return newVarient[0].qty || 1;
  //     }
  //     return 1;
  //   }
  //   return 1;
  // };

  // const VariationPrice = () => {
  //   let varient = selectedSize;
  //   console.log("vv", varient);
  //   if (JSON.parse(selectedData?.variation) && JSON.parse(selectedData?.variation).length > 0) {
  //     let data = JSON.parse(selectedData?.variation)?.filter( 
  //       (val) => val.type === varient
  //     );
  //     console.log("data0", data);
  //     if (data?.length > 0) {
  //       return data[0]?.price;
  //     }
  //     return 1;
  //   }
  //   return 1;
  // };

  return (
    <>
      <Header />
      {/* <!-- Profile Sec Start Here --> */}
      <section className="profile-sec">
        <div className="before">
          <figure>
            <img src={probefore} className="img-fluid" />
          </figure>
        </div>
        <div className="container">
          <div className="heading">
            <h2>My Profile</h2>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <SideBar />
            </div>
            <div className="col-lg-8">
              <div className="tab-content" id="v-pills-tabContent">
                {/* <!-- Fifth Tab Content --> */}
                <div
                  className={"tab-pane fade show active"}
                  id="v-pills-Wishlist"
                  role="tabpanel"
                  aria-labelledby="v-pills-Wishlist-tab"
                >
                  <div className="tab-box wishlist-box">
                    <div className="wishlist-box box">
                      <div className="heading">
                        <h3>Wishlist</h3>
                      </div>
                      {spinLoad ? (
                        <>
                          {[0, 1, 2, 3, 4, 5, 7, 8, 9]?.map((item, index) => {
                            return (
                              <div className="col-md-12 mt-3" key={index}>
                                <Skeleton count={5} />
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          {fvtData?.length > 0 ? (
                            <div className="table-resposnive">
                              <table className="table mb-0">
                                <thead>
                                  <tr>
                                    <th></th>
                                    <th>Product Name</th>
                                    {/* <th>Detail</th> */}
                                    <th>Price</th>
                                    <th>Add to Cart</th>
                                    <th>Remove</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {fvtData?.map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>
                                          <figure>
                                            <img
                                              src={item?.product?.thumbnail ? `${ImageUrlRedux?.product_thumbnail_url}/${item?.product?.thumbnail}` : no_image_product_details}
                                              className="img-fluid"
                                            />
                                          </figure>
                                        </td>
                                        <td>
                                          <h4 className="pro-name">
                                            {item?.product?.name}
                                          </h4>

                                          {/* {
                                           JSON.parse(item?.product?.variation) &&
                                             JSON.parse(item?.product?.variation)?.length > 0 ? (
                                             <select
                                             id="size"
                                             className="form-control"
                                             key={index}
                                             onChange={(e) => {
                                               setSelectedSize(e.target.value);
                                               filterVariationQty()
                                               VariationPrice();
                                               setSelectedData(item?.product)
                                             }}
          
                                           >
                                             <option>Select Size</option>
                                             { JSON.parse(item?.product?.variation)?.map(
                                               (item2, index) => {
                                                 return (
                                                   <option
                                                     value={item2?.type}
                                                     key={index}
                                                   >
                                                     {item2?.type}
                                                   </option>
                                                 );
                                               }
                                             )}
                                           </select>
                                           ) : (null)
                                         }  */}
                                       

                                        </td>
                                        {/* <td>
                                          <h4 className="variation-type">
                                            Leaf Type:{" "}
                                            {item?.product?.leaf_type}
                                          </h4>
                                        </td> */}
                                        <td>
                                          <h4 className="price">
                                            {/* {
                                              selectedSize ? (
                                                <>
                                                   {selectedSize} size: only {filterVariationQty()} {" "}{filterVariationQty() > 1 ?  "items" : "item"} left
                                                </>
                                              ) : (null)
                                            } */}
                                         
                                            {/* $ {
                                            
                                             selectedSize
                                               ? VariationPrice()
                                               : JSON.parse( item?.product?.variation) ? JSON.parse( item?.product?.variation[0])?.price : item?.product?.unit_price
                                            } */}
                                            ${item?.product?.variation && item?.product?.variation?.length > 0 ? item?.product?.variation[0]?.price : item?.product?.unit_price}{" "}
                                          </h4>
                                        </td>
                                        <td>
                                          <button className="wishlist-add-btn">
                                            <BsFillCartPlusFill
                                              className="wishlist-add-icon"
                                              onClick={(e) =>
                                                AddToCartHandler(item?.product)
                                              }
                                            />
                                          </button>
                                        </td>
                                      
                                        <td>
                                          <div
                                            className="delete"
                                            onClick={(e) =>
                                              DeleteWishListHandler(
                                                item?.product?.id,
                                                index
                                              )
                                            }
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="26"
                                              height="26"
                                              fill="#EF5D61"
                                              className="bi bi-x"
                                              viewBox="0 0 16 16"
                                            >
                                              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                            </svg>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            <NoRecord />
                          )}
                        </>
                      )}

                      <div className="paginatio-count">
                        <div className="pagination-container mt-5">
                          <ReactPaginate
                            previousLabel="<"
                            nextLabel=">"
                            breakLabel={"..."}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={handlePageClick}
                            containerClassName={
                              "pagination justify-content-center"
                            }
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                          />
                        </div>

                        {fvtData?.length > 0 ? (
                          <p className="total-pages">
                            {fvtData?.length} PRODUCTS 1 - {fvtData?.length}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- Fifth Tab Content --> */}
              </div>
            </div>
          </div>
        </div>
        <div className="after">
          <figure>
            <img src={proafter} className="img-fluid" />
          </figure>
        </div>
      </section>
      {/* <!-- Profile Sec End Here --> */}

      {/* <!-- My Order Popup --> */}
      <div
        className="modal fade"
        id="orderDetail"
        tabindex="-1"
        aria-labelledby="orderDetailLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="heading">
                <h2>Order Details</h2>
              </div>
              <div className="all-orders">
                <div className="order-detail">
                  <div className="img-box">
                    <figure>
                      <img src={productdetail3} alt="" className="img-fluid" />
                    </figure>
                  </div>
                  <div className="details">
                    <h5 className="name">Nutient</h5>
                    <h5 className="price">
                      <span className="pro-name">Price: $590.00</span>
                      <span className="order-no">order no: #1084598423154</span>
                    </h5>
                    <h5 className="status">
                      <span>
                        <label>Quantity</label>(2)
                      </span>
                      <span>Paid</span>
                    </h5>
                  </div>
                </div>
                <div className="order-detail">
                  <div className="img-box">
                    <figure>
                      <img src={productdetail3} alt="" className="img-fluid" />
                    </figure>
                  </div>
                  <div className="details">
                    <h5 className="name">Nutient</h5>
                    <h5 className="price">
                      <span className="pro-name">Price: $590.00</span>
                      <span className="order-no">order no: #1084598423154</span>
                    </h5>
                    <h5 className="status">
                      <span>
                        <label>Quantity</label>(2)
                      </span>
                      <span>Paid</span>
                    </h5>
                  </div>
                </div>
                <div className="order-detail">
                  <div className="img-box">
                    <figure>
                      <img src={productdetail3} alt="" className="img-fluid" />
                    </figure>
                  </div>
                  <div className="details">
                    <h5 className="name">Nutient</h5>
                    <h5 className="price">
                      <span className="pro-name">Price: $590.00</span>
                      <span className="order-no">order no: #1084598423154</span>
                    </h5>
                    <h5 className="status">
                      <span>
                        <label>Quantity</label>(2)
                      </span>
                      <span>Paid</span>
                    </h5>
                  </div>
                </div>
                <div className="order-detail">
                  <div className="img-box">
                    <figure>
                      <img src={productdetail3} alt="" className="img-fluid" />
                    </figure>
                  </div>
                  <div className="details">
                    <h5 className="name">Nutient</h5>
                    <h5 className="price">
                      <span className="pro-name">Price: $590.00</span>
                      <span className="order-no">order no: #1084598423154</span>
                    </h5>
                    <h5 className="status">
                      <span>
                        <label>Quantity</label>(2)
                      </span>
                      <span>Paid</span>
                    </h5>
                  </div>
                </div>
              </div>
              <ul className="order-summary">
                <li>
                  <span>Sub Total :</span>
                  <span>$ 147.00</span>
                </li>
                <li>
                  <span>Disscount Code Applied :</span>
                  <span>-$ 14.00</span>
                </li>
                <li>
                  <span>Shipping Cost :</span>
                  <span>$ 69.00</span>
                </li>
                <li>
                  <span>Total Amount :</span>
                  <span>$ 150.00</span>
                </li>
              </ul>
              <div className="button-group">
                <Link to="/shop" className="btn">
                  Continue Shopping
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- My Order Popup --> */}

      {/* <!-- Donation Detail Popup --> */}
      <div
        className="modal fade"
        id="donationDetail"
        tabindex="-1"
        aria-labelledby="donationDetailLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="heading">
                <h2>Donation Details</h2>
              </div>
              <div className="all-orders">
                <div className="order-detail">
                  <div className="img-box">
                    <figure>
                      <img src={bird2} alt="" className="img-fluid" />
                    </figure>
                  </div>
                  <div className="details">
                    <h5 className="name">Kigi bird</h5>
                    <h5 className="price">
                      <span className="pro-name">Price: $590.00</span>
                      <span className="order-no">order no: #1084598423154</span>
                    </h5>
                    <h5 className="status">
                      <span>Paid</span>
                    </h5>
                  </div>
                </div>
                <div className="order-detail">
                  <div className="img-box">
                    <figure>
                      <img src={bird2} alt="" className="img-fluid" />
                    </figure>
                  </div>
                  <div className="details">
                    <h5 className="name">Kigi bird</h5>
                    <h5 className="price">
                      <span className="pro-name">Price: $590.00</span>
                      <span className="order-no">order no: #1084598423154</span>
                    </h5>
                    <h5 className="status">
                      <span>Paid</span>
                    </h5>
                  </div>
                </div>
                <div className="order-detail">
                  <div className="img-box">
                    <figure>
                      <img src={bird2} alt="" className="img-fluid" />
                    </figure>
                  </div>
                  <div className="details">
                    <h5 className="name">Kigi bird</h5>
                    <h5 className="price">
                      <span className="pro-name">Price: $590.00</span>
                      <span className="order-no">order no: #1084598423154</span>
                    </h5>
                    <h5 className="status">
                      <span>Paid</span>
                    </h5>
                  </div>
                </div>
                <div className="order-detail">
                  <div className="img-box">
                    <figure>
                      <img src={bird2} alt="" className="img-fluid" />
                    </figure>
                  </div>
                  <div className="details">
                    <h5 className="name">Kigi bird</h5>
                    <h5 className="price">
                      <span className="pro-name">Price: $590.00</span>
                      <span className="order-no">order no: #1084598423154</span>
                    </h5>
                    <h5 className="status">
                      <span>Paid</span>
                    </h5>
                  </div>
                </div>
              </div>
              <ul className="order-summary">
                <li>
                  <span>Sub Total :</span>
                  <span>$ 147.00</span>
                </li>
                <li>
                  <span>Total Amount :</span>
                  <span>$ 150.00</span>
                </li>
              </ul>
              <div className="button-group">
                <a href="kigi-birds.php" className="btn">
                  Continue
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Donation Detail Popup --> */}
      <Footer />
    </>
  );
};

export default WishList;
