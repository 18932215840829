import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";

const HomeBlogCards = (props) => {
  const { item, index, ImageUrlRedux } = props;
  return (
    <>
      <div className="blog_box" key={index}>
        <div className="blog-img">
          <figure>
            <img
              src={`${ImageUrlRedux?.blog_url}/${item?.image}`}
              className="img-fluid"
            />
          </figure>
        </div>
        <div className="blog-content">
          <h4 className="date">
            {moment(item?.created_at).format("MMMM Do YYYY, h:mm a")}
          </h4>
          <h3 className="name">{item?.title}</h3>
          <p>{item?.sub_content}</p>
          {/* <p
                              dangerouslySetInnerHTML={{
                                __html: item?.content,
                              }}
                            /> */}
          <div className="button-group">
            <Link to={`/blog-detail/${item?.id}`} className="lnk">
              View More
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeBlogCards;
