import React, { useEffect, useState } from "react";
import Footer from "../../component/Footer";
import Header from "../../component/Header";
import "../../assets/style/about.css";
import { smallafter, smallbefore } from "../../constant/index";
import { useSelector } from "react-redux";
import { GetSiteSettingsApi } from "../../network/Network";
import Skeleton from "react-loading-skeleton";
import WorkStep from "../../component/Work/WorkStep";

const About = () => {
  const ImageUrlRedux = useSelector((state) => state.AuthReducer.urlData);
  const StepDataRedux = useSelector((state) => state.AuthReducer.stepData);

  const [aboutData, setAboutData] = useState(null);
  // Get Pages Data
  useEffect(() => {
    let type = "about-us";
    GetSiteSettingsApi(type)
      .then((res) => {
        // console.log("about", res);
        setAboutData(res?.data?.data);
        // dispatch(WebSiteSettings(res?.data?.response?.data));
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (aboutData == null) {
    return (
      <div className="container">
        <div className="row loader-container">
          {[0, 1, 2, 3, 4, 5, 7, 8, 9]?.map((item, index) => {
            return (
              <div className="col-md-12 mt-3" key={index}>
                <Skeleton count={5} />
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <>
      <Header />
      {/* <!-- InnerBanner Sec Start Here --> */}
      <section className="innerBanner">
        <div className="container">
          <div className="inner-box">
            <div className="title">
              <h2>About</h2>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- InnerBanner Sec End Here --> */}

      {/* <!-- About Sec Start Here --> */}
      <section className="about_sec">
        <div className="before">
          <figure>
            <img src={smallafter} className="img-fluid" />
          </figure>
        </div>
        <div className="container">
          <div className="row align-items-center">
            {aboutData[0] ? (
              <>
                <div className="col-lg-6">
                  <div className="content-wrapper">
                    <h2>{aboutData[0]?.title}</h2>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: aboutData[0]?.description,
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="img_box about-image">
                    <figure>
                      <img
                        src={`${ImageUrlRedux?.kigi_about_url}/${aboutData[0]?.image}`}
                        className="img-fluid"
                      />
                    </figure>
                  </div>
                </div>
              </>
            ) : null}
          </div>
          <div className="row align-items-center scnd_row">
            {aboutData[1] ? (
              <>
                <div className="col-lg-6">
                  <div className="img_box about-image">
                    <figure>
                      <img
                        src={`${ImageUrlRedux?.kigi_about_url}/${aboutData[1]?.image}`}
                        className="img-fluid"
                      />
                    </figure>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="content-wrapper">
                    <h2>{aboutData[1]?.title}</h2>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: aboutData[1]?.description,
                      }}
                    />
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
        <div className="after">
          <figure>
            <img src={smallbefore} className="img-fluid" />
          </figure>
        </div>
      </section>
      {/* <!-- About Sec End Here --> */}

      {/* <!-- How It Work Sec Start Here --> */}
      <WorkStep 
        StepDataRedux={StepDataRedux} 
        url={"/blogs"}
      />
      {/* <!-- How It Work Sec End Here --> */}
      <Footer />
    </>
  );
};

export default About;
