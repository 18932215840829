import React, { useEffect } from "react";
import Footer from "../../component/Footer";
import Header from "../../component/Header";
import "../../assets/style/video.css";
import { banner_video, poster1, poster2, playbtn } from "../../constant/index";
import { GetVideosApi } from "../../network/Network";
import { useState } from "react";
import ReactPlayer from "react-player/lazy";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";

const Video = () => {
  const ImageUrlRedux = useSelector((state) => state.AuthReducer.urlData);
  const [videoData, setVideoData] = useState([]);
  const [spinLoad, setSpinLoad] = useState(false);

  useEffect(() => {
    setSpinLoad(true);
    GetVideosApi()
      .then((res) => {
        // console.log("video", res);
        setVideoData(res?.data);
        setSpinLoad(false);
      })
      .catch((err) => {
        // console.log(err);
        setSpinLoad(false);
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      {/* <!-- InnerBanner Sec Start Here --> */}
      <section className="innerBanner">
        <div className="container">
          <div className="inner-box">
            <div className="title">
              <h2>Videos</h2>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- InnerBanner Sec End Here --> */}

      {/* <!-- Videos Sec Start Here --> */}
      <section className="videos_sec">
        <div className="container">
          <div className="all-videos">
            {spinLoad ? (
              <div className="row">
                {[0, 1, 2, 3, 4, 5, 7, 8, 9]?.map((item, index) => {
                  return (
                    <div className="col-md-12 mt-3" key={index}>
                      <Skeleton count={5} />
                    </div>
                  );
                })}
              </div>
            ) : (
              <>
                {videoData?.map((item, index) => {
                  return (
                    <div className="video-wrapper" key={index}>
                      <div className="video-container">
                        <ReactPlayer
                          light={`${ImageUrlRedux?.gallery_url}/${item?.thumbnail}`}
                          url={item?.full_media_url}
                          className="react-player"
                          width="100%"
                          height="500px"
                          overflow="hidden"
                          backgroundSize="cover"
                          controls
                        />
                       
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </section>
      {/* <!-- Videos Sec End Here --> */}
      <Footer />
    </>
  );
};

export default Video;
