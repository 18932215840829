import React, { useEffect, useState } from "react";
import Footer from "../../component/Footer";
import Header from "../../component/Header";
import "../../assets/style/gallery.css";
import { GetGalleryImage } from "../../network/Network";
import Skeleton from "react-loading-skeleton";

const Gallery = () => {
  const [galleryData, setGalleryData] = useState([])
  const [spinLoad, setSpinLoad] = useState(false)

    useEffect(() => {
      setSpinLoad(true)
      GetGalleryImage()
        .then((res) => {
          // console.log(res);
          setGalleryData(res?.data)
          setSpinLoad(false)
        })
        .catch((err) => {
          // console.log(err);
          setSpinLoad(false)
        });
    }, []);

  
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
  return (
    <>
    <Header/>
      {/* <!-- InnerBanner Sec Start Here --> */}
      <section className="innerBanner">
        <div className="container">
          <div className="inner-box">
            <div className="title">
              <h2>Image Gallery</h2>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- InnerBanner Sec End Here --> */}

      {/* <!-- Gallery Sec Start Hwere --> */}
      <section className="gallery_sec">
        <div className="container">
          <div className="row">
            {
              spinLoad ? (
                <>
                    {[0, 1, 2, 3, 4, 5, 7, 8, 9]?.map((item, index) => {
                      return (
                        <div className="col-md-6 mt-3" key={index}>
                          <Skeleton count={5} />
                        </div>
                      );
                    })}
                  </>
              ) : (
                <>
                <div className="col-lg-7">
                {
                  galleryData[0] ? (
                    <div className="geallery-img w-60 mb-3">
                    <figure>
                      <img src={galleryData[0]?.full_media_url} className="img-fluid" />
                    </figure>
                  </div>
                  ) : (null)
                }
               
                <div className="row">
                  <div className="col-lg-6">
                  {
                  galleryData[1] ? (
                    <div className="geallery-img w-40 mb-768">
                    <figure>
                      <img src={galleryData[1]?.full_media_url} className="img-fluid" />
                    </figure>
                  </div>
                  ) : (null)
                }
              
                  </div>
                  <div className="col-lg-6">
                  {
                  galleryData[2] ? (
                    <div className="geallery-img w-40  mb-768">
                    <figure>
                      <img src={galleryData[2]?.full_media_url} className="img-fluid" />
                    </figure>
                  </div>
                  ) : (null)
                }
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
              {
                  galleryData[3] ? (
                    <div className="geallery-img w-40 mb-3  mb-768">
                    <figure>
                      <img src={galleryData[3]?.full_media_url} className="img-fluid" />
                    </figure>
                  </div>
                  ) : (null)
                }
                 {
                  galleryData[4] ? (
                    <div className="geallery-img w-40 mb-3  mb-768">
                    <figure>
                      <img src={galleryData[4]?.full_media_url} className="img-fluid" />
                    </figure>
                  </div>
                  ) : (null)
                }
              </div>
              {
                galleryData?.map((item,index)=>{
                  return(
                   <>
                    {index > 4 ? (
                       <div className="col-lg-6" key={index}>
                       <div className="geallery-img mt-3 w-30  mb-768">
                         <figure>
                           <img src={item?.full_media_url} className="img-fluid" />
                         </figure>
                       </div>
                     </div>
                    ) : (null)}
                   </>
                  )
                })
              }
                </>
              )
            }
           
           
          </div>
        </div>
      </section>
      {/* <!-- Gallery Sec End Hwere --> */}
      <Footer/>
    </>
  );
};

export default Gallery;
