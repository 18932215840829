import React, { useEffect, useState } from "react";
import Footer from "../../component/Footer";
import Header from "../../component/Header";
import "../../assets/style/productdetail.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { blogbefore, cart1, heart1, no_image_product_details, proafter } from "../../constant/index";
import { useDispatch, useSelector } from "react-redux";
import {
  GetProductDetailsApi,
  GetProductRatingApi,
  PostAddWishList,
  PostProductReviewApi,
} from "../../network/Network";
import { AddToCart } from "../../redux/actions/CartActions";
import { toast } from "react-toastify";
import ReviewBox from "../../component/Review/ReviewBox";
import AllReviews from "../../component/Review/AllReviews";
import Skeleton from "react-loading-skeleton";
import swal from "sweetalert";

const NutrientProductDetail = () => {
  const dispatch = useDispatch();
  const ImageUrlRedux = useSelector((state) => state.AuthReducer.urlData);
  const cartDataRedux = useSelector((state) => state.CartReducer.cartData);
  const Token = useSelector((state) => state.AuthReducer.token);
  const CampaignReduxData = useSelector(
    (state) => state.AuthReducer.donationCampaign
  );
  let currentUrl = window.location.href.split("/");
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const [productDetails, setProductDetails] = useState(null);
  const [spinLoad, setSpinLoad] = useState(false);
  const [wishLoading, setWishLoading] = useState(false);

  const location = useLocation();
  // console.log(location?.state?.data);
  const ParamData = location?.state?.data;
  // console.log(ParamData);

  const [active, setActive] = useState(false);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [reviewLoading, setReviewLoading] = useState(false);
  const [reviewSpin, setReviewSpin] = useState(false);
  const [reviewData, setReviewData] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [submitReview, setSubmitReview] = useState(false);
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedSize_Id, setSelectedSize_Id] = useState("");
  const [selectCampPrice, setSelectCampPrice] = useState("");
  const [selectCamp_id, setSelectCamp_id] = useState("");
  const [amount, setAmount] = useState("");

  const handleRating = (rate) => {
    // console.log(rate);
    setRating(rate);
  };

  const SelectPacks = (e, item) => {
    if (amount == item?.price) {
      setSelectedSize("")
      setAmount("");
      setQuantity(1);
    } else {
      setAmount(e.target.value);
      setQuantity(1);
      setSelectedSize(item?.size)
      setSelectedSize_Id(item?.id)
    }
  };

  useEffect(() => {
    setSpinLoad(true);
    GetProductDetailsApi(currentUrl[4])
      .then((res) => {
        // console.log("details", res);
        setProductDetails(res?.data);
        setSpinLoad(false);
      })
      .catch((err) => {
        // console.log(err);
        setSpinLoad(false);
      });
  }, [currentUrl[4]]);

  const AddWishListHandler = (e) => {
    e.preventDefault();
    setWishLoading(true);
    let data = {
      product_id: currentUrl[4],
    };
    // console.log(data);
    PostAddWishList(data, Token)
      .then((res) => {
        setWishLoading(false);
        // console.log(res);
        setActive(!active);
        toast.success("Product Add to your Wishlist");
      })
      .catch((err) => {
        setWishLoading(false);
        // console.log(err?.response);
        toast.error(err?.response?.data?.message);
      });
  };

  const AddToCartHandler = (item) => {
    if (currentUrl[3] === "premium-product") {
      if (!selectedSize) {
        toast.error("Please Select Size");
        return;
      }
      if (productDetails?.variation) {
        let newVarient = productDetails?.variation.filter(
          (val) => val.size == selectedSize
        );
        // console.log(newVarient, "test");
        if (newVarient[0]?.qty > 0) {
          let checkItemAlreadyExist = cartDataRedux.filter(
            (val) => val?.id === item?.id
          );
          if (checkItemAlreadyExist.length > 0) {
            swal({
              title: "Item Already Exist in Cart!!!",
            });
          } else {
            let variant_Id = selectedSize ? productDetails?.variation.filter((val)=> val?.size == selectedSize) : ""
            // setSelectedSize_Id(variant_Id?.id)
            let data = {
              id: item?.id,
              price: selectedSize ? VariationPrice() : productDetails?.unit_price,
              quantity: quantity,
              type:
                currentUrl[3] === "premium-product"
                  ? "single"
                  : amount
                    ? "bulk"
                    : "",
              size: selectedSize,
              campaign_price: selectCampPrice,
              campaign_id: selectCamp_id,
              productitem: item,
              deliveryDate: " ",
              addon_item: [],
              product_variant_id: variant_Id ? variant_Id[0]?.id : " ",

              // product_variant_id: productSizeId[0]?.id,
            };
            dispatch(AddToCart(data));
            toast("Product successfully added");
          }
        } else {
          toast("Selected Variation is out of stock");
          return;
        }
      }
    } else {
      if (!amount) {
        toast.error("Please Select your Pack");
        return;
      }
      if (productDetails?.variation) {
        let newVarient = productDetails?.variation.filter(
          (val) => val.price == amount
        );
        // console.log(newVarient, "test");
        if (newVarient[0]?.qty > 0) {
          let checkItemAlreadyExist = cartDataRedux.filter(
            (val) => val?.id === item?.id
          );
          if (checkItemAlreadyExist.length > 0) {
            swal({
              title: "Item Already Exist in Cart!!!",
            });
          } else {
            let data = {
              id: item?.id,
              price: amount ? amount : item?.unit_price,
              quantity: quantity,
              type:
                currentUrl[3] === "premium-product"
                  ? "single"
                  : amount
                    ? "bulk"
                    : "",
              size: selectedSize ? selectedSize : quantity,
              campaign_price: selectCampPrice,
              campaign_id: selectCamp_id,
              productitem: item,
              deliveryDate: " ",

              addon_item: [],
              product_variant_id: selectedSize_Id,

             
            };
            dispatch(AddToCart(data));
            toast("Product successfully added");
          }
        } else {
          toast("Selected Variation is out of stock");
          return;
        }
      }
    }
  };

  const SubmitRating = (e) => {
    e.preventDefault();
    setReviewLoading(true);
    if (!rating || !comment) {
      toast.error("Please Enter All Fields");
      setReviewLoading(false);
      return;
    }
    let data = {
      product_id: productDetails?.id,
      comment: comment,
      rating: rating,
    };
    PostProductReviewApi(data, Token)
      .then((res) => {
        setReviewLoading(false);
        // console.log("post review", res);
        // dispatch(ProductAllReviews(res?.data?.data));
        toast.success("Successfully send");
        setSubmitReview(!submitReview);
        setRating("");
        setComment("");
      })
      .catch((err) => {
        setReviewLoading(false);
        // console.log(err?.response?.data?.message);
        setSubmitReview(!submitReview);
        if (err?.response?.data?.message === "Unauthenticated.") {
          toast.error("Please Login");
        }
        setRating("");
        setComment("");
      });
  };

  useEffect(() => {
    setReviewSpin(true);
    GetProductRatingApi(currentUrl[4])
      .then((res) => {
        // console.log("reviews", res);
        setReviewData(res?.data?.data);
        setReviewSpin(false);
      })
      .catch((err) => {
        // console.log(err);
        setReviewSpin(false);
      });
  }, [submitReview]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const Increment = (id) => {
    setQuantity(quantity + 1);
  };
  const Decrement = (id) => {
    setQuantity(quantity - 1);
  };

  const filterVariationQty = () => {
    // console.log("selectedSize", selectedSize)
    let varient = selectedSize;
    if (productDetails?.variation) {
      let newVarient = productDetails?.variation.filter(
        (val) => val?.size == varient
      );
      // console.log(newVarient);
      if (newVarient.length > 0) {
        return newVarient[0].qty || 1;
      }
      return 1;
    }
    return 1;
  };

  const VariationPrice = () => {
    let varient = selectedSize;
    // console.log("vv", varient)
    if (productDetails?.variation) {
      let data = productDetails?.variation?.filter((val) => val?.size === varient)
      // console.log("data0", data)
      if (data?.length > 0) {
        return data[0]?.price;
      }
      return 1;
    }
    return 1;
  }


  if (productDetails == null) {
    return (
      <div className="container">
        <div className="row loader-container">
          {[0, 1, 2, 3, 4, 5, 7, 8, 9]?.map((item, index) => {
            return (
              <div className="col-md-12 mt-3" key={index}>
                <Skeleton count={5} />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
  return (
    <>
      <Header />
      <section className="innerBanner">
        <div className="container">
          <div className="inner-box">
            <div className="title">
              <h2> {productDetails?.name}</h2>
            </div>
          </div>
        </div>
      </section>


      {/* <!-- Product Detail Sec Start Here --> */}
      <section className="product-detail">
        <div className="before">
          <figure>
            <img src={blogbefore} className="img-fluid" />
          </figure>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="product-images">
                {
                  productDetails?.images[0]
                    ?
                    <>
                      <div className="single-image">

                        <Slider asNavFor={nav2} ref={(slider1) => setNav1(slider1)}>
                          {productDetails?.images?.map((item, index) => {
                            return (
                              <figure key={index}>
                                <img
                                  src={`${ImageUrlRedux?.product_image_url}/${item}`}
                                  className="img-fluid"
                                />
                              </figure>
                            );
                          })}
                        </Slider>
                      </div>
                      <div className="slider-images">
                        <Slider
                          asNavFor={nav1}
                          ref={(slider2) => setNav2(slider2)}
                          slidesToShow={
                            productDetails?.images?.length > 0 ||
                              productDetails?.images?.length < 3
                              ? productDetails?.images?.length
                              : 3
                          }
                          swipeToSlide={true}
                          focusOnSelect={true}
                        >
                          {productDetails?.images?.map((item, index) => {
                            return (
                              <figure key={index}>
                                <img
                                  src={`${ImageUrlRedux?.product_image_url}/${item}`}
                                  className="img-fluid"
                                />
                              </figure>
                            );
                          })}
                        </Slider>
                      </div>
                    </>
                    :
                    <img className="no_image_avatar_product_details" src={`${productDetails?.thumbnail ? `${ImageUrlRedux?.product_thumbnail_url}/${productDetails?.thumbnail}` : no_image_product_details}`} />
                }

              </div>
              <div className="reviews_sec">
                <div className="heading">
                  <h3>Reviews</h3>
                </div>
                <div className="reviewDv">
                  <AllReviews reviewData={reviewData} />
                </div>
              </div>

              <ReviewBox
                handleRating={handleRating}
                rating={rating}
                SubmitRating={SubmitRating}
                comment={comment}
                setComment={setComment}
                reviewLoading={reviewLoading}
              />
            </div>
            <div className="col-lg-6">
              <div className="details">
                <div className="header">
                  <div className="heading">
                    <h4>{productDetails?.category?.name}</h4>
                    <h3> {productDetails?.name}</h3>
                  </div>
                  <div className="price-tag">
                    <h5>
                      Price :{" "}
                      <span className="value">
                        ${currentUrl[3] === "premium-product" && selectedSize ? VariationPrice() : amount ? amount : productDetails?.variation[0]?.price}
                      </span>
                    </h5>
                    {productDetails?.current_stock > 0 ? null : (
                      <p>Out of Stock</p>
                    )}
                    {/* <!-- <h5>Bulk(10x) : <span className="value">$150.50</span></h5> --> */}
                  </div>

                </div>
                <div className="description">
                  <h4>Description</h4>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: productDetails?.details,
                    }}
                  />
                </div>

                {currentUrl[3] === "premium-product" ? (
                  <>
                    <div className="variations">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label for="size">Select Size: </label>
                         
                                        <select
                                          id="size"
                                          className="form-control"
                                          onChange={(e) => {
                                            setSelectedSize(e.target.value);
                                            filterVariationQty(); setQuantity(1);
                                            VariationPrice();
                                          }}

                                        >
                                          <option selected disabled>Select Size</option>
                                          {productDetails?.variation?.map(
                                            (item, index) => {
                                              return (
                                                <option
                                                  value={item?.size}
                                                  key={index}
                                                >
                                                  {item?.size}
                                                </option>
                                              );
                                            }
                                          )}
                                        </select>
                                    
                          </div>
                          {
                            selectedSize ? (
                              <p> {selectedSize} size: only {filterVariationQty()}{" "}{filterVariationQty() > 1 ? "items" : "item"} left</p>
                            ) : (
                              null
                            )
                          }

                        </div>
                      </div>
                    </div>

                    <div className="variations">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label for="Quantity">Select Quantity </label>
                            <div className="quantity">
                              <button
                                className="minus"
                                onClick={() =>
                                  Decrement(productDetails?.productitem?.id)
                                }
                                disabled={quantity <= 1}
                              >
                                -
                              </button>
                              <span> {quantity}</span>
                              <button
                                className="plus"
                                onClick={() =>
                                  Increment(productDetails?.productitem?.id)
                                }
                                disabled={filterVariationQty() == quantity}
                              // disabled={
                              //   filterVariationQty(
                              //     item?.type,
                              //     item?.productitem?.variation
                              //   ) == item?.quantity
                              // }
                              >
                                +
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="variations mt-4">
                      <div className="form-group">
                        <label for="SelectAdds">Choose Your Pack : </label>
                        <div className="input-group">
                          {productDetails?.variation?.map((item, index) => {
                            {
                              // console.log(amount, item?.price);
                            }
                            return (
                              <label
                                className={`${amount == item?.price
                                  ? "add_container-Selected add_container"
                                  : "add_container"
                                  }`}
                                key={index}
                              >
                                <input
                                  type="radio"
                                  hidden
                                  name={`${item?.size}${item?.id}`}
                                  value={item?.price}
                                  checked={amount == item?.price ? true : false}
                                  onClick={(e) => SelectPacks(e, item)}
                                />
                                <span className="checkmark">
                                  <h5 className="count"> {item?.size?.replace(/["_"]/g, " ")}</h5>
                                  <h5 className="price">${item?.price}</h5>
                                </span>
                              </label>
                            );
                          })}
                        </div>
                      </div>
                      {quantity > 0 && amount ? (
                        <p>
                          Selected pack : only {filterVariationQty()} {" "} {filterVariationQty() > 1 ? "packs" : "pack"} left
                        </p>
                      ) : null}
                    </div>
                  </>
                )}

                <div className="donation">
                  <div className="title">
                    <h4>Donation</h4>
                    <a href="/donate" target="_blank">
                      Learn More
                    </a>
                  </div>
                  <div className="input-group">
                    <div className="form-group br-right">
                      <label for="Amount">Amount: </label>
                      <select
                        className="form-control"
                        id="Amount"
                        onChange={(e) => setSelectCampPrice(e.target.value)}
                      >
                        <option value={""} selected disabled>Amount</option>
                        <option value={"5"}>$5</option>
                        <option value={"12"}>$12</option>
                        <option value={"20"}>$20</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label for="Campaign">Campaign : </label>
                      <select
                        id="size"
                        className="form-control"
                        onChange={(e) => setSelectCamp_id(e.target.value)}
                      >
                        <option selected disabled>Select Campaign</option>
                        {CampaignReduxData?.map((item, index) => {
                          return (
                            <option value={item?.id} key={index}>
                              {item?.title}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                {productDetails?.current_stock > 0 ? (
                  <div className="button-group">
                    <button
                      className="btn mr-2"
                      onClick={(e) => AddToCartHandler(productDetails)}
                    >
                      Add to cart <img src={cart1} className="img-fluid" />
                    </button>

                    <button
                      className="btn"
                      onClick={(e) => AddWishListHandler(e)}
                      disabled={wishLoading}
                    >
                      {wishLoading ? "Loading.." : "Add To Wishlist"}{" "}
                      <img src={heart1} className="img-fluid" />
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="after">
          <figure>
            <img src={proafter} className="img-fluid" />
          </figure>
        </div>
      </section>
      {/* <!-- Product Detail Sec End Here --> */}
      <Footer />
    </>
  );
};

export default NutrientProductDetail;
