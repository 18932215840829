import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { arrow } from "../../constant";
// import "../../assets/css/Stripform.css";
// import { toast } from "react-toastify";
const stripePromise = loadStripe(
  "pk_test_51L7zHyFqIiZq0dEpc0PLbRjukbs86xsyHAIfdWQ5tFgpbN24TXDj0wuX5pEK7U53wrqotJoxmwtBfXvyGlHSEpyR00niD3uArJ"
);
//

const CheckoutForm = (props) => {
  const { styling, handleClickOpen, paynow, PaymentHandler, loading } = props;
  //   const { StepTwoContinue, handleNext, setCardToken, cardToken } = props;
  const [formData, setFormData] = useState({});
  const [payProcessing, setPayProcessing] = useState(false);
  //   const [error, setError] = useState(false);
  const [done, setDone] = useState(false);

  const stripe = useStripe();
  const elements = useElements();
  const [paybutton, setPayButton] = useState(true);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (elements == null) {
      return;
    }
    const cardElement = elements.getElement(CardElement);
    const payload = await stripe.createToken(cardElement);
    // setCardToken(payload?.token?.id);
    // console.log("check", payload);
    if (payload?.token?.id) {
      PaymentHandler(e, payload?.token?.id);
      return;
    } else {
      //   toast.error("Something Went Wrong");
      return;
    }
    setPayProcessing(true);
    setDone(true);
    setPayProcessing(false);
  };

  const stylestrip = {
    marginTop: "30px",
  };

  return (
    <>
      <form style={stylestrip} onSubmit={handleSubmit}>
        <CardElement
          onChange={(e) => {
            if (e.complete) {
              setPayButton(false);
            } else {
              setPayButton(true);
            }
          }}
        />
        <br />

        <ul className="list-inline">
          <li>
            <button
              type="submit"
              style={{
                ...styling,
                // background: "#286152",
                color: "#fff",
              }}
              className="stripePay-Btn f-14 f-pop-med btn fullbtn hoverbtn"
              disabled={!stripe || !elements || paybutton || loading}
              onClick={(e) => handleSubmit(e)}
            >
              {loading ? "Loading..." : paynow}
              <img src={arrow} className="image-fluid pl-2" />
            </button>
          </li>
        </ul>
      </form>
    </>
  );
};

const StripeForm = (props) => {
  const { styling, paynow, PaymentHandler, loading } = props;
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm
        styling={styling}
        paynow={paynow}
        className="strip_data"
        PaymentHandler={PaymentHandler}
        loading={loading}
      />
    </Elements>
  );
};

export default StripeForm;
