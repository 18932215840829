import React, { useEffect, useState } from "react";
import Footer from "../../component/Footer";
import Header from "../../component/Header";
import "../../assets/style/shop.css";
import { smallafter, smallbefore } from "../../constant/index";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import NoRecord from "../../component/NoRecord/NoRecord";
import { GetAllFilterApi, GetPlantsApi } from "../../network/Network";
import AllPlantCard from "../../component/AllCard/AllPlantCard";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";

const Shop = () => {
  const CategoriesReduxData = useSelector(
    (state) => state.AuthReducer.categories
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [GrowthShow, setGrowthShow] = useState(false);

  const handlePageClick = async (data) => {
    setCurrentPage(data?.selected + 1);
  };

  const [plantsData, setPlantsData] = useState([]);
  const [spinLoad, setSpinLoad] = useState(false);
  const [filtersData, setFiltersData] = useState([]);

  const [selectedSpecies, setSelectedSpecies] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedLeafType, setSelectedLeafType] = useState("");
  const [selectedGrowthRate, setSelectedGrowthRate] = useState("");
  const [selectedHardinessZone, setSelectedHardinessZone] = useState("");
  const [startPrice, setStartPrice] = useState("");
  const [endPrice, setEndPrice] = useState("");
  const [search, setSearch] = useState("");

  const ApplyFilters = (e = null, type) => {
    if (e) {
      e.preventDefault();
    }
    setSpinLoad(true);
    let data;
    if (type == "reset") {
      data = {
        name: "/all-plants",
        color: " ",
        species: " ",
        leaf_type: " ",
        growth_rate: " ",
        hardiness_zone: " ",
        start_price: " ",
        end_price: " ",
        search: " ",
      };
      window.location.reload(false);
      setSelectedColor("");
      setSelectedSpecies("");
      setSelectedLeafType("");
      setSelectedGrowthRate("");
      setSelectedHardinessZone("");
      setStartPrice("");
      setEndPrice("");
      setSearch("");
    } else {
      data = {
        name: "/all-plants",
        color: type == "search" ? " " : selectedColor,
        species: type == "search" ? " " : selectedSpecies,
        leaf_type: type == "search" ? " " : selectedLeafType,
        growth_rate: type == "search" ? " " : selectedGrowthRate,
        hardiness_zone: type == "search" ? " " : selectedHardinessZone,
        start_price: type == "search" ? " " : startPrice,
        end_price: type == "search" ? " " : endPrice,
        search: type == "filter" ? " " : search,
      };
    }
    GetPlantsApi(currentPage, data)
      .then((res) => {
        // console.log(res);
        setPlantsData(res?.data?.data);
        const total = res?.data?.total;
        const limit = res?.data?.per_page;
        setPageCount(Math.ceil(total / limit));
        setSpinLoad(false);
      })
      .catch((err) => {
        // console.log(err);
        setSpinLoad(false);
      });
  };

  // Get ALL plants
  useEffect(() => {
    ApplyFilters(null);
  }, [currentPage]);

  // Get FILTERS Data
  useEffect(() => {
    GetAllFilterApi()
      .then((res) => {
        // console.log("filter ", res);
        setFiltersData(res?.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // console.log(filtersData?.hardiness_zone);
  // console.log(CategoriesReduxData, "CategoriesReduxData");

  return (
    <>
      <Header />
      {/* <!-- InnerBanner Sec Start Here --> */}
      <section className="innerBanner">
        <div className="container">
          <div className="inner-box">
            <div className="title">
              <h2>All Plants</h2>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- InnerBanner Sec End Here --> */}

      {/* <!-- Shop Sec Start Here --> */}
      <section className="shop_sec all-plants">
        <div className="before">
          <figure>
            <img src={smallafter} className="img-fluid" />
          </figure>
        </div>
        <div className="container">
          {CategoriesReduxData?.map((item, index) => {
            return (
              <>
                {item?.name == "All Plants" ? (
                  <div className="heading" key={index}>
                    <h2>{item?.subtitle}</h2>
                  </div>
                ) : null}
              </>
            );
          })}

          <div className="row">
            <div className="col-lg-3">
              <div className="sidebar">
                <div className="heading">
                  <h3>Apply Filters</h3>
                </div>
                <div className="search">
                  <form action="">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <button
                      className="searchBtn"
                      type="submit"
                      onClick={(e) => ApplyFilters(e, "search")}
                    >
                      <i className="fa fa-search" aria-hidden="true"></i>
                    </button>
                  </form>
                </div>
                <div className="filter">
                  <div className="box">
                    <div className="title">
                      <h4>Species</h4>
                    </div>

                    <select
                      className="form-control"
                      id="state"
                      name="state"
                      onChange={(e) => {
                        setSelectedSpecies(e.target.value);
                      }}
                    >
                      <option selected="">Select Species</option>
                      {filtersData?.species?.map((item, index) => {
                        return (
                          <option value={item} key={index}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="box">
                    <div className="title">
                      <h4>Color</h4>
                    </div>
                    <div>
                      <select
                        className="form-control"
                        id="state"
                        name="state"
                        onChange={(e) => {
                          setSelectedColor(e.target.value);
                        }}
                      >
                        <option selected="">Select color</option>
                        {filtersData?.colors?.map((item, index) => {
                          return (
                            <option value={item?.id} key={index}>
                              {item?.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="box">
                    <div className="title">
                      <h4>Leaf Type</h4>
                    </div>
                    <div>
                      <select
                        className="form-control"
                        id="state"
                        name="state"
                        onChange={(e) => {
                          setSelectedLeafType(e.target.value);
                        }}
                      >
                        <option selected="">Select leaf type</option>
                        {filtersData?.leaf_type?.map((item, index) => {
                          return (
                            <option value={item} key={index}>
                              {item}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  <div className="box">
                    <div className="title">
                      <h4>Growth Rate</h4>
                    </div>
                    <div>
                      <select
                        className="form-control"
                        id="state"
                        name="state"
                        onChange={(e) => {
                          setSelectedGrowthRate(e.target.value);
                        }}
                      >
                        <option selected="">Select growth rate</option>
                        {filtersData?.growth_rate?.map((item, index) => {
                          return (
                            <option value={item} key={index}>
                              {item}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  <div className="box">
                    <div className="title">
                      <h4>Hardiness Zone</h4>
                    </div>
                    <div>
                      <select
                        className="form-control"
                        id="state"
                        name="state"
                        onChange={(e) => {
                          setSelectedHardinessZone(e.target.value);
                        }}
                      >
                        <option selected="">Select Hardiness Zone</option>
                        {filtersData?.hardiness_zone?.map((item, index) => {
                          return (
                            <option value={item?.id} key={index}>
                              {item?.zone_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  <div className="price-range">
                    <div className="title">
                      <h4>Price</h4>
                    </div>
                    <div className="input-group">
                      <div className="form-group">
                        <label for="From">From</label>
                        <input
                          type="text"
                          id="From"
                          className="form-control"
                          placeholder="From"
                          value={startPrice}
                          onChange={(e) => setStartPrice(e.target.value)}
                        />
                      </div>
                      <div className="seprator"></div>
                      <div className="form-group">
                        <label for="To">To</label>
                        <input
                          type="text"
                          id="To"
                          className="form-control"
                          placeholder="To"
                          value={endPrice}
                          onChange={(e) => setEndPrice(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mt-2 mb-3">
                    <button
                      className="apply-filter-btn"
                      onClick={(e) => ApplyFilters(e, "filter")}
                    >
                      Apply Filters
                    </button>
                  </div>

                  <div className="mt-2 mb-3">
                    <button
                      className="apply-filter-btn"
                      onClick={(e) => ApplyFilters(e, "reset")}
                    >
                      Reset Filters
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="row">
                {spinLoad ? (
                  <>
                    {[0, 1, 2, 3, 4, 5, 7, 8, 9]?.map((item, index) => {
                      return (
                        <div className="col-md-4 mt-3" key={index}>
                          <Skeleton count={5} />
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {plantsData?.length > 0 ? (
                      <>
                        {plantsData?.map((item, index) => {
                          return (
                            <AllPlantCard
                              item={item}
                              index={index}
                              colValue={"4"}
                              links="/product-detail"
                            />
                          );
                        })}
                      </>
                    ) : (
                      <NoRecord />
                    )}
                  </>
                )}
              </div>
              <div className="paginatio-count">
                <div className="pagination-container mt-5">
                  <ReactPaginate
                    previousLabel="<"
                    nextLabel=">"
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-center"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
                {plantsData?.length > 0 ? (
                  <p className="total-pages">
                    {plantsData?.length} PRODUCTS 1 - {plantsData?.length}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="after">
          <figure>
            <img src={smallbefore} className="img-fluid" />
          </figure>
        </div>
      </section>
      {/* <!-- Shop Sec End Here --> */}
      <Footer />
    </>
  );
};

export default Shop;
