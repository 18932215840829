import React, { useEffect } from "react";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import HomeBlogCards from "../../component/AllCard/HomeBlogCards";
import HomeCategorySection from "../../component/Category/HomeCategorySection";
import WorkStep from "../../component/Work/WorkStep";
import {
  bannervideo,
  arrow,
  probefore,
  btnicon,
  proafter,
  blogbefore,
  blogs4,
} from "../../constant/index";
import {
  GetBlogs,
  GetHomeServices,
  GetPlantsApi,
  GetWorkStep,
} from "../../network/Network";
import { WorkStepData } from "../../redux/actions/AuthActions";

const HomeContain = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const ImageUrlRedux = useSelector((state) => state.AuthReducer.urlData);
  const SiteSettingsRedux = useSelector(
    (state) => state.AuthReducer.siteSettings
  );
  const SocialDataRedux = useSelector((state) => state.AuthReducer.socialData);
  const CategoriesReduxData = useSelector(
    (state) => state.AuthReducer.categories
  );
  const StepDataRedux = useSelector((state) => state.AuthReducer.stepData);
  const GalleryReduxData = useSelector(
    (state) => state.CartReducer.galleryData
  );
  const [plantsData, setPlantsData] = useState([]);
  const [plantSpinLoad, setPlantSpinLoad] = useState(false);

  const [blogData, setBlogData] = useState([]);
  const [blogSpinLoad, setBlogSpinLoad] = useState(false);

  const [serviceData, setServiceData] = useState([]);
  const [stepsData, setStepsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  // Get ALL plants
  useEffect(() => {
    setPlantSpinLoad(true);
    let data = {
      name: "/all-plants",
      color: " ",
      species: " ",
      leaf_type: " ",
      growth_rate: " ",
      hardiness_zone: " ",
      start_price: " ",
      end_price: " ",
      search: " ",
    };
    GetPlantsApi(currentPage, data)
      .then((res) => {
        // console.log(res);
        setPlantsData(res?.data?.data);
        setPlantSpinLoad(false);
      })
      .catch((err) => {
        // console.log(err);
        setPlantSpinLoad(false);
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const headerstyle = {
    background: "transparent",
    position: "absolute",
    top: 0,
  };

  // Get Blogs
  useEffect(() => {
    setBlogSpinLoad(true);
    GetBlogs()
      .then((res) => {
        // console.log("blogs", res);
        setBlogData(res?.data);
        setBlogSpinLoad(false);
      })
      .catch((err) => {
        // console.log(err);
        setBlogSpinLoad(false);
      });
  }, []);

  // Get Services Home
  useEffect(() => {
    GetHomeServices()
      .then((res) => {
        // console.log("service", res);
        setServiceData(res?.data?.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);

  var browseCarousel = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    // centerMode: true,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 5000,
    // cssEase: "linear"
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
    
  };
  var ourBlogCarousel = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,

    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };
  var socialCarousel = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: true,

    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };
  return (
    <>
      {/* <!-- Banner Sec Start Here --> */}
      <section className="banner_sec">
        <div className="container">
          <div className="banner_content">
            <div className="video_box">
         
              <video width="100%" id="myvid" autoPlay loop muted poster={blogs4}>
                {/* <source src={bannervideo}  type="video/mp4"></source> */}
                <source src={`${ImageUrlRedux?.shop_image_url}/${SiteSettingsRedux?.kigi_shop_banner_video}`} type="video/mp4"></source>
              </video>
              <div className="toggle-btn" onclick="playPause()"></div>
            </div>
            <div className="content_dv">
              <h1>{SiteSettingsRedux?.kigi_company_banner_title}</h1>
              <div className="button-group">
                <Link to="/all-plants" className="btn">
                  SHOP NOW <img src={arrow} className="img-fluid" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Banner Sec End Here --> */}

      {/* <!-- Quality Sec Start Here --> */}
      <section className="quality_sec">
        <div className="container">
          <div className="boxes">
            <div className="row">
              {serviceData?.map((item, index) => {
                return (
                  <div className="col-lg-4" key={index}>
                    <div className="icon_with_text">
                      <div className="iconDv">
                        <figure>
                          <img
                            src={`${ImageUrlRedux?.services_url}/${item?.image}`}
                            className="img-fluid"
                          />
                        </figure>
                        <h4>{item?.title}</h4>
                      </div>
                      <div className="txt">
                        <p>{item?.description}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Quality Sec End Here --> */}

      {/* <!-- Browse Product Sec Start Here --> */}
      <section className="prouct-sec">
        <div className="left">
          <figure>
            <img src={probefore} className="img-fluid" />
          </figure>
        </div>
        <div className="container">
          <div className="heading">
            <h2>
             {SiteSettingsRedux?.kigi_home_section_heading}
            </h2>
          </div>
          <div className="discover">
            <div className="heading_wrapper">
              <h2>Browse Our Products</h2>
              <p>
                Duis convallis convallis tellus id interdum velit laoreet id
                donec. Vitae nunc sed velit dignissim. Facilisi cras fermentum
                odio eu.
              </p>
            </div>
            <div className="view-all">
              <Link to="/all-plants" className="lnk">
                View more <img src={btnicon} className="img-fluid" />
              </Link>
            </div>
          </div>
        </div>
        <div className="product-slider">
          {plantSpinLoad ? (
            <>
              {[0, 1, 2, 3, 4, 5, 7, 8, 9]?.map((item, index) => {
                return (
                  <div key={index}>
                    <Skeleton count={5} />
                  </div>
                );
              })}
            </>
          ) : (
            <Slider {...browseCarousel}>
              {plantsData?.map((item, index) => {
                return (
                  <div key={index}>
                    <div className="product-box">
                      {/* <Link to={`/product-detail/${item?.id}`}> */}
                        <div className="pro-img">
                          <figure>
                            <img
                              src={`${ImageUrlRedux?.product_thumbnail_url}/${item?.thumbnail}`}
                              alt=""
                              className="img-fluid"
                            />
                          </figure>
                        </div>
                        <div className="pro-detail">
                          <div className="name">
                            <h4 onClick={()=>navigate(`/product-detail/${item?.id}`)}>{item?.name}</h4>
                          </div>
                          <div className="price">
                            <h4>${item?.unit_price}</h4>
                          </div>
                        </div>
                      {/* </Link> */}
                    </div>
                  </div>
                );
              })}
            </Slider>
          )}
        </div>
        <div className="right">
          <figure>
            <img src={proafter} className="img-fluid" />
          </figure>
        </div>
      </section>
      {/* <!-- Browse Product Sec End Here --> */}

      {/* <!-- Categories Sec Start Here --> */}
              <HomeCategorySection 
                CategoriesReduxData={CategoriesReduxData}
              />
      {/* <!-- Categories Sec End Here --> */}

      {/* <!-- How It Work Sec Start Here --> */}
          <WorkStep 
            StepDataRedux={StepDataRedux} 
            url={"/about"} 
          />
      {/* <!-- How It Work Sec End Here --> */}

      {/* <!-- Blog Sec Start Here --> */}
      <section className="blog_sec">
        <div className="blog-before">
          <figure>
            <img src={blogbefore} className="img-fluid" />
          </figure>
        </div>
        <div className="container">
          <div className="discover">
            <div className="heading_wrapper">
              <h2>Our Blogs</h2>
            </div>
            <div className="view-all">
              <Link to="/blogs" className="lnk">
                View More <img src={btnicon} className="img-fluid" />
              </Link>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="blog_slider">
            {blogSpinLoad ? (
              <>
                {[0, 1, 2, 3, 4, 5, 7, 8, 9]?.map((item, index) => {
                  return (
                    <div key={index}>
                      <Skeleton count={5} />
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                <Slider {...ourBlogCarousel}>
                  {blogData?.map((item, index) => {
                    return (
                      <HomeBlogCards
                        index={index}
                        item={item}
                        ImageUrlRedux={ImageUrlRedux}
                      />
                    );
                  })}
                </Slider>
              </>
            )}
          </div>
        </div>
      </section>
      {/* <!-- Blog Sec End Here --> */}

      {/* <!-- Social Sec Start Here --> */}
      <section className="social_sec">
        <div className="container">
          <div className="discover">
            <div className="heading_wrapper">
              <h2>Follow Us on social media</h2>
            </div>
            <ul className="social-icons">
              {SocialDataRedux?.map((item, index) => {
                return (
                  <li key={index}>
                    <a href={`${item?.link}`} target="_blank">
                      <i className={item?.icon} aria-hidden="true"></i>
                    </a>
                  </li>
                );
              })}

              {/* <li>
                <a href="#">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-tiktok"
                    viewBox="0 0 16 16"
                  >
                    <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3V0Z" />
                  </svg>
                </a>
              </li> */}
            </ul>
          </div>
        </div>
        <div className="all-feeds">
          <Slider {...socialCarousel}>
            {GalleryReduxData?.map((item, index) => {
              return (
                <figure key={index}>
                  <img src={item?.full_media_url} className="img-fluid" />
                </figure>
              );
            })}
          </Slider>
        </div>
      </section>
      {/* <!-- Social Sec End Here --> */}
    </>
  );
};

export default HomeContain;
