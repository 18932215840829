import React, { useEffect } from "react";
import Footer from "../../component/Footer";
import Header from "../../component/Header";
import "../../assets/style/terms.css";
import { arrow, smallafter, smallbefore } from "../../constant/index";
import { GetSiteSettingsApi } from "../../network/Network";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";

const ShippingPolicy = () => {
  const [shippingData, setShippingData] = useState();
const [spinLoad, setSpinLoad] = useState(false)

  // Get shipping Data
  useEffect(() => {
    setSpinLoad(true)
    let type = "shipping_policy";
    GetSiteSettingsApi(type)
      .then((res) => {
        // console.log("shipping", res);
        setShippingData(res?.data?.data);
        setSpinLoad(false)
      })
      .catch((err) => {
        // console.log(err);
        setSpinLoad(false)
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      {/* <!-- InnerBanner Sec Start Here --> */}
      <section className="innerBanner">
        <div className="container">
          <div className="inner-box">
            <div className="title">
              <h2>Shipping policy</h2>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- InnerBanner Sec End Here --> */}

      {/* <!-- Term Sec Start Here --> */}
      <section className="site-setting">
        <div className="before">
          <figure>
            <img src={smallafter} className="img-fluid" />
          </figure>
        </div>
        <div className="container">
          <div className="heading">
            <h2>Shipping policy</h2>
          </div>
          {spinLoad ? (
            <>
              {[0, 1, 2, 3, 4, 5, 7, 8, 9]?.map((item, index) => {
                return (
                  <div className="row">
                    <div className="col-md-12 mt-3" key={index}>
                      <Skeleton count={5} />
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <p
              dangerouslySetInnerHTML={{
                __html: shippingData?.value,
              }}
            />
          )}
        </div>
        <div className="after">
          <figure>
            <img src={smallbefore} className="img-fluid" />
          </figure>
        </div>
      </section>
      {/* <!-- Term Sec End Here --> */}
      <Footer />
    </>
  );
};

export default ShippingPolicy;
