import React, { useEffect, useState } from "react";
import Footer from "../../component/Footer";
import Header from "../../component/Header";
import "../../assets/style/shop.css";
import { blogbefore, proafter, plantmix } from "../../constant/index";
import NoRecord from "../../component/NoRecord/NoRecord";
import { GetPlantsApi } from "../../network/Network";
import Skeleton from "react-loading-skeleton";
import ProductCard from "../../component/AllCard/ProductCard";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";

const PlantingMaxing = () => {
  const CategoriesReduxData = useSelector(
    (state) => state.AuthReducer.categories
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState();

  const handlePageClick = async (data) => {
    setCurrentPage(data?.selected + 1);
  };

  const [plantMixesData, setPlantMixesData] = useState([]);
  const [spinLoad, setSpinLoad] = useState(false);

  // Get ALL plant mixes
  useEffect(() => {
    setSpinLoad(true);
    let data = {
      name: "/planting-mixes",
    };
    GetPlantsApi(currentPage,data)
      .then((res) => {
        // console.log(res);
        setPlantMixesData(res?.data?.data);
        const total = res?.data?.total;
        const limit = res?.data?.per_page;
        setPageCount(Math.ceil(total / limit));
        setSpinLoad(false);
      })
      .catch((err) => {
        // console.log(err);
        setSpinLoad(false);
      });
  }, [currentPage]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      {/* <!-- InnerBanner Sec Start Here --> */}
      <section className="innerBanner">
        <div className="container">
          <div className="inner-box">
            <div className="title">
              <h2>Planting Mixes</h2>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- InnerBanner Sec End Here --> */}

      {/* <!-- Shop Sec Start Here --> */}
      <section className="plant-mixes similar">
        <div className="before">
          <figure>
            <img src={blogbefore} className="img-fluid" />
          </figure>
        </div>
        <div className="container">
        {CategoriesReduxData?.map((item, index) => {
            return (
              <>
                {item?.name == "PLANTING MIXES" ? (
                  <div className="heading" key={index}>
                  <h2>{item?.subtitle}</h2>
                  <p>
                    {item?.description}
                  </p>
                </div>
                ) : null}
              </>
            );
          })}
        
          <div className="row">
            {spinLoad ? (
              <>
                {[0, 1, 2, 3, 4, 5]?.map((item, index) => {
                  return (
                    <div className="col-md-4 mt-3" key={index}>
                      <Skeleton count={5} />
                    </div>
                  );
                })}
              </>
            ) : plantMixesData?.length > 0 ? (
              <>
                {plantMixesData?.map((item, index) => {
                  return (
                    <ProductCard
                      item={item}
                      index={index}
                      colValue={"3"}
                      pageLink={"/planting-mixes-item"}
                    />
                   
                  );
                })}
              </>
            ) : (
              <NoRecord />
            )}
          </div>
          <div className="paginatio-count">
            <div className="pagination-container mt-5">
              <ReactPaginate
                previousLabel="<"
                nextLabel=">"
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
            {plantMixesData?.length > 0 ? (
                  <p className="total-pages">
                    {plantMixesData?.length} PRODUCTS 1 - {plantMixesData?.length}
                  </p>
                ) : null}
          </div>
        </div>
        <div className="after">
          <figure>
            <img src={proafter} className="img-fluid" />
          </figure>
        </div>
      </section>
      {/* <!-- Shop Sec End Here --> */}
      <Footer />
    </>
  );
};

export default PlantingMaxing;
