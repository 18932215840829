import {
  AUTH,
  REGISTER,
  BLOGS,
  GET_KIGI_FAQS,
  ADD,
  NEWS_LETTER,
  KIGI_NURSERY,GET_KIGI_SITESETTINGS,
  CUSTOMER,
  WISH_LIST,
  REMOVE,
  URLS,
  CATEGORIES,
  BRANDS,
  CAMPAIGN,
  GET_CAMPAIGN,
  FIND_CAMPAIGN,
  DONATE,
  MAKE_DONATION,
  MEMBERSHIP,
  GET_MEMBERSHIP,
  FIND_MEMBERSHIP,
  CSA_PRODUCT_PRICE,
  PRODUCTS,
  DETAILS,
  REVIEWS,SUBMIT,
  RATING,
  UPDATE_PROFILE,
  ADDRESS,LIST,
  CHANGE_PASSWORD,
  ORDER,BLOG_DETAILS,
  CONTACT, CONTACTUS,
  GALLERY,GET_IMAGES,
  FILTER_TYPES, 
  GIFT_CODE,SEND_GIFT_CODE,
 GIFT_THEME, GET_GIT_THEME,
 GET_VIDEOS,MY_DONATION,
 MAKE_DEFAULT,DELETE,
 GET_GIFT_CODE,
 COUPON,
 APPLY,
 PLACE,
 RESET_PASSWORD,
 SERVICES,GET_SERVICES,
 HOW_IT_WORKS,GET_HOME_IT_WORKS,
 PRODUCT_DATE,
 GET_KIGI_SOCIAL_MEDIA,
 FREQUENTLY_BOUGHT,
 EDIT_ADDRESS ,

  // hoho end 
  LOGIN,
  FORGOT,
  // End Franchise s
    CAR,
    INSTANTOFFER,
    ALL_LISTING,
    // FROGOT_PASSWORD,
  } from "../network/Endpoint";
  
  import {
    doGet,
    doPost,
    doPatch,
    doDelete,
    doPostProfilePictureUpload,
    doPut,
    doPutPictureUpload,
  } from "./Config";


  export const postSignUp = (data) => {
    return doPost(`${AUTH}${REGISTER}`, data);
  };

  export const postSignIn = (data) => {
    return doPost(`${AUTH}${LOGIN}`, { ...data });
  };

  export const forgotPasswordApi = (data) => {
    return doPost(`${AUTH}${FORGOT}`, data);
  };

  export const GetBlogs = () => {
    return doGet(`${BLOGS}`);
  };

  export const GetFaqs = () => {
    return doGet(`${KIGI_NURSERY}${GET_KIGI_FAQS}`);
  };

  export const PostNewsLetter = (data) => {
    return doPost(`${NEWS_LETTER}${ADD}`, data);
  };

  export const GetSiteSettingsApi = (type) => {
    return doGet(`${KIGI_NURSERY}${GET_KIGI_SITESETTINGS}${`?type`}=${type ? type : " "}`);
  };


  export const GetAllWishList = (currentPage,token) => {
    return doGet(`${CUSTOMER}${WISH_LIST}${"?page"}=${currentPage}`, token);
  };

  export const PostWishListDelete = (data, token) => {
    return doPost(`${CUSTOMER}${WISH_LIST}${REMOVE}`,data, token);
  };

  export const PostAddWishList = (data, token) => {
    return doPost(`${CUSTOMER}${WISH_LIST}${ADD}`, data, token);
  };

  export const GetCategories = () => {
    return doGet(`${CATEGORIES}`, );
  };
  
  export const GetUrls = () => {
    return doGet(`${URLS}`, );
  };

  export const GetBrands = () => {
    return doGet(`${BRANDS}`, );
  };
  
  export const GetCampaign = () => {
    return doGet(`${CAMPAIGN}${GET_CAMPAIGN}`, );
  };

  export const PostCampaignDetails = (id) => {
    return doPost(`${CAMPAIGN}${FIND_CAMPAIGN}${"/"}${id}`,);
  };

  export const PostCampaignDonation = (data, token) => {
    return doPost(`${DONATE}${MAKE_DONATION}`, data, token);
  };

  export const GetMembership = () => {
    return doGet(`${MEMBERSHIP}${GET_MEMBERSHIP}`, );
  };

  export const PostMembershipById = (id) => {
    return doPost(`${MEMBERSHIP}${FIND_MEMBERSHIP}/${id}`,);
  };

  export const PostCSAProductPriceById = (id) => {
    return doPost(`${MEMBERSHIP}${CSA_PRODUCT_PRICE}/${id}`,);
  };

  export const GetPlantsApi = (currentPage,data) => {
    return doGet(`${CATEGORIES}${PRODUCTS}${data?.name}${"?color"}=${data?.color ? data?.color : " "}${"&species"}=${data?.species ? data?.species : " "}${"&leaf_type"}=${data?.leaf_type ? data?.leaf_type : " "}${"&growth_rate"}=${data?.growth_rate ? data?.growth_rate : " "}${"&hardiness_zone"}=${data?.hardiness_zone ? data?.hardiness_zone : " "}${"&start_price"}=${data?.start_price ? data?.start_price : " "}${"&end_price"}=${data?.end_price ? data?.end_price : " "}${"&category_id"}=${data?.category ? data?.category : " "}${"&search"}=${data?.search ? data?.search : " "}${"&page"}=${currentPage}`, );
  };


  export const GetProductDetailsApi = (id) => {
    return doGet(`${PRODUCTS}${DETAILS}/${id}`, );
  };

  export const PostProductReviewApi = (data,token) => {
    return doPost(`${PRODUCTS}${REVIEWS}${SUBMIT}`,data,token);
  };

  export const GetProductRatingApi = (id) => {
    return doGet(`${PRODUCTS}${RATING}/${id}`, );
  };

  export const UpdateProfile = (data, token) => {
    return doPostProfilePictureUpload(
      `${CUSTOMER}${UPDATE_PROFILE}`,data,token);
  };

  export const PostChangePassword = (data, token) => {
    return doPost(`${CUSTOMER}${CHANGE_PASSWORD}`, data, token);
  };

  export const GetAllAddress = (token) => {
    return doGet(`${CUSTOMER}${ADDRESS}${LIST}`, token);
  };

  export const GetAllOrder = (currentPage,token) => {
    return doGet(`${CUSTOMER}${ORDER}${LIST}${"?page"}=${currentPage}`, token);
  };

  export const GetBlogDetails = (id) => {
    return doPost(`${BLOGS}${BLOG_DETAILS}/${id}`,);
  };

  export const ContactUsApi = (data) => {
    return doPost(`${CONTACT}${CONTACTUS}`, data);
  };

  export const GetGalleryImage = () => {
    return doGet(`${'/kigi-nursery'}${GALLERY}${GET_IMAGES}`, );
  };

  export const GetAllFilterApi = () => {
    return doGet(`${PRODUCTS}${FILTER_TYPES}`, );
  };

  export const PostGiftApi = (data) => {
    return doPost(`${GIFT_CODE}${SEND_GIFT_CODE}`, data);
  };

  export const GetGiftThemesApi = () => {
    return doGet(`${GIFT_THEME}${GET_GIT_THEME}`, );
  };

  export const PostAddressApi = (data,token) => {
    return doPost(`${CUSTOMER}${ADDRESS}${ADD}`, data, token);
  };

  export const GetVideosApi = () => {
    return doGet(`${'/kigi-nursery'}${GALLERY}${GET_VIDEOS}`, );
  };

  export const MyDonations = (currentPage,token) => {
    return doGet(`${CUSTOMER}${MY_DONATION}${"?page"}=${currentPage}`, token);
  };

  export const PostMakeDefaultAddress = (data,token) => {
    return doPost(`${CUSTOMER}${ADDRESS}${MAKE_DEFAULT}`, data, token);
  };

  export const PostDeleteAddress = (data,token) => {
    return doPost(`${CUSTOMER}${ADDRESS}${DELETE}`, data, token);
  };

  export const GetGiftCode = (data, token) => {
    return doGet(`${GIFT_CODE}${GET_GIFT_CODE}${"?giftCode"}=${data?.code}`, token);
  };

  export const GetCouponCode = (data, token) => {
    return doGet(`${COUPON}${APPLY}${"?code"}=${data?.code}${"&amount"}=${data?.amount}`, token);
  };
  
  export const PostPlaceOrder = (finalData,token) => {
    return doPost(`${CUSTOMER}${ORDER}${PLACE}`, finalData, token);
  };

  export const ResetPasswordApi = (data) => {
    return doPost(`${AUTH}${RESET_PASSWORD}`, data);
  };


  export const GetHomeServices = () => {
    return doGet(`${SERVICES}${GET_SERVICES}`, );
  };

  export const GetWorkStep = () => {
    return doGet(`${HOW_IT_WORKS}${GET_HOME_IT_WORKS}`, );
  };

  export const GetProductDate = () => {
    return doGet(`${PRODUCTS}${PRODUCT_DATE}`, );
  };

  export const GetSocialMedia = () => {
    return doGet(`${KIGI_NURSERY}${GET_KIGI_SOCIAL_MEDIA}`, );
  };

  export const GetFrequentProducts = (id) => {
    return doGet(`${PRODUCTS}${FREQUENTLY_BOUGHT}${"?product_id"}=${id}`, );
  };

  export const EditAddress = (data,token) => {
    return doPost(`${CUSTOMER}${ADDRESS}${EDIT_ADDRESS}`,data, token );
  };

  export const forgotPasswordOtp = (data) => {
    return doPost(`${AUTH}${RESET_PASSWORD}`, data);
  };

  // HOHO FARM 



  
  export const GetFilterCar = (currentPage = null,data) => {
    let link = `${CAR}${ALL_LISTING}`;
    if(data){
      link += '?';
      link += (data.color_code)? `${"color_code"}=${data?.color_code}&` : '';  
      link += (data.mileage)? `${"mileage"}=${data?.mileage}&` : '';  
      link += (data.car_category_id)? `${"car_category_id"}=${data?.car_category_id}&` : '';  
      link += (data.make_id)? `${"make_id"}=${data?.make_id}&` : '';  
      link += (data.model_id)? `${"model_id"}=${data?.model_id}&` : '';  
      link += (data.condition_id)? `${"condition_id"}=${data?.condition_id}&` : '';  
      link += (data.drive_type_id)? `${"drive_type_id"}=${data?.drive_type_id}&` : '';  
      link += (data.transmission_id)? `${"transmission_id"}=${data?.transmission_id}&` : '';  
      link += (data.fuel_type_id)? `${"fuel_type_id"}=${data?.fuel_type_id}&` : '';  
      link += (data.min_year)? `${"min_year"}=${data?.min_year}&` : '';  
      link += (data.max_year)? `${"max_year"}=${data?.max_year}&` : '';  
      link += (data.min_price)? `${"min_price"}=${data?.min_price}&` : '';  
      link += (data.max_price)? `${"max_price"}=${data?.max_price}&` : '';
      link += (currentPage)? `${"page"}=${currentPage}` : ''
    }
    // console.log(link);
    return doGet(link,data?.car_category_id);
  };

  export const GetCarListingInstantOffer = (currentPage = null,data) => {
    let link = `${CAR}${INSTANTOFFER}`;
    if(data){
      link += '?';
      link += (data.color_code)? `${"color_code"}=${data?.color_code}&` : '';  
      link += (data.mileage)? `${"mileage"}=${data?.mileage}&` : '';  
      link += (data.car_category_id)? `${"car_category_id"}=${data?.car_category_id}&` : '';  
      link += (data.make_id)? `${"make_id"}=${data?.make_id}&` : '';  
      link += (data.model_id)? `${"model_id"}=${data?.model_id}&` : '';  
      link += (data.condition_id)? `${"condition_id"}=${data?.condition_id}&` : '';  
      link += (data.drive_type_id)? `${"drive_type_id"}=${data?.drive_type_id}&` : '';  
      link += (data.transmission_id)? `${"transmission_id"}=${data?.transmission_id}&` : '';  
      link += (data.fuel_type_id)? `${"fuel_type_id"}=${data?.fuel_type_id}&` : '';  
      link += (data.min_year)? `${"min_year"}=${data?.min_year}&` : '';  
      link += (data.max_year)? `${"max_year"}=${data?.max_year}&` : '';  
      link += (data.min_price)? `${"min_price"}=${data?.min_price}&` : '';  
      link += (data.max_price)? `${"max_price"}=${data?.max_price}&` : '';
      link += (currentPage)? `${"page"}=${currentPage}` : ''
    }
    // console.log(link);
    return doGet(link,data?.car_category_id);
    // return doGet(`${CAR}${INSTANTOFFER}`);
  };









  // ------ end

  