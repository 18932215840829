import React, { useEffect, useState } from "react";
import Footer from "../../component/Footer";
import Header from "../../component/Header";
import "../../assets/style/donatenow.css";
import {
  smallafter,
  smallbefore,
} from "../../constant/index";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { PostCampaignDonation } from "../../network/Network";
import { toast } from "react-toastify";
import StripeForm from "../../component/PaymentMethod/StripeForm";

const DonateNow = () => {
  const navigate = useNavigate();
  const Token = useSelector((state) => state.AuthReducer.token);
  const userData = useSelector((state) => state.AuthReducer.users);
  const location = useLocation();
  // console.log(location);
  const [firstName, setFirstName] = useState(userData?.f_name ? userData?.f_name : "");
  const [lastName, setLastName] = useState(userData?.l_name ? userData?.l_name : "");
  const [phone, setPhone] = useState(userData?.phone ? userData?.phone : "");
  const [email, setEmail] = useState(userData?.email ? userData?.email : "");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [userSate, setUserState] = useState("");
  const [zip, setZip] = useState("");
  const [country, setCountry] = useState("");
  const [stripeToken, setStripeToken] = useState("");
  const [is_anonymous, setIs_anonymous] = useState(false);
  const [loading, setLoading] = useState(false);

  const [amount, setAmount] = useState("");
  // console.log("amount", amount);

  const SubmitDonation = (e, stripe_token) => {
    e.preventDefault();
    setLoading(true);
    if (
      !firstName ||
      !lastName ||
      !phone ||
      !country ||
      !amount ||
      !email ||
      !address ||
      !city ||
      !userSate ||
      !zip
    ) {
      setLoading(false);
      toast.error("Please Enter All Field");
      return;
    }
    if (firstName?.length < 3 || firstName?.length > 30) {
      setLoading(false);
      toast.error("First name must be between 3 to 30 characters");
      return;
    }
    if (lastName?.length < 3 || lastName?.length > 30) {
      setLoading(false);
      toast.error("Last name must be between 3 to 30 characters");
      return;
    }
    if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      toast.error("Invalid Email");
      setLoading(false);
      return;
    }

    if(phone.length < 11 || phone.length > 15){
      setLoading(false);
      toast.error("Phone number must be betwween 11 to 15 digits");
      return;
    }
    let data = new FormData();
    if (location?.state?.data?.page == "birds") {
      data.append("product_id", location?.state?.data?.id);
      data.append("donation_type", "product");
    } else {
      data.append("campaign_id", location?.state?.data?.id);
      data.append("donation_type", "campaign");
    }
    data.append("f_name", firstName);
    data.append("l_name", lastName);
    data.append("email", email);
    data.append("phone", phone);
    data.append("address", address);
    data.append("city", city);
    data.append("state", userSate);
    data.append("post_code", zip);
    data.append("country", country);
    data.append("is_anonymous", is_anonymous ? 1 : 0);
    data.append("amount", amount);
    data.append("platform", "kigi");
    data.append("_token", stripe_token);
    PostCampaignDonation(data, Token)
      .then((res) => {
        // console.log(res);
        toast.success(res?.data?.message);
        setLoading(false);
        navigate('/donate')
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhone("");
        setAddress("");
        setCity("");
        setUserState("");
        setZip("");
        setCountry("");
        setIs_anonymous(false);
        setAmount("");
      })
      .catch((err) => {
        setLoading(false);
        // console.log(err)
        toast.error(err?.response?.data?.message)
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const styling = {
    marginTop: "10px",
    width: "100%",
    padding: "8px 16px",
    borderRadius: "20px",
  };

  return (
    <>
      <Header />
      {/* <!-- InnerBanner Sec Start Here --> */}
      <section className="innerBanner">
        <div className="container">
          <div className="inner-box">
            <div className="title">
              <h2>Donate Now</h2>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- InnerBanner Sec End Here --> */}

      {/* <!-- Donate Now Sec Start Here --> */}
      <section className="donate_now">
        <div className="before">
          <figure>
            <img src={smallafter} className="img-fluid" />
          </figure>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-3"></div>
            <div className="col-lg-6 donation-form">
              <div className="heading">
                <h2>Donate Now</h2>
              </div>
              <div className="title">
                <h2>Your Donation</h2>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <label className="donate_container">
                    <input
                      type="radio"
                      hidden
                      name="donate-price"
                      value="50"
                      checked={amount === 50 ? true : false}
                      onChange={(e) => setAmount(e.target.value)}
                    />
                    <span className="checkmark">
                      <h4
                        className={`${
                          amount === "50" ? "selected_amount" : " "
                        }`}
                      >
                        $ 50.00
                      </h4>
                    </span>
                  </label>
                </div>
                <div className="col-md-4">
                  <label className="donate_container">
                    <input
                      type="radio"
                      hidden
                      name="donate-price"
                      value="100"
                      checked={amount === 100 ? true : false}
                      onChange={(e) => setAmount(e.target.value)}
                    />
                    <span className="checkmark">
                      <h4
                        className={`${
                          amount === "100" ? "selected_amount" : " "
                        }`}
                      >
                        $ 100.00
                      </h4>
                    </span>
                  </label>
                </div>
                <div className="col-md-4">
                  <label className="donate_container">
                    <input
                      type="radio"
                      hidden
                      name="donate-price"
                      value="150"
                      checked={amount === 150 ? true : false}
                      onChange={(e) => setAmount(e.target.value)}
                    />
                    <span className="checkmark">
                      <h4
                        className={`${
                          amount === "150" ? "selected_amount" : " "
                        }`}
                      >
                        $ 150.00
                      </h4>
                    </span>
                  </label>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Custom Amount</label>
                    <input
                      type="text"
                      placeholder="0.00"
                      className="form-control"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="title">
                    <h2>Details</h2>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="email"
                      className="form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Phone Number</label>
                    <input
                      type="number"
                      className="form-control"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Address</label>
                    <input
                      type="text"
                      className="form-control"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>City</label>
                    <input
                      type="text"
                      className="form-control"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>State</label>
                    <input
                      type="text"
                      className="form-control"
                      value={userSate}
                      onChange={(e) => setUserState(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Post Code</label>
                    <input
                      type="number"
                      className="form-control"
                      value={zip}
                      onChange={(e) => setZip(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Country</label>
                    <input
                      type="text"
                      className="form-control"
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group ml-3">
                    <input
                      type="checkbox"
                      id="anonymously"
                      className="form-check-input"
                      value={is_anonymous}
                      onChange={(e) => setIs_anonymous(!is_anonymous)}
                    />
                    <label for="anonymously">
                      I would like to make my donation anonymously.
                    </label>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="title">
                    <h2>Payment</h2>
                  </div>
                </div>

                <div className="col-md-12">
                  <StripeForm
                    styling={styling}
                    paynow="Donate Now"
                    PaymentHandler={SubmitDonation}
                    loading={loading}
                  />
                </div>
              </div>
              <div className="col-lg-3"></div>
            </div>
          </div>
        </div>
        <div className="after">
          <figure>
            <img src={smallbefore} className="img-fluid" />
          </figure>
        </div>
      </section>
      {/* <!-- Donate Now Sec End Here --> */}
      <Footer />
    </>
  );
};

export default DonateNow;
