// import React from "react";
// import { BrowserRouter, Routes, Route } from "react-router-dom";
// import Aboutus from "../pages/About/Aboutus";
// import ForgotEmail from "../pages/Auth/ForgotEmail";
// import Login from "../pages/Auth/Login";
// import Signup from "../pages/Auth/Signup";
// import Blogs from "../pages/Blogs/Blogs";
// import BookingMemberShip from "../pages/BookingMemberShip/BookingMemberShip";
// import GardenTour from "../pages/BookingMemberShip/GardenTour";
// import Camping from "../pages/BookingMemberShip/Camping";
// import FramersBooth from "../pages/BookingMemberShip/FramersBooth";
// import Cart from "../pages/Cart/Cart";
// import Checkout from "../pages/Cart/Checkout";
// import Contactus from "../pages/Contact/Contactus";
// import Donate from "../pages/Donate/Donate";
// import Faqs from "../pages/Faqs/Faqs";
// import Home from "../pages/Home/Home";
// import PrivacyPolicy from "../pages/PolicyPages/PrivacyPolicy";
// import Touristslove from "../pages/PolicyPages/Touristslove";
// import Terms from "../pages/PolicyPages/Terms";
// import AllPlants from "../pages/Products/AllPlants";
// import NutrientsAdditives from "../pages/Products/NutrientsAdditives";
// import PlantingMixes from "../pages/Products/PlantingMixes";
// import ProductDetails from "../pages/Products/ProductDetails";
// import Products from "../pages/Products/Products";
// import MyBooking from "../pages/Profile/MyBooking";
// import MyDonation from "../pages/Profile/MyDonation";
// import MyMemberShip from "../pages/Profile/MyMemberShip";
// import OrdersDetails from "../pages/Profile/OrdersDetails";
// import MyOrders from "../pages/Profile/MyOrders";
// import Profile from "../pages/Profile/Profile";
// import Wishlist from "../pages/Profile/Wishlist";
// import NotFound from "../pages/404 Pages/NotFound";

// import ProtectedRoutes from "./ProtectedRoutes";
// import CSAMEMBERship from "../pages/BookingMemberShip/CSAMembership";
// import MyAddress from "../pages/Profile/MyAddress";
// import OurDoners from "../pages/OurDoners/OurDoners";
// import Testimonial from "../pages/Testimonial/Testimonial";
// import Cancelation from "../pages/PolicyPages/Cancelation";
// import QAScanner from "../Components/QAScanner/QAScanner";
// import QACode from "../pages/Profile/QACode";
// import OrderDetails from "../pages/BookingCard/BookingCard";
// import OrderDetail from "../pages/OrderDetails/OrderDetails";
// import MyDonationDetails from "../pages/My Donation/MyDonation";
// import MyBookings from "../pages/MyBooking/MyBookings";
// import OrderDetailSlider from "../Components/Slider/OrderDetailSlider";
// import GeneralHydroponic from "../pages/Products/GeneralHydroponic";
// import FamilyMembership from "../pages/BookingMemberShip/FamilyMembership";
// import Thankyou from "../Components/ThankYouPage/Thankyou";
// import OrderViewDetails from "../pages/OrderViewDetails/OrderViewDetails";
// import DonateListing from "../Components/DonateListing/DonateListing";
// import BlogDetail from "../pages/Blogs/BlogsDetails";
// import OtpPage from "../pages/Auth/OtpPage";
// import NewPassword from "../pages/Auth/NewPassword";
// import ProductsDetailPage from "../pages/Products/ProductsDetailPage";
// import CSAmemberdetail from "../pages/Profile/CSAmemberdetail";
// import FamilyMembershipDetail from "../pages/Profile/familyMembershipDetail";

// function PublicRoutes(props) {
// 	return (
// 		<>
// 			<BrowserRouter>
// 				<Routes>


// 					<Route path="/" element={<Home />} />
// 					<Route path="blogs" element={<Blogs />} />
// 					{/* <Route path="blog-details" element={<BlogDetail />} /> */}
// 					<Route path="all-plants" element={<AllPlants />} />
// 					<Route path="nutrients-additive-item/:id" element={<GeneralHydroponic />} />
// 					<Route path="planting-mixes-item/:id" element={<GeneralHydroponic />} />
					
// 					<Route path="products-detailPage" element={<ProductsDetailPage />} />
// 					<Route path="planting-mixes" element={<PlantingMixes />} />
// 					<Route path="nutrients-additives" element={<NutrientsAdditives />} />
// 					<Route path="products" element={<Products />} />
// 					<Route path="product-details" element={<ProductDetails />} />
// 					<Route path="booking-membership" element={<BookingMemberShip />} />
// 					<Route path="garden-tour" element={<GardenTour />} />
// 					<Route path="camping" element={<Camping />} />
// 					<Route path="framers-booth" element={<FramersBooth />} />
// 					<Route path="family-membership" element={<FamilyMembership />} />
// 					<Route path="csa-membership" element={<CSAMEMBERship />} />
// 					<Route path="All-Order-Detail" element={<OrderViewDetails />} />
// 					<Route path="donate-listing" element={<DonateListing />} />
// 					<Route path="our-doners/:id" element={<OurDoners />} />
// 					<Route path="donate" element={<Donate />} />
					
// 					<Route path="contact" element={<Contactus />} />
// 					<Route path="about-us" element={<Aboutus />} />
					
// 					<Route path="cart" element={<Cart />} />
// 					<Route path="check-out" element={<Checkout />} />
// 					<Route path="thankyou" element={<Thankyou />} />
// 					<Route path="faqs" element={<Faqs />} />
// 					<Route path="privacy-policy" element={<PrivacyPolicy />} />
// 					<Route path="blog-details" element={<Touristslove />} />
// 					<Route path="testimonial" element={<Testimonial />} />
// 					<Route path="terms-conditions" element={<Terms />} />
// 					<Route path="cancelation" element={<Cancelation />} />
					
// 					<Route path="donation" element={<MyDonationDetails />} />
// 					<Route path="booking-details" element={<MyBookings />} />

// 					<Route path="login" element={<Login />} />
// 					<Route path="signup" element={<Signup />} />
// 					<Route path="forget-email" element={<ForgotEmail />} />
// 					<Route path="otp" element={<OtpPage />} />
// 					<Route path="new-password" element={<NewPassword />} />

// 					<Route path="/" element={<ProtectedRoutes />}>
// 						<Route path="profile" element={<Profile />} />
// 						<Route path="address" element={<MyAddress />} />
// 						<Route path="my-orders" element={<MyOrders />} />
// 						<Route path="my-bookings" element={<MyBooking />} />
// 						<Route path="my-membership" element={<MyMemberShip />} />
// 						<Route path="CSA-member" element={<CSAmemberdetail />} />
// 						<Route
// 							path="family-member-details"
// 							element={<FamilyMembershipDetail />}
// 						/>
// 						<Route path="orders-details" element={<OrdersDetails />} />
// 						<Route path="my-donations" element={<MyDonation />} />
// 						<Route path="wishlist" element={<Wishlist />} />
// 						<Route path="scanner" element={<QACode />} />
// 						<Route path="order-details" element={<OrderDetail />} />
// 					</Route>

// 					<Route path="/*" element={<NotFound />} />
// 				</Routes>
// 			</BrowserRouter>
// 		</>
// 	);
// }

// export default PublicRoutes;

import React from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Login from "../pages/auth/Login";
import Signup from "../pages/auth/Signup";
import Home from "../pages/Home/Home";
import KigiBird from "../pages/kigibird/KigiBird";
import Nutrient from "../pages/nutrients/Nutrient";
import PlantingMaxing from "../pages/planting-mixing/PlantingMixing";
import Shop from "../pages/shop/Shop";
import PremiumProducts from "../pages/premiumproducts/PremiumProducts";
import Donate from "../pages/donate/Donate";
import Contact from "../pages/contact/Contact";
import Cart from "../pages/cart/Cart";
import Wishlist from "../component/profie/WishList";
import About from "../pages/aboutus/About";
import GiftCertificate from "../pages/giftcertificate/GiftCertificate";
import Gallery from "../pages/gallery/Gallery";
import Blog from "../pages/blogs/Blog";
import Faq from "../pages/faq/Faq";
import ShippingPolicy from "../pages/shippingpolicy/ShippingPolicy";
import Video from "../pages/video/Video";
import ProductDetail from "../pages/productdetail/ProductDetail";
import NutrientProductDetail from "../pages/nutrients/NutrientProductDetail";
import Checkout from "../pages/checkout/Checkout";
import Thankyou from "../pages/thankyou/Thankyou";
import BirdProductDetail from "../pages/kigibird/BirdProductDetail";
import DonateNow from "../pages/donatenow/DonateNow";
import PlantingProductDetail from "../pages/planting-mixing/PlantingProductDetail";
import PremiumProductDetail from "../pages/premiumproducts/PremiumProductDetail";
import Campaign from "../pages/campaign/Campaign";
import Profile from "../component/profie/Profile";
import ForgetPassword from "../pages/auth/ForgetPassword";
import ChangePassword from "../pages/auth/ChangePassword";
import BlogDetail from "../pages/blogs/BlogDetail";
import ProtectedRoutes from "./ProtectedRoutes";
import MyAddress from "../component/profie/MyAddress";
import MyDonation from "../component/profie/MyDonation";
import MyOrders from "../component/profie/MyOrders";
import NotFound from "../component/NotFound/NotFound";


const PublicRoutes = () => {
    return (
        <>
		
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />} />
					<Route path="all-plants" element={<Shop />} />
                    <Route path="nutrients-additives" element={<Nutrient />} />
					<Route path="nutrients-additive-item/:Id" element={<NutrientProductDetail />} />
					<Route path="planting-mixes" element={<PlantingMaxing />} />
					<Route path="planting-mixes-item/:Id" element={<NutrientProductDetail />} />

                    <Route path="kigibird" element={<KigiBird />} />
                    <Route path="kigibird-detail/:Id" element={<BirdProductDetail />} />
           
                    <Route path="product-detail/:Id" element={<ProductDetail />} />
					<Route path="planting-mixes-item/:Id" element={<NutrientProductDetail />} />
                    <Route path="premium-products" element={<PremiumProducts />} />
					<Route path="premium-product/:Id" element={<NutrientProductDetail />} />
					{/* <Route path="/premiumproductdetail" element={<PremiumProductDetail />}></Route> */}

                    <Route path="/donate" element={<Donate />} />

                    <Route path="/contact" element={<Contact />}></Route>
                    <Route path="/cart" element={<Cart />}></Route>
                    {/* <Route path="/wishlist" element={<Wishlist />}></Route> */}
                    <Route path="/about" element={<About />}></Route>
                    <Route path="/gift-certificate" element={<GiftCertificate />} />
                    <Route path="/gallery" element={<Gallery />}></Route>
                    <Route path="/blogs" element={<Blog />}></Route>
                    <Route path="/faqs" element={<Faq />}></Route>
                    <Route path="/shipping-policy" element={<ShippingPolicy />}></Route>
                    <Route path="/video" element={<Video />}></Route>
              
                    <Route path="/checkout" element={<Checkout />}></Route>
                    <Route path="/thankyou" element={<Thankyou />}></Route>
                
                    <Route path="/donatenow" element={<DonateNow />} />
                    {/* <Route path="/plantingproductdetail" element={<PlantingProductDetail />}></Route> */}
               
                    <Route path="/campaign/:Id" element={<Campaign />}/>
                    <Route path="/blog-detail/:Id" element={<BlogDetail />} />
                    
                    <Route path="/login" element={<Login />}></Route>
                    <Route path="/signup" element={<Signup />}></Route>
                    <Route path="/forget-password" element={<ForgetPassword />}></Route>
                    <Route path="/reset-password" element={<ChangePassword />}></Route>

					<Route path="/" element={<ProtectedRoutes />}>
  						<Route path="profile" element={<Profile />} />
                          <Route path="my-address" element={<MyAddress />} />
                          <Route path="my-donation" element={<MyDonation />} />
                          <Route path="my-orders" element={<MyOrders />} />
                          <Route path="wishlist" element={<Wishlist />} />
					</Route>
                  
                    <Route path="/*" element={<NotFound />} />

                </Routes>
            </BrowserRouter>
        </>
    )
}

export default PublicRoutes;
