import React from "react";
import { useSelector } from "react-redux";
import { Rating } from "react-simple-star-rating";
import { arrow, user5 } from "../../constant";
import { ramdomImage } from "../../constant/ConstantFunction";

const ReviewBox = (props) => {
  const ImageUrlRedux = useSelector((state) => state.AuthReducer.urlData);
  const userData = useSelector((state) => state.AuthReducer.users);
  const {
    handleRating,
    rating,
    SubmitRating,
    comment,
    setComment,
    reviewLoading,
  } = props;

  return (
    <div className="post-review">
      <div className="header">
        <div className="left-side">
          <figure>
            {userData?.image == null ? (
              <img
                src={`${ramdomImage(
                  `${userData?.f_name} ${userData?.l_name}`
                )}`}
                className="image-fluid image-width"
                alt=""
              />
            ) : (
              <img
                src={`${ImageUrlRedux?.customer_image_url}/${userData.image}`}
                className="img-fluid"
              />
            )}
          </figure>
          <div className="name">
            <h4>
              {userData?.f_name} {userData?.l_name}
            </h4>
            <p className="tagline">Your review will be Posted on Web</p>
          </div>
        </div>
        <div className="right-side">
          <Rating   allowFraction={true} onClick={handleRating} ratingValue={rating} />
        </div>
      </div>
      <form action="">
        <textarea
          placeholder="Write Your Review Here"
          className="form-control"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        ></textarea>
        <button
          className="btn"
          onClick={(e) => SubmitRating(e)}
          disabled={reviewLoading}
        >
          {reviewLoading ? "Loading..." : "Submit review"}{" "}
          <img src={arrow} className="img-fluid" />
        </button>
      </form>
    </div>
  );
};

export default ReviewBox;
