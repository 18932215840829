import React, { useEffect, useState } from "react";
import Footer from "../../component/Footer";
import Header from "../../component/Header";
import { smallafter, smallbefore } from "../../constant";
import "../../assets/style/blogdetail.css";
import { GetBlogDetails } from "../../network/Network";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";

const BlogDetail = () => {
  const ImageUrlRedux = useSelector((state) => state.AuthReducer.urlData);
  let currentUrl = window.location.href.split("/");
  const [blogDetails, setBlogDetails] = useState();
  const [spinLoad, setSpinLoad] = useState(false);

  useEffect(() => {
    setSpinLoad(true);
    GetBlogDetails(currentUrl[4])
      .then((res) => {
        // console.log("details", res);
        setBlogDetails(res?.data?.data);
        setSpinLoad(false);
      })
      .catch((err) => {
        // console.log(err);
        setSpinLoad(false);
      });
  }, [currentUrl[4]]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      {/* <!-- InnerBanner Sec Start Here --> */}
      <section className="innerBanner">
        <div className="container">
          <div className="inner-box">
            <div className="title">
              <h2>Blog Detail</h2>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- InnerBanner Sec End Here --> */}
      {/* <!-- Blog Detail Sec Start Here --> */}
      {spinLoad ? (
        <>
          {[0, 1, 2, 3, 4, 5, 7, 8, 9]?.map((item, index) => {
            return (
              <div className="container mt-2" key={index}>
                <Skeleton count={5} />
              </div>
            );
          })}
        </>
      ) : (
        <>
          <section className="blog-detail">
            <div className="before">
              <figure>
                <img src={smallafter} className="img-fluid" />
              </figure>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="img-box">
                    <figure>
                      <img
                        src={`${ImageUrlRedux?.blog_url}/${blogDetails?.image}`}
                        className="img-fluid"
                      />
                    </figure>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="content-wrapper">
                    <h4>{blogDetails?.title}</h4>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: blogDetails?.content,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="after">
              <figure>
                <img src={smallbefore} className="img-fluid" />
              </figure>
            </div>
          </section>
        </>
      )}

      {/* <!-- Blog Detail Sec End Here --> */}
      <Footer />
    </>
  );
};

export default BlogDetail;
