import React, { useEffect, useState } from "react";
import Footer from "../../component/Footer";
import Header from "../../component/Header";
import "../../assets/style/giftcertificate.css";
import { toast } from "react-toastify";
import { GetGiftThemesApi, PostGiftApi } from "../../network/Network";
import StripeForm from "../../component/PaymentMethod/StripeForm";
import { Navigate, useNavigate } from "react-router-dom";

const GiftCertificate = () => {
  const navigate = useNavigate()
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [recipientName, setRecipientName] = useState("");
  const [amount, setAmount] = useState("");
  const [recipientEmail, setRecipientEmail] = useState("");
  const [message, setMessage] = useState("");
  const [giftTheme_id, setGiftTheme_id] = useState();
  const [loading, setLoading] = useState(false);
  const [expire, setExpire] = useState(false);
  const [non_refundable, setNon_refundable] = useState(false);
  const [giftThemeData, setGiftThemeData] = useState([]);

  // Get Gift themes
  useEffect(() => {
    GetGiftThemesApi()
      .then((res) => {
        // console.log("gift theme", res);
        setGiftThemeData(res?.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);

  const GiftSendHandler = (e, stripe_token) => {
    e.preventDefault();
    setLoading(true);
    if (
      !name ||
      !recipientName ||
      !amount ||
      !email ||
      !message ||
      !recipientEmail
    ) {
      toast.error("Please Enter All Fields");
      setLoading(false);
      return;
    }
    if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      toast.error("Invalid Email");
      setLoading(false);
      return;
    }
    if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        recipientEmail
      )
    ) {
      toast.error("Invalid Email");
      setLoading(false);
      return;
    }
    if (!giftTheme_id) {
      toast.error("Please select gift theme");
      setLoading(false);
      return;
    }
    if (message.length < 20) {
      toast.error("The message must be at least 20 characters");
      setLoading(false);
      return;
    }
    if (!expire) {
      toast.error("Please agree with our gift certificates expire policy");
      setLoading(false);
      return;
    }
    if (!non_refundable) {
      toast.error("Please agree with our gift certificates are non-refundable");
      setLoading(false);
      return;
    }
    let data = new FormData();
    data.append("name", name);
    data.append("email", email);
    data.append("recipient_name", recipientName);
    data.append("recipient_email", recipientEmail);
    data.append("amount", amount);
    data.append("message", message);
    data.append("_token", stripe_token);
    data.append("gift_theme_id", giftTheme_id);
    // console.log(data);
    PostGiftApi(data)
      .then((res) => {
        // console.log("gift send", res);
        toast.success("Gift certificate successfully send");
        setLoading(false);
        setName("");
        setRecipientName("");
        setRecipientEmail("");
        setEmail("");
        setMessage("");
        setAmount("");
        setGiftTheme_id("");
        setExpire(false)
        setNon_refundable(false)
        navigate('/')
      })
      .catch((err) => {
        // console.log(err);
        setLoading(false);
      });
  };

  const styling = {
    marginTop: "10px",
    width: "100%",
    padding: "8px 16px",
    borderRadius: "20px",
    background: "#c23127",
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      {/* <!-- InnerBanner Sec Start Here --> */}
      <section class="innerBanner">
        <div class="container">
          <div class="inner-box">
            <div class="title">
              <h2>Gift Certificates</h2>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- InnerBanner Sec End Here --> */}

      {/* <!-- Gift Sec Start Here --> */}
      <section class="gift_sec">
        <div class="container">
          <div class="heading">
            <h2>Send Gift Amounts to Your friends and family</h2>
            <p>
              : Gift Certificate code send to Recipient via email, and the code
              is only valid for 365 days. The recipient can redeem the amount by
              applying the code at checkout. Also, the recipient can check the
              balance amount by entering the certificate code.
            </p>
          </div>
          <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-8">
              <form action="thank-you.php">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Your Name</label>
                      <input
                        type="text"
                        placeholder="Write Your Name"
                        class="form-control"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Your Email</label>
                      <input
                        type="email"
                        placeholder="Write Your Email"
                        class="form-control"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Recipient’s Name</label>
                      <input
                        type="text"
                        placeholder="Write Recipient’s Name"
                        class="form-control"
                        value={recipientName}
                        onChange={(e) => setRecipientName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Recipient’s Email</label>
                      <input
                        type="email"
                        placeholder="Write Recipient’s Email"
                        class="form-control"
                        value={recipientEmail}
                        onChange={(e) => setRecipientEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Enter Gift Amount</label>
                      <input
                        type="number"
                        placeholder="Write gift Amount"
                        class="form-control"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Gift Certificate Theme</label>
                      <select
                        className="form-control"
                        id="theme"
                        // style={{padding: "unset"}}
                        onChange={(e) => {
                          setGiftTheme_id(e.target.value);
                        }}
                      >
                        <option selected="">Select Theme</option>
                        {giftThemeData?.map((item, index) => {
                          return (
                            <option value={item?.id} key={index}>
                              {item?.title}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label>Your Message</label>
                      <textarea
                        type="text"
                        placeholder="Write your Message Here"
                        class="form-control"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group">
                      <input
                        type="checkbox"
                        id="radio-check"
                        class="form-check-input"
                        value={expire}
                        onChange={(e) => setExpire(e.target.checked)}
                      />
                      <label for="radio-check">
                        I understand that Gift Certificates expire after 365
                        days
                      </label>
                    </div>
                    <div class="form-group">
                      <input
                        type="checkbox"
                        id="radio-check2"
                        class="form-check-input"
                        value={non_refundable}
                        onChange={(e) => setNon_refundable(e.target.checked)}
                      />
                      <label for="radio-check2">
                        I agree that Gift Certificates are non-refundable
                      </label>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <StripeForm
                      styling={styling}
                      paynow="Apply"
                      PaymentHandler={GiftSendHandler}
                      loading={loading}
                    />
                    {/* <div class="button-group">
                      <button class="btn">Apply</button>
                    </div> */}
                  </div>
                </div>
              </form>
            </div>
            <div class="col-md-2"></div>
          </div>
        </div>
      </section>
      {/* <!-- Gift Sec End Here --> */}
      <Footer />
    </>
  );
};

export default GiftCertificate;
