import React from 'react'
import moment from "moment";
import { Rating } from "react-simple-star-rating";
import { user1 } from '../../constant';
import { useSelector } from 'react-redux';
import { ramdomImage } from '../../constant/ConstantFunction';

const AllReviews = (props) => {
    const { reviewData } = props;
    const ImageUrlRedux = useSelector((state) => state.AuthReducer.urlData)
  return (
   <>
   {
     reviewData?.reviews?.map((item, index) => {
        return(
      <>
      {
        index < 6 ? (
          <>
           <div className="questionDv reviews" key={index}>
            <div className="user-img">
              <figure>
                {
                  item?.user?.image == null ? (
                    <img  src={`${ramdomImage(
                      `${item?.user?.f_name} ${item?.user?.l_name}`
                    )}`}
                    className="image-fluid image-width"
                    alt="" />
                  ) : (
                    <img src={`${ImageUrlRedux?.customer_image_url}/${item?.user?.image}`} className="img-fluid" />
                  )
                }
               
              </figure>
            </div>
            <div className="detailDv">
              <div className="header">
                <h5 className="name"> {item?.user?.f_name} {item?.user?.l_name}{" "} </h5>
                <p> {moment(item?.created_at).format(
                        "MMMM Do YYYY, h:mm a"
                      )}</p>
              </div>
              <p>
              {item?.comment}
             
              </p>
              <div className="bottom-bar">
                <div className="rating">
                      <Rating
                        size={18}
                        readonly={true}
                        allowFraction={true}
                        initialValue={item?.rating}
                        // ratingValue={}
                      />
                 
                  <p className="count">{item?.rating}</p>
                </div>
                {/* <div className="rplyDv">
                  <a href="#">Reply</a>
                </div> */}
              </div>
            </div>
          </div>
          {/* <div className="answersDv">
            <div className="vertical-line"></div>
            <div className="answer reviews">
              <div className="user-img">
                <figure>
                  <img src={user2} className="img-fluid" />
                </figure>
              </div>
              <div className="detailDv">
                <div className="header">
                  <h5 className="name">Joeby Ragpa </h5>
                  <p>- 12 April, 2014 at 16:50</p>
                </div>
                <p>
                  We possess within us two minds. So far I have written
                  only of the conscious mind. I would now like to
                  introduce you to your second mind the hidden and
                  mysterious subconscious.
                </p>
              </div>
            </div>
            <div className="answer reviews">
              <div className="user-img">
                <figure>
                  <img src={user3} className="img-fluid" />
                </figure>
              </div>
              <div className="detailDv">
                <div className="header">
                  <h5 className="name">Joeby Ragpa </h5>
                  <p>- 12 April, 2014 at 16:50</p>
                </div>
                <p>
                  We possess within us two minds. So far I have written
                  only of the conscious mind. I would now like to
                  introduce you to your
                </p>
              </div>
            </div>
            <div className="answer reviews">
              <div className="user-img">
                <figure>
                  <img src={user4} className="img-fluid" />
                </figure>
              </div>
              <div className="detailDv">
                <div className="header">
                  <h5 className="name">Joeby Ragpa </h5>
                  <p>- 12 April, 2014 at 16:50</p>
                </div>
                <p>
                  We possess within us two minds. So far I have written
                  only of the conscious mind. I would now like to
                  introduce you to your second mind,
                </p>
              </div>
            </div>
          </div> */}
          </>
        ) : (null)
      }
      </>
        )
     })
     }
   
       
   </>
  )
}

export default AllReviews