import React, { useEffect, useState } from "react";
import Footer from "../../component/Footer";
import Header from "../../component/Header";
import "../../assets/style/faqs.css";
import { smallafter, smallbefore } from "../../constant/index";
import { GetFaqs } from "../../network/Network";
import Skeleton from "react-loading-skeleton";

const Faq = () => {
  const [faqsData, setFaqsData] = useState([]);
  const [spinLoad, setSpinLoad] = useState(false);
  useEffect(() => {
    setSpinLoad(true);
    GetFaqs()
      .then((res) => {
        // console.log("faqs", res);
        setFaqsData(res?.data);
        setSpinLoad(false);
      })
      .catch((err) => {
        // console.log(err);
        setSpinLoad(false);
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      {/* <!-- InnerBanner Sec Start Here --> */}
      <section className="innerBanner">
        <div className="container">
          <div className="inner-box">
            <div className="title">
              <h2>FAQS</h2>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- InnerBanner Sec End Here --> */}

      {/* <!-- Faqs Sec Start Here --> */}
      <section className="faqs_sec">
        <div className="before">
          <figure>
            <img src={smallafter} className="img-fluid" />
          </figure>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
              <div className="heading">
                <h2>Frequently Asked Questions</h2>
                <p>
                  {" "}
                  Nisl vel pretium lectus quam id leo. Odio ut enim blandit
                  volutpat maecenas volutpat blandit. Nunc vel risus commodo
                  viverra maecenas. Lacus luctus accumsan tortor posuere ac ut
                  consequat.{" "}
                </p>
              </div>

              <div className="accordion" id="accordionExample">
                {spinLoad ? (
                  <>
                    {[0, 1, 2, 3, 4, 5]?.map((item, index) => {
                      return (
                        <div className="col-md-12 mt-3" key={index}>
                          <Skeleton count={5} />
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {/* {faqsData?.map((item, index) => {
                      return (
                        <div className="accordion-item" key={index}>
                          <h2 className="accordion-header" id={item?.id}>
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-target={`#${item?.question}${item?.id}`}
                              aria-expanded="true"
                              aria-controls={`${item?.question}${item?.id}`}
                            >
                              {item?.question}
                            </button>
                          </h2>
                          <div
                            // id="collapseOne"
                            className="accordion-collapse collapse show"
                            // aria-labelledby="headingOne"
                            data-parent="#accordionExample"
                            id={`${item?.question}${item?.id}`}
                            aria-labelledby={item?.id}
                          >
                            <div className="accordion-body">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: item?.answer,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })} */}

                    <div id="accordion">
                      {faqsData?.map((item, index) => {
                        return (
                          <div className="card mt-2" key={index}>
                            <div className="card-header" id={item?.id}>
                              <h5 className="mb-0">
                                <button
                                  className="btn btn-link accordion-button"
                                  data-toggle="collapse"
                                  // data-target="#collapseOne"
                                  // aria-expanded="true"
                                  // aria-controls="collapseOne"

                                  data-target={`#${item?.question}${item?.id}`}
                                  aria-expanded="true"
                                  aria-controls={`${item?.question}${item?.id}`}
                                >
                                  {item?.question}
                                </button>
                              </h5>
                            </div>

                            <div
                              // id="collapseOne"
                              className="collapse show"
                              // aria-labelledby="headingOne"
                              data-parent="#accordion"
                              id={`${item?.question}${item?.id}`}
                              aria-labelledby={item?.id}
                            >
                              <div className="card-body">
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: item?.answer,
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="col-lg-2"></div>
          </div>
        </div>
        <div className="after">
          <figure>
            <img src={smallbefore} className="img-fluid" />
          </figure>
        </div>
      </section>
      {/* <!-- Faqs Sec End Here --> */}
      <Footer />
    </>
  );
};

export default Faq;
